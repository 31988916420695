<section class="h-100">
  <span class="success-modal-header" (click)="closeModal()">
    <mat-icon class="close-icon">close</mat-icon>
  </span>
  <section class="d-flex align-items-center flex-column px-2">
    <img
      src="assets/img/icons/retry-success.svg"
      alt="success icon"
      width="69px"
    />
    <h3 *ngIf="currentPaymentStatus !== 'Done'" class="success-title">
      Recibimos los nuevos datos bancarios
    </h3>
    <h3 *ngIf="currentPaymentStatus !== 'Done'" class="success-subtitle">
      de {{ entityType }}
    </h3>
    <h3 *ngIf="currentPaymentStatus === 'Done'" class="success-title">
      Tu pago ya fue depositado
    </h3>
    <p *ngIf="currentPaymentStatus !== 'Done'" class="success-main-text">
      Te avisaremos por correo cuando hayamos reintentado el pago con estos
      nuevos datos.
    </p>
    <p *ngIf="currentPaymentStatus === 'Done'" class="success-main-text">
      Corregimos los datos de pago y reintentamos tu pago sin problemas.
    </p>
    <p class="success-text">
      Recuerda que debes actualizar estos datos bancarios en tu cuenta para
      evitar problemas con futuros pagos.
    </p>
    <section class="button-wrapper d-flex w-100 justify-content-center">
      <button
        mat-raised-button
        class="success-btn"
        style="height: fit-content;"
        (click)="closeModal()"
      >
        <span>Listo</span>
      </button>
    </section>
  </section>
</section>
