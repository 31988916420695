<div>
  <div>
    <div class="d-flex justify-content-center">
      <img
        src="assets/img/icons/success.svg"
        style="max-width: 400px;"
        alt="success"
      />
    </div>
    <div class="d-flex flex-column align-items-center">
      <span class="main-text py-2"
        >¡Tu pago de {{ category }} está en proceso!</span
      >
      <span *ngIf="transferDate" class="secundary-text py-2"
        >Será depositado el
        <b
          >{{ transferDate | date: 'dd':'':'es-CL' }} de
          {{ transferDate | date: 'MMMM':'':'es-CL' }} de
          {{ transferDate | date: 'yyyy':'':'es-CL' }}</b
        >, y ese día enviaremos el comprobante de pago a ti y al
        destinatario.</span
      >
    </div>
    <div class="d-flex justify-content-center py-3">
      <button (click)="closeBtn()" class="neat-btn neat-btn__primary">
        <span>
          Aceptar
        </span>
      </button>
    </div>
    <hr style="opacity: 0.3;background: #B7D3F9;width: 100%;" />
    <div class="d-flex justify-content-center">
      <span
        class="my-2 text-center automate-text"
        (click)="goToAutomate(entity)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <mask
            id="mask0_60_61"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="17"
          >
            <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_60_61)">
            <path
              d="M3.33333 5.83301H12.6667V4.49967H3.33333V5.83301ZM3.33333 15.1663C2.96667 15.1663 2.65278 15.0358 2.39167 14.7747C2.13056 14.5136 2 14.1997 2 13.833V4.49967C2 4.13301 2.13056 3.81912 2.39167 3.55801C2.65278 3.2969 2.96667 3.16634 3.33333 3.16634H4V1.83301H5.33333V3.16634H10.6667V1.83301H12V3.16634H12.6667C13.0333 3.16634 13.3472 3.2969 13.6083 3.55801C13.8694 3.81912 14 4.13301 14 4.49967V8.28301C13.7889 8.18301 13.5722 8.09967 13.35 8.03301C13.1278 7.96634 12.9 7.91634 12.6667 7.88301V7.16634H3.33333V13.833H7.53333C7.61111 14.0775 7.70278 14.3108 7.80833 14.533C7.91389 14.7552 8.03889 14.9663 8.18333 15.1663H3.33333ZM12 15.833C11.0778 15.833 10.2917 15.508 9.64167 14.858C8.99167 14.208 8.66667 13.4219 8.66667 12.4997C8.66667 11.5775 8.99167 10.7913 9.64167 10.1413C10.2917 9.49134 11.0778 9.16634 12 9.16634C12.9222 9.16634 13.7083 9.49134 14.3583 10.1413C15.0083 10.7913 15.3333 11.5775 15.3333 12.4997C15.3333 13.4219 15.0083 14.208 14.3583 14.858C13.7083 15.508 12.9222 15.833 12 15.833ZM13.1167 14.083L13.5833 13.6163L12.3333 12.3663V10.4997H11.6667V12.633L13.1167 14.083Z"
              fill="#3369F0"
            />
          </g>
        </svg>
        Ahorra tiempo programando tu próximo pago
      </span>
    </div>
  </div>
</div>
