<app-loading [showLoader]="showLoader"></app-loading>
<section id="register-section">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="dialog-header">
          <h6 mat-dialog-title c style="color: #212121; font-weight: 600;">
            Ingresa tu RUT
          </h6>
        </div>
        <form
          [formGroup]="rutForm"
          (ngSubmit)="saveRut()"
          class="d-flex justify-content-center flex-column"
        >
          <div>
            <div class="d-block mb-2">
              <p style="color: #627178; font-size: 13.5px;">
                {{ descriptionText }}
              </p>
            </div>
            <mat-form-field
              class="w-100 notranslate"
              floatLabel="always"
              appearance="outline"
              style="display: inline-block;"
            >
              <mat-label class="notranslate">
                <mat-icon>credit_card</mat-icon> RUT
              </mat-label>
              <input
                [ngClass]="currentUser?.rut ? 'disabled-field' : ''"
                matInput
                (focus)="focusState = true"
                (focusout)="focusState = false"
                onkeypress="onlyNumbersAndK(event)"
                [value]="rutForm.get('rut').value | formatRut"
                maxlength="12"
                formControlName="rut"
                id="rut"
                name="lesseeRut"
                type="text"
                maxlength="12"
                (keyup)="validateValidRut()"
              />
              <mat-error *ngIf="rutForm.controls['rut'].hasError('required')"
                >El RUT es <strong>requerido</strong>.</mat-error
              >
              <mat-error
                *ngIf="
                  rutForm.controls['rut'].hasError('minlength') &&
                  !rutForm.controls['rut'].hasError('invalidRut')
                "
                >El RUT es <strong>inválido</strong>.</mat-error
              >
              <mat-error *ngIf="rutForm.controls['rut'].hasError('invalidRut')"
                >El formato del RUT es <strong>inválido</strong>.</mat-error
              >
              <mat-error *ngIf="rutForm.controls['rut'].hasError('usedRut')"
                >El RUT ya esta siendo <strong>utilizado</strong>.</mat-error
              >
              <mat-error
                *ngIf="rutForm.controls['rut'].hasError('rutValidation')"
                >Ocurrió un problema al validar tu <strong>RUT</strong>. Si el
                problema persiste, contáctanos al chat.</mat-error
              >
              <span class="valid" *ngIf="rutForm.get('rut').status === 'VALID'"
                >El RUT es <strong>válido</strong></span
              >
              <mat-hint
                class="animated fadeIn"
                *ngIf="rutForm.get('rut').status === 'PENDING'"
                >Verificando RUT...</mat-hint
              >
            </mat-form-field>
          </div>
          <div class="d-flex my-2 justify-content-between">
            <button
              *ngIf="!this.data?.edipro"
              id="no-rut-added"
              (click)="goBack()"
              mat-raised-button
              class="prev-btn"
              type="button"
            >
              Volver
            </button>
            <button
              id="save-rut-from-modal"
              mat-raised-button
              class="next-btn"
              type="submit"
              [disabled]="!rutForm.valid"
            >
              Guardar mi RUT
            </button>
          </div>
        </form>
      </div>
      <div *ngIf="this.data?.edipro" class="col-12">
        <div
          class="d-flex justify-content-between my-4"
          style="border-top: 1px solid rgba(59,64,134, .2);"
        >
          <div
            class="mt-4"
            style="color: #868686;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;"
          >
            <div
              class="d-flex align-items-center"
              style="cursor: pointer;"
              (click)="goEdiPro()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M3.825 9L9.425 14.6L8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825Z"
                  fill="#868686"
                />
              </svg>
              <span class="ml-2">
                Volver
              </span>
            </div>
          </div>
          <div class="mt-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="97"
              height="24"
              viewBox="0 0 97 24"
              fill="none"
            >
              <path
                d="M29.7249 0H12.8199C9.0249 0 5.5749 3 5.5749 7.28571V8.57143L2.9874 6L0.399902 8.57143L7.2999 15.4286L14.1999 8.57143L11.6124 6L9.0249 8.57143V7.28571C9.0249 5.15547 10.7626 3.42857 12.9062 3.42857H26.2749V6.85714H29.7249V0Z"
                fill="#3369F0"
              />
              <path
                d="M5.5749 24L22.4799 24C26.5684 24 29.7249 20.5714 29.7249 16.7143V15.4286L32.3124 18L34.8999 15.4286L27.9999 8.57143L21.0999 15.4286L23.6874 18L26.2749 15.4286V16.7143C26.2749 18.8445 24.5372 20.5714 22.3937 20.5714L9.0249 20.5714V17.1429H5.5749V24Z"
                fill="#3369F0"
              />
              <path
                d="M93.5698 7.71429L93.5698 14.5714C93.5698 15.0311 93.6765 15.3677 93.8897 15.5811C94.1194 15.7781 94.4968 15.8766 95.0218 15.8766H96.9999V18.861H94.4558C91.5519 18.861 90.0999 17.8238 90.0999 15L90.0999 7.71429H88.3749L88.3749 5.14285H90.0999V1.71429H93.5698V5.14285H96.1374L96.1374 7.71429H93.5698Z"
                fill="#3369F0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M71.9874 11.9903C71.9874 10.6112 72.2669 9.38809 72.826 8.32095C73.402 7.25381 74.1728 6.43293 75.1384 5.85832C76.121 5.2837 77.2137 4.9964 78.4165 4.9964C79.4669 4.9964 80.3817 5.20162 81.161 5.61206C81.9572 6.0225 82.7255 6.53965 83.1999 7.16351V5.14285H86.6499V18.8571H83.1999V16.8663C82.7425 17.5066 81.9742 18.0401 81.161 18.467C80.3648 18.8774 79.4415 19.0826 78.3911 19.0826C77.2052 19.0826 76.121 18.7871 75.1384 18.1961C74.1728 17.6051 73.402 16.776 72.826 15.7088C72.2669 14.6253 71.9874 13.3858 71.9874 11.9903ZM83.0874 12C83.0874 11.1627 82.8975 10.4881 82.5586 9.89703C82.2198 9.28958 81.7624 8.8299 81.1864 8.51796C80.6104 8.18961 79.9921 8.02543 79.3314 8.02543C78.6707 8.02543 78.0608 8.1814 77.5017 8.49333C76.9427 8.80527 76.4853 9.26496 76.1295 9.87241C75.7907 10.4634 75.6213 11.1694 75.6213 11.9903C75.6213 12.8111 75.7907 13.5335 76.1295 14.1574C76.4853 14.7648 76.9427 15.2327 77.5017 15.5611C78.0777 15.8894 78.6876 16.0536 79.3314 16.0536C79.9921 16.0536 80.6104 15.8976 81.1864 15.5857C81.7624 15.2574 82.2198 14.7977 82.5586 14.2066C82.8975 13.5992 83.0874 12.8373 83.0874 12Z"
                fill="#3369F0"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M70.2624 11.744C70.2624 12.2365 70.229 12.6798 70.1622 13.0738H60.0188C60.1023 14.0589 60.453 14.8305 61.0707 15.3887C61.6885 15.9469 62.4482 16.226 63.3499 16.226C64.6522 16.226 65.5789 15.676 66.1299 14.576H69.9118C69.511 15.8894 68.743 16.973 67.6076 17.8267C66.4722 18.664 65.078 19.0826 63.425 19.0826C62.0893 19.0826 60.8871 18.7953 59.8185 18.2207C58.7666 17.6297 57.9401 16.8006 57.339 15.7335C56.7546 14.6663 56.4624 13.435 56.4624 12.0395C56.4624 10.6276 56.7546 9.38809 57.339 8.32095C57.9234 7.25381 58.7415 6.43293 59.7934 5.85832C60.8453 5.2837 62.0559 4.9964 63.425 4.9964C64.7441 4.9964 65.9212 5.2755 66.9564 5.83369C68.0083 6.39189 68.8181 7.18814 69.3858 8.22244C69.9702 9.24033 70.2624 10.4142 70.2624 11.744ZM66.6308 10.759C66.6141 9.87241 66.2885 9.16645 65.654 8.64109C65.0196 8.09931 64.2432 7.82842 63.3248 7.82842C62.4566 7.82842 61.7219 8.0911 61.1208 8.61647C60.5364 9.12541 60.1775 9.83957 60.0439 10.759H66.6308Z"
                fill="#3369F0"
              />
              <path
                d="M49.2987 5.02103C50.923 5.02103 52.2355 5.53818 53.2362 6.57248C54.237 7.59037 54.7374 9.0187 54.7374 10.8575V18.861H51.2921V11.3254C51.2921 10.2418 51.0214 9.41272 50.48 8.8381C49.9386 8.24707 49.2003 7.95156 48.2652 7.95156C47.3136 7.95156 46.5589 8.24707 46.0011 8.8381C45.4597 9.41272 45.2452 10.2418 45.2452 11.3254V18.8571H41.7999V5.14285H45.2452V6.91725C45.7046 6.32622 46.2308 5.86653 46.9363 5.53818C47.6581 5.19341 48.4456 5.02103 49.2987 5.02103Z"
                fill="#3369F0"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
