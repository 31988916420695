import { INeatCategories, INeatServices } from 'neat-lib/dist/Interfaces/IData';

import { MergeTypes } from '@shared/utils';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';

export const getImageUrl = (
  entity: MergeTypes<BasicService, Entity> | Entity,
  neatServices: INeatServices[],
  neatCategories: INeatCategories[]
) => {
  const serviceUrl = getImageUrlFromService(entity, neatServices);
  if (serviceUrl) {
    return serviceUrl;
  }
  const categoryUrl =
    (entity?.entityType as any) !== 'basicService' &&
    getImageUrlFromCategories(entity, neatCategories);
  return categoryUrl || null;
};

const getImageUrlFromService = (
  entity: MergeTypes<BasicService, Entity> | Entity,
  neatServices: INeatServices[]
) => {
  if (!entity?.neatInformation?.serviceNeatCode) {
    return null;
  }
  const neatService = neatServices?.find(
    service => service?.neatCode === entity?.neatInformation?.serviceNeatCode
  );
  return neatService ? neatService.logoUrl : null;
};
const getImageUrlFromCategories = (
  entity: Entity,
  neatCategories: INeatCategories[]
) => {
  if (!entity?.neatInformation?.serviceNeatCategory) {
    return null;
  }
  const neatCategory = neatCategories?.find(
    category => category?.code === entity?.neatInformation?.serviceNeatCategory
  );
  return neatCategory ? neatCategory.logoUrl : null;
};
