import { AbstractControl } from '@angular/forms';

import { AppGlobals } from '../shared/constants';

export function RutValidator(control: AbstractControl) {
  if (control.value) {
    const rut = String(control.value);
    // Despejar Puntos
    const valor = AppGlobals.formatRut(rut);
    // Aislar Cuerpo y Dígito Verificador
    const [cuerpo, dv] = valor.split('-');
    let rutVerifierDigit = dv.toUpperCase();
    // Calcular Dígito Verificador
    let suma = 0;
    let multiplo = 2;
    // Para cada dígito del Cuerpo
    for (let i = 1; i <= cuerpo.length; i++) {
      // Obtener su Producto con el Múltiplo Correspondiente
      const index = multiplo * Number(valor[cuerpo.length - i]);
      // Sumar al Contador General
      suma = suma + index;
      // Consolidar Múltiplo dentro del rango [2,7]
      if (multiplo < 7) {
        multiplo = multiplo + 1;
      } else {
        multiplo = 2;
      }
    }
    // Calcular Dígito Verificador en base al Módulo 11
    const dvEsperado = String(11 - (suma % 11));
    // Casos Especiales (0 y K)
    rutVerifierDigit = rutVerifierDigit == 'K' ? '10' : rutVerifierDigit;
    rutVerifierDigit = rutVerifierDigit == '0' ? '11' : rutVerifierDigit;
    // Validar que el Cuerpo coincide con su Dígito Verificador
    return dvEsperado != rutVerifierDigit || valor.length < 9
      ? { invalidRut: true }
      : null;
  }
}
