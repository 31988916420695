import { Component, Inject, OnDestroy, OnInit, isDevMode } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Kushki } from '@kushki/js';
import { TokenResponse } from '@kushki/js/lib/types/remote/token_response';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription } from 'rxjs/internal/Subscription';

import { PaymentsService } from '@services/payments/payments.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { UserService } from '@services/user/user.service';
import { CardMethod, CardTypes } from '@shared/enums/enums.enum';
import { MyErrorStateMatcher } from '@shared/my-error-state-matcher/my-error-state-matcher.directive';
import { PaymentClass } from 'app/dashboard/checkout/payment';
import { User } from 'app/models/users/user.model';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-kushki-enroll-modal',
  templateUrl: './kushki-enroll-modal.component.html',
  styleUrls: ['./kushki-enroll-modal.component.scss']
})
export class KushkiEnrollModalComponent implements OnInit, OnDestroy {
  paymentMethodsAvailable: any[];
  cardTypes = CardTypes;
  cardMethod = CardMethod;
  card: any;
  selectedCardId: string;
  errorMsg: boolean;
  matcher = new MyErrorStateMatcher();
  private errorSubscription: Subscription;
  showLoader: boolean;
  showNewLoader: boolean;
  successMode: boolean;
  errorMode: boolean;
  hide = true;
  currentUserIdToken: string;
  isMobile: boolean;
  kushkiForm: FormGroup;
  currentUser: User;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private deviceDetector: DeviceDetectorService,
    public dialogRef: MatDialogRef<KushkiEnrollModalComponent>,
    public paymentClass: PaymentClass,
    private userService: UserService,
    private errorService: ErrorHandlerService,
    private paymentService: PaymentsService,
    private fb: FormBuilder
  ) {
    this.kushkiForm = this.fb.group({
      number: ['', Validators.required],
      name: ['', Validators.required],
      expiry_date: ['', Validators.required],
      cvc: ['', Validators.required]
    });
  }

  async ngOnInit(): Promise<void> {
    this.assignCurrentUserIdToken();
    this.isMobile = this.deviceDetector.isMobile();
    this.userService.idToken$.subscribe({
      next: idToken => (this.currentUserIdToken = idToken),
      error: error => {
        this.errorService.recordError(
          error,
          'payment-methods-selection.component.ts',
          'this.userService.idToken$',
          'Error al ejecutar subscribe de idToken$'
        );
      }
    });
    this.currentUser = await this.userService.getCurrentUser();
  }

  assignCurrentUserIdToken(): void {
    this.userService.idToken$.subscribe({
      next: idToken => (this.currentUserIdToken = idToken),
      error: error => {
        this.errorService.recordError(
          error,
          'checkout.component.ts',
          'this.userService.idToken$',
          'Error al ejecutar subscribe de idToken$'
        );
      }
    });
  }

  requestToken(): void {
    try {
      if (this.kushkiForm.invalid) {
        this.kushkiForm.markAllAsTouched();
        return;
      }
      this.showNewLoader = true;
      const kushki = new Kushki({
        merchantId: environment.kushkiMerchantId,
        ...(isDevMode() && { inTestEnvironment: true })
      });
      kushki.requestSubscriptionToken(
        {
          currency: 'CLP',
          card: {
            name: this.kushkiForm.get('name').value,
            number: this.kushkiForm.get('number').value,
            cvc: this.kushkiForm.get('cvc').value,
            expiryMonth: this.kushkiForm.get('expiry_date').value.slice(0, 2),
            expiryYear: String(this.kushkiForm.get('expiry_date').value).slice(
              -2
            )
          }
        },
        response => {
          if ((response as TokenResponse).token) {
            this.paymentService
              .kushkiEnrollerRequest(
                {
                  token: (response as TokenResponse).token,
                  uid: this.currentUser.id
                },
                this.currentUserIdToken
              )
              .subscribe(res => {
                if (res.status === 200) {
                  this.showNewLoader = false;
                  this.errorMsg = false;
                  this.successMode = true;
                } else {
                  this.showNewLoader = false;
                  this.errorMode = true;
                  this.errorMsg = true;
                }
              });
          } else {
            this.showNewLoader = false;
            this.errorMode = true;
            this.errorMsg = true;
            this.successMode = false;
          }
        }
      );
    } catch (err) {
      this.showNewLoader = false;
      this.errorMode = true;
      this.errorMsg = true;
      this.successMode = false;
    }
  }

  ngOnDestroy(): void {
    this.errorSubscription?.unsubscribe();
  }

  closeBtn(): void {
    this.dialogRef.close();
  }

  changePaymentMethod(): void {
    this.dialogRef.close({
      paymentMethodId: this.selectedCardId
    });
  }

  resetStatus() {
    this.successMode = false;
    this.errorMode = false;
  }
}
