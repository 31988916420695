import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { EntityTypeLocale } from '@shared/enums/enums.enum';
import { PaymentClass } from 'app/dashboard/checkout/payment';
import { Entity } from 'app/models/abstract-entity.model';

@Component({
  selector: 'app-depositee-info-modal',
  templateUrl: './depositee-info-modal.component.html',
  styleUrls: ['./depositee-info-modal.component.scss']
})
export class DepositeeInfoModalComponent {
  entity: Entity;
  entityTypeLocale = EntityTypeLocale;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entity: Entity },
    public paymentClass: PaymentClass,
    public router: Router,
    public dialogRef: MatDialogRef<DepositeeInfoModalComponent>
  ) {
    this.entity = this.data.entity;
  }

  closeBtn(): void {
    this.dialogRef.close();
  }
}
