<div style="position: relative;">
  <div class="top-neat-logo">
    <img
      src="assets/img/icons/neat-icon-logo-circle.png"
      style="width: 68px !important; margin-top: 1px;"
      alt="neat"
      srcset="
        assets/img/icons/neat-icon-logo-circle.png    1x,
        assets/img/icons/neat-icon-logo-circle@2x.png 2x
      "
    />
  </div>
  <div *ngIf="data === 'automatization'">
    <div class="d-flex align-items-center" style="flex-direction: column;">
      <img
        src="assets\img\modal-images\automatizate-img.png"
        class="img-fluid img-provider mt-2"
        style="max-height: 250px;"
        alt="automatization"
      />
      <h5 class="heading-h5 text-center">¿Cómo automatizar un pago en Neat?</h5>
      <p class="text-center">
        En este artículo, explicaré de manera breve los beneficios de utilizar
        el pago automático
      </p>
      <a
        mat-raised-button
        id="onboard-survey-go-to-automatization-article"
        class="main-btn mt-3"
        (click)="onNoClick()"
        style="width: fit-content !important;"
        href="https://neatpagos.com/blog/articulo/pago-automatico-de-neat"
        target="_blank"
        >IR AL BLOG A CONOCER MÁS</a
      >
      <span
        id="read-article-later"
        class="underlined-btn mt-3"
        (click)="onNoClick()"
        >Quizás, en otro momento</span
      >
    </div>
  </div>
  <div *ngIf="data === 'benefits'">
    <div class="d-flex align-items-center" style="flex-direction: column;">
      <img
        src="assets\img\modal-images\credit-cards-img.png"
        class="img-fluid img-provider mt-2"
        style="max-height: 250px;"
        alt="benefits"
      />
      <h5 class="heading-h5 text-center">
        Conoce las mejores tarjetas de crédito en Chile
      </h5>
      <p class="text-center">
        El uso de tarjetas de crédito ofrece grandes ventajas, por ello, es
        indispensable contar con las mejores.
      </p>
      <a
        mat-raised-button
        id="onboard-survey-go-to-best-credit-cards-article"
        class="main-btn mt-3"
        (click)="onNoClick()"
        style="width: fit-content !important;"
        href="https://neatpagos.com/blog/articulo/mejores-tarjetas-de-credito"
        target="_blank"
        >IR AL BLOG A CONOCER MÁS</a
      >
      <span
        id="read-article-later"
        class="underlined-btn mt-3"
        (click)="onNoClick()"
        >Quizás, en otro momento</span
      >
    </div>
  </div>
  <div *ngIf="data === 'needMoney'">
    <div class="d-flex align-items-center" style="flex-direction: column;">
      <img
        src="assets\img\modal-images\money-managment-img.png"
        class="img-fluid img-provider mt-2"
        style="max-height: 250px;"
        alt="order"
      />
      <h5 class="heading-h5 text-center">¿Cómo administrar tu dinero?</h5>
      <p class="text-center">
        Aprender cómo administrar tu dinero te ayudará a tener unas finanzas
        sanas
      </p>
      <a
        mat-raised-button
        id="onboard-survey-go-to-how-to-manage-money-article"
        class="main-btn mt-3"
        (click)="onNoClick()"
        style="width: fit-content !important;"
        href="https://neatpagos.com/blog/articulo/como-administrar-tu-dinero"
        target="_blank"
        >IR AL BLOG A CONOCER MÁS</a
      >
      <span
        id="read-article-later"
        class="underlined-btn mt-3"
        (click)="onNoClick()"
        >Quizás, en otro momento</span
      >
    </div>
  </div>
  <div *ngIf="data === 'others'">
    <div class="d-flex align-items-center" style="flex-direction: column;">
      <img
        src="assets\img\modal-images\benefits-img.png"
        class="img-fluid img-provider mt-2"
        style="max-height: 250px;"
        alt="others"
      />
      <h5 class="heading-h5 text-center">¿Qué beneficios ofrece Neat?</h5>
      <p class="text-center">
        La falta de fondos en la cuenta corriente para poder pagar todas las
        cuentas del mes.
      </p>
      <a
        mat-raised-button
        id="onboard-survey-go-to-benefits-of-neat-article"
        class="main-btn mt-3"
        (click)="onNoClick()"
        style="width: fit-content !important;"
        href="https://neatpagos.com/blog/articulo/que-beneficios-ofrece-neat"
        target="_blank"
        >IR AL BLOG A CONOCER MÁS</a
      >
      <span
        id="read-article-later"
        class="underlined-btn mt-3"
        (click)="onNoClick()"
        >Quizás, en otro momento</span
      >
    </div>
  </div>
</div>
