import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CustomProvidersNames } from 'neat-lib/dist/Enums/Constants';

import { RentListService } from '@services/rent-list/rent-list.service';

declare let Intercom: any;

@Component({
  selector: 'app-basic-service-help-modal',
  templateUrl: './basic-service-help-modal.component.html',
  styleUrls: ['./basic-service-help-modal.component.scss']
})
export class BasicServiceHelpModalComponent {
  isEdiproPayment: boolean;
  isMultiPay: boolean;
  constructor(
    public dialogRef: MatDialogRef<BasicServiceHelpModalComponent>,
    public rentListService: RentListService
  ) {
    const entities = this.rentListService.selectedEntities;
    this.isMultiPay =
      entities.length > 1 && entities.find(entity => !entity?.customData)
        ? true
        : false;
    this.isEdiproPayment = entities.find(
      entity =>
        entity?.customData &&
        entity?.customData.providerName === CustomProvidersNames.edipro
    )
      ? true
      : false;
  }

  closeBtn(): void {
    this.dialogRef.close();
  }

  openIntercom() {
    Intercom('showNewMessage', '');
    this.closeBtn();
  }
}
