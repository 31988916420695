import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import firebase from 'firebase/compat';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  constructor() {}

  recordError(
    error: Error | firebase.auth.Error,
    file: string,
    functionName: string,
    customMessage?: string,
    level?: Sentry.Severity
  ): void {
    Sentry.withScope(scope => {
      scope.setExtra('file', file);
      scope.setExtra('functionName', functionName);
      scope.setExtra('customMessage', customMessage);
      if (level) {
        scope.setLevel(level);
      }
      Sentry.captureException(error);
    });
  }

  recordWarn(
    error: Error | firebase.auth.Error,
    file: string,
    functionName: string,
    customMessage?: string
  ): void {
    Sentry.withScope(scope => {
      scope.setExtra('file', file);
      scope.setExtra('functionName', functionName);
      scope.setExtra('customMessage', customMessage);
      scope.setLevel(Sentry.Severity.Warning);
      Sentry.captureException(error);
    });
  }

  recordLog(
    error: Error | firebase.auth.Error,
    file: string,
    functionName: string,
    customMessage?: string
  ): void {
    Sentry.withScope(scope => {
      scope.setExtra('file', file);
      scope.setExtra('functionName', functionName);
      scope.setExtra('customMessage', customMessage);
      scope.setLevel(Sentry.Severity.Log);
      Sentry.captureException(error);
    });
  }

  setUser(user: Partial<firebase.User>): void {
    Sentry.configureScope(scope => {
      scope.setUser({
        email: user.email,
        id: user.uid,
        username: user.displayName
      });
    });
  }

  getErrorMessage(errorCode: string): string {
    switch (errorCode) {
    case 'auth/user-not-found':
      return 'El usuario no se encuentra registrado.';
    case 'auth/invalid-email':
      return 'El correo ingresado no es válido.';
    case 'auth/user-disabled':
      return 'El usuario ha sido suspendido. Por favor póngase en contacto con nuestro soporte para más información.';
    case 'auth/wrong-password': {
      const message = [
        'Credenciales incorrectas.',
        'Ten en consideración que si creaste tu usuario mediante Google debes seleccionar esa opción.',
        'Si no te acuerdas de la contraseña ingresa a la sección "Recuperar contraseña".',
        'Si no tienes un usuario ingresa a la sección "Regístrate".'
      ].join('\n\n');
      return message;
    }
    case 'auth/email-already-in-use':
      return 'El correo ingresado ya está en uso.';
    case 'auth/operation-not-allowed': {
      const message = [
        'No tiene los permisos suficientes para realizar esta operación.',
        'Por favor póngase en contacto con nuestro soporte para más información.'
      ].join('\n\n');
      return message;
    }
    case 'auth/weak-password':
      return 'La contraseña ingresada no es válida. Tenga en cuenta que la contraseña debe tener al menos 6 caracteres.';
    case 'auth/too-many-requests':
      return 'Han sido demasiadas peticiones desde este dispositivo. Por favor intenta nunevamente más tarde.';
    case 'auth/expired-action-code':
      return 'El enlace de restablecimiento de contraseña ha expirado.';
    case 'auth/invalid-action-code':
      return 'El enlace de restablecimiento de contraseña es inválido. Puede que haya expirado o ya haya sido usado.';
    default:
      return 'Problema desconocido. Si este problema persiste por favor contáctese con nuestro soporte.';
    }
  }
}
