import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

import { IconErrorModals } from '@shared/enums/enums.enum';

import { ErrorHandlerService } from '../../../services/shared/error-handler/error-handler.service';
import { SweetalertService } from '../../../services/shared/sweetalert/sweetalert.service';
import { UserService } from '../../../services/user/user.service';
import { DialogService } from './../../../services/shared/dialog/dialog.service';
import { MyErrorStateMatcher } from './../../../shared/my-error-state-matcher/my-error-state-matcher.directive';

@Component({
  selector: 'app-login-without-password',
  templateUrl: './login-without-password.component.html',
  styleUrls: ['./login-without-password.component.scss']
})
export class LoginWithoutPasswordComponent {
  loginWithLink: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]]
  });
  matcher = new MyErrorStateMatcher();
  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    public dialogService: DialogService,
    public dialogRef: MatDialogRef<LoginWithoutPasswordComponent>,
    private swalService: SweetalertService,
    private errorService: ErrorHandlerService,
    private router: Router
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
  openLoginDialog() {
    this.dialogRef.close();
    this.router.navigate(['']);
  }
  loginWithLinkRequest(): void {
    this.userService
      .requestLoginWithLink(this.loginWithLink.value.email)
      .then(() => {
        this.swalLoginWithLinkSuccess();
      })
      .catch(err => {
        this.errorService.recordError(
          err,
          'login-without-password.ts',
          'this.userService.loginWithLinkRequest()',
          'Error al enviar link para inicio de sesión'
        );
        this.swalLoginWithLinkError();
      });
  }
  swalLoginWithLinkSuccess(): void {
    this.swalService.swalSuccess(
      `Te hemos enviado un mail para que puedas iniciar sesión.`,
      'Por favor revisa tu correo y sigue las instrucciones.'
    );
  }
  swalLoginWithLinkError(): void {
    this.swalService.swalError2(
      'No se ha podido reestablecer tu contraseña',
      `Por favor inténtalo de nuevo en unos momentos. Si el problema persiste escríbenos al chat. `,
      IconErrorModals.sadCloud,
      false,
      false
    );
  }
}
