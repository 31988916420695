import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-neat-prime-modal',
  templateUrl: './neat-prime-modal.component.html',
  styleUrls: ['./neat-prime-modal.component.scss']
})
export class NeatPrimeModalComponent {
  constructor(public dialogRef: MatDialogRef<NeatPrimeModalComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
