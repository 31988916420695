import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from 'app/services/user/user.service';

import { DialogService } from './../../services/shared/dialog/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private userService: UserService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot
  ): boolean | Observable<boolean> | Promise<boolean> {
    const isInEdiproFlow = localStorage.getItem('ediproFlow');
    const isInCredituFlow = localStorage.getItem('credituFlow');
    const basicServicesFlow = localStorage.getItem('basicServiceFlow');
    const isInRetryFlow = localStorage.getItem('retryTransfer');
    const providerFlow = next.queryParams.action;
    if (
      providerFlow === 'ediproFlow' ||
      providerFlow === 'credituFlow' ||
      providerFlow === 'basicServiceFlow'
    ) {
      const providerInfo = {
        payerEmail: next.queryParams.payer_email,
        billId: next.queryParams.bill_id,
        biller: next.queryParams.biller,
        initTimestamp: new Date(),
        providerFlow: providerFlow,
        comesFrom: next?.queryParams?.comesFrom || null,
        providerName: next?.queryParams?.providerName || null
      };
      // eslint-disable-next-line no-async-promise-executor, @typescript-eslint/no-unused-vars
      return new Promise(async (resolve, reject) => {
        const currentUser = await this.userService.getCurrentUser();
        localStorage.setItem(providerFlow, JSON.stringify(providerInfo));
        if (currentUser && currentUser.email === providerInfo.payerEmail) {
          return this.router.navigate(['/customProviderLogIn']);
        }
        if (currentUser && currentUser.email !== providerInfo.payerEmail) {
          this.userService.logOut();
        }
        return this.router.navigate(['/customProviderLogIn']);
      });
    } else {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve) => {
        const currentUser = await this.userService.getCurrentUser();
        if (!currentUser) {
          const params = next.queryParams;
          isInEdiproFlow || isInCredituFlow || basicServicesFlow
            ? this.router.navigate(['/customProviderLogIn'])
            : this.router.navigate(['inicia-sesion'], { queryParams: params });
          resolve(false);
        } else {
          if (isInRetryFlow) {
            this.router.navigate(['dashboard/historial']);
          }
          resolve(true);
        }
      });
    }
  }
}
