<div class="presentation-modal-checkout text-center">
  <div class="title-header mb-2 mb-md-3">
    <span class="title">
      Incorporamos tu feedback y mejoramos la sección de pagos
    </span>
  </div>
  <div class="title-header content-modal">
    <span class="d-block subtitle mb-2">
      Principales cambios:
    </span>
    <span class="d-block secundary mb-2">
      🚀 Seleccionar tu método de pago ahora es más fácil
    </span>
    <span class="d-block secundary mb-2">
      🚀 La experiencia desde celular es más intuitiva
    </span>
    <span class="d-block secundary mb-2">
      🚀 Simplificamos el resumen de tu pago
    </span>
  </div>
  <span class="d-block normal-text mt-1 my-3">
    Podrás comparar ambas versiones hasta el 31 de mayo
  </span>
  <div class="btns my-3 w-100 text-center mx-auto">
    <button
      id="button-accept-presentation-modal-checkout"
      [disableRipple]="true"
      style="max-width: 250px;"
      type="submit"
      (click)="closeBtn()"
      class="w-100 neat-blue-btn"
      mat-raised-button
    >
      Bacán
    </button>
  </div>
</div>
