import { Component, Inject, Input, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { UserService } from '@services/user/user.service';
import { User } from 'app/models/users/user.model';

import { VerifyIdentityModalComponent } from '../verify-identity-modal/verify-identity-modal.component';

@Component({
  selector: 'app-neat-club-modal',
  templateUrl: './neat-club-modal.component.html',
  styleUrls: ['./neat-club-modal.component.scss']
})
export class NeatClubModalComponent implements OnInit {
  @Input() currentUser: User;
  preregisteredUser = false;
  title = 'Ya pasaste por aquí.';
  subtitle =
    'Sabemos que es emocionante, pero ya tenemos tu contacto y te informaremos sobre cualquier novedad.';
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<VerifyIdentityModalComponent>,
    private userService: UserService,
    private fireAnalytics: AngularFireAnalytics,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.currentUser = this.data.user;
    const isUserPreRegisteredInNeatClub = this.checkPreRegisteredNeatClubUser();
    if (isUserPreRegisteredInNeatClub) {
      this.preregisteredUser = true;
    }
  }

  checkPreRegisteredNeatClubUser(): boolean {
    return this.currentUser?.extraData &&
      Object.keys(this.currentUser?.extraData).filter(res => {
        return (
          res.includes('neatClubPreRegister') ||
          res.includes('neatClubPreRegistration')
        );
      }).length > 0
      ? true
      : false;
  }

  onClose(): void {
    this.dialogRef.close();
  }

  async saveNeatClubRegistry(): Promise<void> {
    this.loading = true;
    const checkRegisteredNeatClubUser = this.checkPreRegisteredNeatClubUser();
    if (!checkRegisteredNeatClubUser) {
      const data = this.currentUser?.extraData;
      let updateUserData = {};
      if (this.currentUser?.extraData) {
        data['neatClubPreRegister'] = true;
        updateUserData = new User().deserialize({ extraData: data });
      } else {
        updateUserData = new User().deserialize({
          extraData: { neatClubPreRegister: true }
        });
      }
      await this.userService.updateUserDoc(
        updateUserData,
        this.currentUser?.id
      );
      this.fireAnalytics.logEvent('click_on_pre_register_neat_club_button');
      this.title = '¡Genial! Ya te registramos.';
      this.subtitle =
        'Pronto recibirás mas información de NeatClub y todos sus beneficios.';
      this.preregisteredUser = true;
      this.loading = false;
    }
  }
}
