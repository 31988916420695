import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-preloading',
  templateUrl: './preloading.component.html',
  styleUrls: ['./preloading.component.scss']
})
export class PreloadingComponent {
  @Input() showPreloader: boolean;
  constructor() {}
}
