<div class="boxes" *ngIf="!loading">
  <div class="header">
    <div class="header-content">
      <span
        *ngIf="success === null && error === null"
        class="d-block blue-title"
        >Confirmar canje</span
      >
      <span *ngIf="success" class="d-block blue-title">Canje solicitado</span>
      <span *ngIf="error" class="d-block blue-title"
        >No pudimos solicitar tu canje</span
      >
      <span *ngIf="error" class="close-icon" (click)="close()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <g opacity="0.5">
            <path
              d="M1 1.5L11 11.5"
              stroke="#3C4082"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11 1.5L1 11.5"
              stroke="#3C4082"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </span>
    </div>
    <div class="header-divider"></div>
  </div>
  <div class="content">
    <div
      *ngIf="success === null && error === null"
      class="content-inner mx-3 mx-md-4"
    >
      <div
        class="d-flex justify-content-center align-items-center row-direction"
      >
        <div class="reward-box">
          <span class="reward-points">
            {{ rewardSelected.pointCosts | thousandsSeparator }}
          </span>
          <span class="reward-label">
            NeatPoints
          </span>
        </div>
        <span class="mx-4 reward-multiplier">X</span>
        <div class="reward-image">
          <img class="w-100" [src]="rewardSelected.image" alt="neat" />
        </div>
      </div>
      <div
        class="mt-3 d-flex justify-content-center align-items-center column-direction"
      >
        <span class="reward-text">
          Estás por canjear:
        </span>
        <span class="mt-1 reward-name">
          {{ rewardSelected.name }}
        </span>
      </div>
      <div
        *ngIf="rewardSelected.provider === 'CMR'"
        class="my-3 my-md-2 section-divider"
      ></div>

      <div
        *ngIf="rewardSelected.provider === 'CMR'"
        class="mt-2 reward-provider"
      >
        Serán asociados al RUT {{ currentUser.rut | formatRut }}
      </div>
    </div>
    <div *ngIf="success" class="content-inner mx-3 mx-md-4">
      <div
        class="d-flex d-md-none justify-content-center align-items-center column-direction"
      >
        <div class="reward-image">
          <img class="w-100" [src]="rewardSelected.image" alt="neat" />
        </div>
        <span class="mt-4 mb-3 reward-name">
          {{ rewardSelected.name }}
        </span>
        <span class="reward-confirmation">
          Recibirás un mail de confirmación en los próximos minutos con el
          detalle del canje.
        </span>
      </div>
      <div
        class="d-none d-md-flex justify-content-center align-items-center column-direction"
      >
        <span class="reward-name">
          {{ rewardSelected.name }}
        </span>
        <div class="my-4 reward-image">
          <img class="w-100" [src]="rewardSelected.image" alt="neat" />
        </div>
        <span class="reward-confirmation">
          Recibirás un mail de confirmación en los próximos minutos con el
          detalle del canje.
        </span>
      </div>
    </div>
    <div *ngIf="error" class="content-inner mx-3 mx-md-4">
      <div
        class="d-flex justify-content-center align-items-center column-direction"
      >
        <span class="error-message mb-3">
          Ups! Ocurrió un problema al solicitar tu canje de NeatPoints
        </span>
        <span class="error-suggestion">
          Inténtalo de nuevo, o háblanos al chat para revisarlo si el problema
          persiste.
        </span>
      </div>
    </div>
  </div>
  <div class="footer">
    <div class="footer-divider"></div>
    <div
      *ngIf="success === null && error === null"
      class="footer-buttons p-4 d-flex"
    >
      <button class="neat-btn neat-btn__secondary w-100 mr-2" (click)="close()">
        <span>
          No, gracias
        </span>
      </button>
      <button
        class="neat-btn neat-btn__primary w-100 ml-2"
        (click)="redeemReward()"
      >
        <span>
          Sí, canjear
        </span>
      </button>
    </div>
    <div
      *ngIf="success"
      class="footer-buttons p-4 d-flex justify-content-center"
    >
      <button
        class="neat-btn neat-btn__primary w-100 ml-2"
        (click)="goToNeatClub()"
      >
        <span>
          Ir a mi cuenta
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M16.675 13H4.5V11H16.675L11.075 5.4L12.5 4L20.5 12L12.5 20L11.075 18.6L16.675 13Z"
              fill="white"
            />
          </svg>
        </span>
      </button>
    </div>
    <div *ngIf="error" class="footer-buttons p-4 d-flex">
      <button class="neat-btn neat-btn__secondary w-100 mr-2" (click)="close()">
        <span>
          Cerrar
        </span>
      </button>
      <button
        class="neat-btn neat-btn__primary w-100 ml-2"
        (click)="openIntercom()"
      >
        <span>
          Ir al chat
        </span>
      </button>
    </div>
  </div>
</div>
<div
  *ngIf="loading"
  class="h-100 d-flex align-items-center justify-content-center"
>
  <div
    class="main-card align-items-center justify-content-center text-center"
    style="flex-direction: column;"
  >
    <div class="svg-container mobile-center">
      <svg
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="24"
        viewBox="0 0 10 24"
        fill="none"
      >
        <path id="svg1" opacity="0.3" d="M10 0H0V24H10V0Z" fill="#9DBEF7" />
      </svg>
      <svg
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="24"
        viewBox="0 0 10 24"
        fill="none"
      >
        <path id="svg2" opacity="0.3" d="M10 0H0V24H10V0Z" fill="#9DBEF7" />
      </svg>
      <svg
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="24"
        viewBox="0 0 10 24"
        fill="none"
      >
        <path id="svg3" opacity="0.3" d="M10 0H0V24H10V0Z" fill="#9DBEF7" />
      </svg>
    </div>
    <span class="primary-text d-block mt-3">Solicitando canje...</span>
  </div>
</div>
