<section class="row animated fadeIn">
  <div class="d-flex flex-column justify-content-center align-center mt-4 p-5">
    <div class="d-flex justify-content-center align-center w-100 mb-4">
      <img
        src="assets/img/illustrations/il-sand-watch.png"
        style="width: 68px !important;"
        alt="neat"
        srcset="
          assets/img/illustrations/il-sand-watch.png    1x,
          assets/img/illustrations/il-sand-watch@2x.png 2x
        "
      />
    </div>
    <h1 class="import-modal-title mb-4">Trabajando para usted</h1>
    <div class="progress-container">
      <div class="progress-bar" [style.width.%]="progress"></div>
    </div>
    <p class="import-modal-disclaimer mt-4">
      Estamos validando tus datos de acceso a Servipag. Esto solo demorará un
      momento...
    </p>
  </div>
</section>
