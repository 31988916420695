import { Component, Inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IFields, IBasicServiceDebt, INeatServices } from 'neat-lib';
import {
  CustomProvidersNames,
  EntityTypeLocale
} from 'neat-lib/dist/Enums/Constants';
import {
  areInvoicesToBePaidValid,
  isCustomProviderEnabledToBePaid
} from 'neat-lib/dist/Functions/Functions';
import { Observable } from 'rxjs/internal/Observable';
import { take } from 'rxjs/internal/operators/take';

import { PaymentsService } from '@services/payments/payments.service';
import { RentListService } from '@services/rent-list/rent-list.service';
import { MergeTypes } from '@shared/utils';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';

declare let Intercom: any;
@Component({
  selector: 'app-entity-added-modal',
  templateUrl: './entity-added-modal.component.html',
  styleUrls: ['./entity-added-modal.component.scss']
})
export class EntityAddedModalComponent {
  entity: any;
  entityTypeLocale = EntityTypeLocale;
  allEntities$: Observable<MergeTypes<BasicService, Entity>[]>;
  enabledToPay = true;
  neatServices: INeatServices[] = null;
  showAutomaticFlow = false;
  isLoading = false;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      basicServiceNoDebtAutoTreatment: boolean;
      entity: any;
    },
    public router: Router,
    public dialogRef: MatDialogRef<EntityAddedModalComponent>,
    private rentListService: RentListService,
    private fireAnalytics: AngularFireAnalytics,
    private paymentsService: PaymentsService
  ) {
    this.entity = this.data.entity;
    this.allEntities$ = this.rentListService.allUnifiedEntities$.pipe(take(1));
    if (
      this.entity?.value > 0 &&
      this.entity?.customData &&
      this.entity?.customData.providerName !== CustomProvidersNames.edipro
    ) {
      this.enabledToPay = isCustomProviderEnabledToBePaid(
        this.entity?.customData.providerName,
        this.entity?.customData?.lastUpdate,
        this.entity.value,
        this.entity.valueUF
      );
    }
    if (
      this.entity.value === 0 &&
      !this.entity.currentDebtErrror &&
      this.entity.entityType === 'basicService' &&
      this.data.basicServiceNoDebtAutoTreatment
    ) {
      this.showAutomaticFlow = true;
    }
    this.rentListService.allBasicServicesTAPI$
      .pipe(take(1))
      .subscribe(neatServices => {
        this.neatServices = neatServices;
      });
    this.rentListService.currentEntityForConfig = this.entity as any;
  }

  closeBtn(): void {
    this.dialogRef.close();
    this.fireAnalytics.logEvent('CloseModalVisibilityAutomaticPayment');
  }

  goToPay(entity: MergeTypes<BasicService, Entity>) {
    const analyticsParams = {
      event: 'goToPay',
      section: 'addPreloadedEntityModal'
    };
    this.rentListService.allUnifiedEntities$
      .pipe(take(1))
      .subscribe(entities => {
        const updatedEntity = entities.find(
          entities => entities.id === entity.id
        );
        if ((entity.entityType as any) === 'basicService') {
          this.rentListService.goToPay(
            Object.assign(updatedEntity, {
              invoicesToBePayed: this.updateInvoicesToBePayed(entity)
            }),
            analyticsParams,
            true,
            this.dialogRef
          );
        } else {
          this.rentListService.goToPay(
            updatedEntity,
            analyticsParams,
            true,
            this.dialogRef
          );
        }
      });
  }

  goToAutomate(entity: MergeTypes<BasicService, Entity>): void {
    const analyticsParams = {
      event: 'goToAutomate',
      section: 'addPreloadedEntityModal'
    };
    this.rentListService.goToAutomate(
      entity,
      true,
      analyticsParams,
      this.dialogRef
    );
  }

  goToEdit(entity: MergeTypes<BasicService, Entity>): void {
    const analyticsParams = {
      event: 'goToEdit',
      section: 'addPreloadedEntityModal'
    };
    this.rentListService.goToAutomate(
      entity,
      false,
      analyticsParams,
      this.dialogRef
    );
  }

  openIntercom(): void {
    Intercom('showNewMessage', '');
  }

  updateInvoicesToBePayed(
    entity: MergeTypes<BasicService, Entity>
  ): IFields[] | IFields {
    if (
      (entity.entityType as any) === 'basicService' &&
      entity?.currentDebt &&
      entity.currentDebt?.invoices.length > 1 &&
      this.payValidationValidator(entity.payValidation, entity?.currentDebt)
    ) {
      return this.setBasicServiceInvoicesToBePayed(
        entity.id,
        entity.payValidation,
        entity.currentDebt
      );
    }
    if (
      (entity.entityType as any) === 'basicService' &&
      entity?.currentDebt &&
      entity.currentDebt?.invoices.length === 1
    ) {
      return entity.currentDebt.invoices[0];
    }
  }

  setBasicServiceInvoicesToBePayed(
    id: string,
    payValidation: number,
    currentDebt: IBasicServiceDebt
  ): IFields[] {
    if (
      this.paymentsService.invoicesToBePayed[id] &&
      this.paymentsService.invoicesToBePayed[id]?.totalAmount
    ) {
      return this.paymentsService.invoicesToBePayed[id]?.invoices;
    }
    if (!payValidation || [1, 6].includes(payValidation)) {
      return [currentDebt.invoices[0]];
    } else {
      return currentDebt.invoices;
    }
  }

  payValidationValidator(
    payValidation: number,
    currentDebt: IBasicServiceDebt
  ): boolean {
    if (!payValidation || [1, 6].includes(payValidation)) {
      return areInvoicesToBePaidValid(
        [currentDebt.invoices[0]],
        currentDebt.invoices,
        payValidation
      );
    } else {
      return areInvoicesToBePaidValid(
        currentDebt.invoices,
        currentDebt.invoices,
        payValidation
      );
    }
  }

  getBasicServiceLogo(utilityNumber: string | number): string {
    const neatService = this.neatServices.find(
      service => service.neatCode === utilityNumber.toString()
    );
    return neatService?.logoUrl;
  }

  setIsLoading(isLoading: boolean): void {
    this.isLoading = isLoading;
  }
}
