import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from './../shared/angular-material/angular-material.module';
import { EmailHandlerComponent } from './email-handler.component';


@NgModule({
  declarations: [EmailHandlerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule
  ]
})
export class EmailHandlerModule {}
