<div>
  <div>
    <h2 class="title">Tu tarjeta fue eliminada correctamente</h2>
  </div>
  <div>
    <p class="main-text mt-2 mb-3">
      La siguiente tarjeta quedó asociada a tus pagos automáticos:
    </p>
  </div>
  <div>
    <ng-container *ngIf="paymentMethod">
      <div class="w-100 text-center row custom-wrap">
        <div class="col-6 m-auto mb-2 mb-md-3 custom-width">
          <div class="credit-card-box">
            <div>
              <img
                src="assets/payments-methods-icons/{{
                  paymentMethod.cardType
                }}.svg"
                class="img-fluid img-entity mr-2"
                style="width: 24px; height: 16px;"
                alt="{{ paymentMethod.cardType }}"
              />
            </div>
            <div
              style="display: flex; flex-direction: column; align-items: baseline;"
            >
              <span class="mb-2"
                ><b>{{ paymentMethod.cardType }}</b></span
              >
              <span>{{
                formatCreditCardNumbers(paymentMethod.cardNumber)
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="d-flex justify-content-center">
    <button
      id="close-modal-after-delete-credit-card-with-automatic-entities-associated"
      [disableRipple]="true"
      type="submit"
      (click)="close()"
      style="width: fit-content;"
      class="neat-main-btn mt-2"
      mat-raised-button
    >
      <span style="font-size: 16px;">Aceptar</span>
    </button>
  </div>
</div>
