import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatRut'
})
export class FormatRutPipe implements PipeTransform {
  transform(rut: any): unknown {
    if (!rut) {
      return '';
    }
    rut = String(rut);
    rut = rut.replace(/\./g, '');
    rut = rut.replace(/-/g, '');
    switch (rut.length) {
    case 1:
    case 2:
      return rut;
    case 3:
    case 4:
      // eslint-disable-next-line no-case-declarations
      let valor = rut.replace(/\./g, '');
      valor = valor.replace(/-/g, '');
      // eslint-disable-next-line no-case-declarations
      const cuerpo = valor.slice(0, -1);
      // eslint-disable-next-line no-case-declarations
      const dv = valor.slice(-1).toUpperCase();
      rut = `${cuerpo}-${dv}`;
      return rut;
    case 5:
      return `${rut.slice(0, 1)}.${rut.slice(1, 4)}-${rut.slice(4)}`;
    case 6:
      return `${rut.slice(0, 2)}.${rut.slice(2, 5)}-${rut.slice(5)}`;
    case 7:
      return `${rut.slice(0, 3)}.${rut.slice(3, 6)}-${rut.slice(6)}`;
    case 8:
      return `${rut.slice(0, 1)}.${rut.slice(1, 4)}.${rut.slice(
        4,
        7
      )}-${rut.slice(7)}`;
    case 9:
      return `${rut.slice(0, 2)}.${rut.slice(2, 5)}.${rut.slice(
        5,
        8
      )}-${rut.slice(8)}`;
    }
  }
}
