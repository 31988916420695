import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaymentProduct, PlatformType } from 'neat-lib/dist/Enums/Constants';
import { combineLatest, debounceTime } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { take } from 'rxjs/internal/operators/take';
import { Subject } from 'rxjs/internal/Subject';
import { Subscription } from 'rxjs/internal/Subscription';

import { CloudService } from '@services/cloud/cloud.service';
import { RentListService } from '@services/rent-list/rent-list.service';
import { DatabaseService } from '@services/shared/database/database.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { UserService } from '@services/user/user.service';
import { AppGlobals } from '@shared/constants';
import { BasicServiceType } from '@shared/enums/enums.enum';
import { basicServicesSuggested, MergeTypes } from '@shared/utils';
import { IBasicServiceConfiguration } from 'app/interfaces/basic-services.interface';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';
import { UserInfo } from 'app/models/users/user-info.model';
import { User } from 'app/models/users/user.model';

declare let Intercom: any;

@Component({
  selector: 'app-basic-services-by-rut-modal',
  templateUrl: './basic-services-by-rut-modal.component.html',
  styleUrls: ['./basic-services-by-rut-modal.component.scss']
})
export class BasicServicesByRutModalComponent implements OnInit, OnDestroy {
  basicServicesAvailable = basicServicesSuggested;
  selectedBasicServices = [];
  step = 2;
  currentUser: User;
  utilityTypes = [];
  initialNumberOfBasicServices = 0;
  entitiesToSaveLength = 0;
  totalEntitiesLength = 0;
  basicServicesCompanies: IBasicServiceConfiguration[] = [];
  private allSubscriptions: Subscription = new Subscription();
  private detectBasicServices: Subscription = new Subscription();
  basicServicesSaved: MergeTypes<BasicService, Entity>[] = [];
  arrayOfBasicServicesAdded: Subject<BasicService[]> = new Subject<[]>();
  savedValues = [];
  allBasicServicesAdded = true;
  success: boolean;
  progressWidth: Observable<string> = of('0%');
  errorMessage: string;
  checkTimeout: any;
  teamNeat = false;
  isSameRut = true;
  haveEntitiesAdded = false;
  userInfo: UserInfo;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { rut: string; selectedBasicServices: any[] },
    public dialogRef: MatDialogRef<BasicServicesByRutModalComponent>,
    private userService: UserService,
    private rentListService: RentListService,
    private afs: AngularFirestore,
    private fireAnalytics: AngularFireAnalytics,
    private errorService: ErrorHandlerService,
    private db: DatabaseService,
    public router: Router,
    private cloudService: CloudService
  ) {
    this.selectedBasicServices = this.data.selectedBasicServices;
    this.rentListService.allUnifiedEntities$
      .pipe(take(1))
      .subscribe(entities => {
        this.haveEntitiesAdded = entities.length > 1 ? true : false;
      });
    this.userService.userInfo$.pipe(take(1)).subscribe(userInfo => {
      this.userInfo = userInfo;
    });
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('cdk-global-scrollblock');
  }

  ngOnInit(): void {
    this.getAllBasicServicesAvailables();
    this.userService.currentUserData$.pipe(take(1)).subscribe((user: User) => {
      this.currentUser = user;
      if (user.rut) {
        if (
          AppGlobals.formatRut(String(user.rut)) !==
          AppGlobals.formatRut(String(this.data.rut))
        ) {
          this.isSameRut = false;
        }
        this.saveBasicServices(this.data.rut);
      } else {
        this.saveRut();
      }
    });
    this.rentListService.allBasicServices$
      .pipe(take(1))
      .subscribe(basicService => {
        if (basicService && basicService.length >= 0) {
          this.initialNumberOfBasicServices = basicService.length;
        }
      });
    this.arrayOfBasicServicesAdded.subscribe({
      next: basicServicesAdded => {
        if (this.entitiesToSaveLength > 0) {
          const basicServicesDetected = basicServicesAdded.map(res => res.id);
          const basicServicesLength = Array.from(new Set(basicServicesDetected))
            .length;
          if (this.teamNeat) {
            console.info(
              `la cantidad de cuentas guardadas es ${basicServicesLength}, el total a guardar son ${this.entitiesToSaveLength}`
            );
          }
          const getPercentage =
            (basicServicesLength * 100) / this.entitiesToSaveLength;
          this.progressWidth = of(
            `${
              Math.round(getPercentage) > 100 ? 100 : Math.round(getPercentage)
            }%`
          );
          if (basicServicesLength === this.entitiesToSaveLength) {
            this.arrayOfBasicServicesAdded?.unsubscribe();
            this.detectBasicServices?.unsubscribe();
            this.fireAnalytics.logEvent(
              'Cuentas básicas agregadas desde modal',
              {
                Respuesta: 'Success',
                Total: this.entitiesToSaveLength,
                Cantidad: this.basicServicesSaved?.length
              }
            );
            clearTimeout(this.checkTimeout);
            this.success = true;
            this.step = 3;
            if (this.teamNeat) {
              console.timeEnd('cuentasxrut');
            }
          }
        }
      },
      error: error => {
        this.errorHeapAndAnalytics(error);
        this.errorMessage =
          'Ocurrió un problema al intentar guardar tus cuentas :(';
        this.success = false;
        this.step = 3;
      }
    });
  }

  errorHeapAndAnalytics(err: any): void {
    this.errorService.recordError(
      err,
      'basic-services-by-rut-modal.component.ts',
      'errorHeapAndAnalytics()',
      'Error al guardar cuentas básicas'
    );
    this.fireAnalytics.logEvent(
      'Error para agregar cuentas básicas desde modal',
      {
        Error: err
      }
    );
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('cdk-global-scrollblock');
    this.allSubscriptions?.unsubscribe();
    this.detectBasicServices?.unsubscribe();
    this.arrayOfBasicServicesAdded?.unsubscribe();
    clearTimeout(this.checkTimeout);
    this.success !== false && this.userService.setShowBasicServiceScreen(false);
  }

  onNoClick(): void {
    this.fireAnalytics.logEvent('Cerrar modal cuentas básicas por rut', {
      Motivo: 'Sin interés'
    });
    this.dialogRef.close();
  }

  onNoClickFinished(): void {
    this.fireAnalytics.logEvent(
      'Cerrar modal cuentas básicas por rut luego de agregar'
    );
    this.dialogRef.close();
    this.userService.setShowBasicServiceScreen(false);
  }

  onNoClickNotFound(): void {
    this.dialogRef.close();
    this.userService.setShowBasicServiceScreen(false);
  }

  goToHome(): void {
    this.rentListService.selectedNav = 2;
    this.dialogRef.close();
    this.router.navigate([`/dashboard/home`]);
    this.fireAnalytics.logEvent('Cerrar modal cuentas básicas por rut', {
      Motivo: 'cuentas agregadas'
    });
  }

  detectTimeOut(): void {
    this.success = false;
    this.errorMessage = 'No pudimos guardar todas tus cuentas :(';
    this.step = 3;
    this.errorHeapAndAnalytics(new Error('Front timeout'));
    this.basicServicesSaved = [];
  }

  saveBasicServices(manualRut?: string): void {
    try {
      if (this.teamNeat) {
        console.time('cuentasxrut');
      }
      this.checkTimeout = setTimeout(() => {
        this.detectTimeOut();
      }, 180_000);
      this.step = 2;
      const savingBasicService: BasicService[] = this.fillBasicServiceObjectWithFormData(
        manualRut ? manualRut : this.currentUser.rut
      );
      this.entitiesToSaveLength = savingBasicService.length;
      savingBasicService.forEach(basicServiceCreated => {
        this.saveBasicService(basicServiceCreated);
      });
      const isAutotag = savingBasicService?.find(
        basicService => basicService?.utilityNumber === 'NT-CL-G-00400'
      )
        ? true
        : false;
      const allBasicServices = isAutotag
        ? this.rentListService.allUnifiedEntities$.pipe(debounceTime(4000))
        : this.rentListService.allUnifiedEntities$;
      this.detectBasicServices.add(
        allBasicServices.subscribe({
          next: basicService => {
            if (
              basicService.length >= this.totalEntitiesLength ||
              this.totalEntitiesLength === 0
            ) {
              if (this.teamNeat) {
                console.info(`se actualiza total de maxima cantidad de basic services de user 
                con total de ${basicService.length}, antes tenía ${this.totalEntitiesLength}`);
              }
              this.totalEntitiesLength = basicService.length;
            } else {
              this.allBasicServicesAdded = false;
              this.entitiesToSaveLength--;
              if (this.teamNeat) {
                console.info(
                  `re remueve una entidad autodestruida, ahora el total a agregar es de ${this.entitiesToSaveLength}`
                );
              }
            }
            this.basicServicesSaved = basicService.filter(item1 =>
              savingBasicService.some(item2 => item2.id === item1.id)
            );
            this.basicServicesSaved.forEach(basicService => {
              if (basicService?.verified) {
                this.savedValues.push(basicService);
                try {
                  this.arrayOfBasicServicesAdded?.next(this.savedValues);
                } catch (e) {
                  console.error('error al guardar cuentas');
                }
              }
            });
            this.totalEntitiesLength = basicService.length;
          },
          error: err => {
            this.errorHeapAndAnalytics(err);
            this.success = false;
            this.errorMessage =
              'Ocurrió un problema al intentar guardar tus cuentas :(';
            this.step = 3;
          }
        })
      );
    } catch (err) {
      this.errorHeapAndAnalytics(err);
      this.success = false;
      this.errorMessage =
        'Ocurrió un problema al intentar guardar tus cuentas :(';
      this.step = 3;
    }
  }

  getAllBasicServicesAvailables(): void {
    const mergeSubscriptions: any = combineLatest([
      this.rentListService.allBasicServicesTAPI$
    ]);
    this.allSubscriptions.add(
      mergeSubscriptions.subscribe(([neatServices]) => {
        const {
          utilityTypes,
          basicServicesCompanies
        } = this.rentListService.getAvailableBasicServices(neatServices);
        this.utilityTypes = utilityTypes;
        this.basicServicesCompanies = basicServicesCompanies;
      })
    );
  }

  private fillBasicServiceObjectWithFormData(rut: string): BasicService[] {
    const basicServicesToBeAdded = [];
    const values = this.selectedBasicServices.map(cat => cat.utilityNumber);
    const mergedValues = [];
    values.forEach(val => {
      val.map(res => mergedValues.push(res));
    });
    mergedValues.forEach(selectedUtilityNumbers => {
      const findCompanie = this.basicServicesCompanies?.find(
        basicService =>
          basicService?.utilityNumber === Number(selectedUtilityNumbers) ||
          basicService?.neatCode === selectedUtilityNumbers
      );
      if (findCompanie) {
        const clientNumber = AppGlobals.formatRut(
          rut.replace(/[^a-z0-9]/gi, '')
        );
        const basicServiceObject = this.buildBasicServiceObject(
          findCompanie,
          clientNumber,
          selectedUtilityNumbers
        );
        basicServicesToBeAdded.push(basicServiceObject);
      }
    });
    return basicServicesToBeAdded;
  }

  buildBasicServiceObject(
    findCompanie: any,
    rut: string,
    utilityNumber: string
  ): BasicService {
    const selectedBasicService = this.rentListService.basicServiceFactory.deserializeBasicService(
      BasicServiceType.basicService,
      {}
    );
    const id = this.afs.createId();
    const basicService = this.rentListService.basicServiceFactory.deserializeBasicService(
      BasicServiceType.basicService,
      selectedBasicService
    );
    Object.assign(basicService, {
      id: String(id),
      sendReminderNotification: true,
      platform: PlatformType.web,
      entityType: BasicServiceType.basicService,
      category: findCompanie.neatUtilityCategory,
      utilityName: findCompanie.utilityName || findCompanie.neatName,
      clientNumber: rut,
      lesseeEmail: this.currentUser.email,
      payer: {
        email: this.currentUser.email,
        fullName: this.currentUser?.displayName,
        identifier: rut
      },
      paymentOption: {
        automatic: false,
        paymentMethodId: null,
        product: PaymentProduct.oneclick
      },
      experiment: 'cuentas por rut',
      utilityType: findCompanie.utilityType || findCompanie.neatCategory,
      utilityNumber: String(utilityNumber),
      alias: '',
      neatDebtMultiEngine: true,
      neatInformation: {
        serviceNeatCategory: findCompanie.neatCategory,
        serviceNeatCode: String(utilityNumber),
        userClientId: this.currentUser.id
      }
    });

    return basicService;
  }

  private saveBasicService(savingBasicService: BasicService): Promise<void> {
    return this.db.firestoreUpdateEntity(
      this.rentListService.basicServiceFactory.getBasicServiceCollectionName(
        BasicServiceType.basicService
      ),
      Object.assign({}, savingBasicService),
      savingBasicService.id
    );
  }

  async saveRut() {
    this.step = 2;
    const res = await this.cloudService.rutEnroller(
      AppGlobals.formatRut(String(this.data.rut))
    );
    if (res.status) {
      this.saveBasicServices(AppGlobals.formatRut(String(this.data.rut)));
    } else {
      this.errorHeapAndAnalytics(new Error('Guardar rut'));
      this.success = false;
      this.errorMessage = 'Ocurrió un problema al intentar guardar tu rut :(';
      this.step = 3;
    }
  }

  openIntercom(): void {
    this.dialogRef.close();
    Intercom('showNewMessage', '');
  }
}
