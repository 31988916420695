<div *ngIf="showLoader" class="preloader-2">
  <div class="loader-2">
    <div class="loader-2__figure"></div>
    <p class="loader-2__label text-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="47"
        height="32"
        viewBox="0 0 47 32"
        fill="none"
      >
        <path
          d="M17.9506 11.1874L15.1103 8.41372L10.981 12.4401V8.98942C10.9828 7.64561 11.5308 6.35738 12.5046 5.40733C13.4785 4.45727 14.7987 3.92296 16.1756 3.92159H35.7903V9.12689H39.8087V0H16.1756C13.7335 0.00272898 11.3922 0.950705 9.66537 2.63596C7.93853 4.32121 6.96716 6.60612 6.96436 8.98942V12.4401L2.84033 8.41372L0 11.1874L8.9806 19.9517L17.9506 11.1874Z"
          fill="#046CEB"
        />
        <path
          d="M46.8628 20.82L37.8822 12.0557L28.9016 20.82L31.7419 23.5919L35.829 19.6033V23.0093C35.8268 24.3428 35.2872 25.6219 34.3268 26.5705C33.3664 27.519 32.0622 28.0608 30.6959 28.0788H11.0813V22.9114H7.06641V32.0004H30.6378C33.0807 31.9977 35.4229 31.0497 37.1506 29.3642C38.8783 27.6787 39.8507 25.3934 39.8544 23.0093V19.5242L44.0224 23.5919L46.8628 20.82Z"
          fill="#046CEB"
        />
      </svg>
      <span class="d-block mt-2" [style.color]="textColor">{{
        loadingText
      }}</span>
    </p>
  </div>
</div>
