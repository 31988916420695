<div *ngIf="!showLoading && !showInitLoading">
  <div class="w-100 py-4 px-2 py-md-0 px-md-0">
    <div *ngIf="!addingEntity">
      <span
        class="close-icon d-none d-md-flex justify-content-end py-3 py-md-0"
        (click)="close()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <g opacity="0.5">
            <path
              d="M1 1.5L11 11.5"
              stroke="#3C4082"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11 1.5L1 11.5"
              stroke="#3C4082"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </span>
      <span class="modal-title d-block mb-3" *ngIf="selectedEntity?.customData"
        >Estás programando el pago de
        <b> {{ selectedEntity?.customData?.providerName }}</b></span
      >
      <span class="modal-title d-block mb-3" *ngIf="selectedEntity?.utilityName"
        >Estás programando el pago de
        <b> {{ selectedEntity?.utilityName }}</b></span
      >
      <span
        class="modal-title d-block mb-3"
        *ngIf="!selectedEntity?.customData && !selectedEntity?.utilityName"
        >Estás programando el pago de
        <b> {{ selectedEntity?.showEntityTypeLocale() }}</b></span
      >
    </div>
    <form
      [formGroup]="automatePaymentForm"
      name="automate-payment-from-config-section"
    >
      <div class="blue-box mt-2">
        <div *ngIf="showNeatAutoFeature">
          <span class="bold-text">
            ¿Cómo quieres programar tu pago?
          </span>
          <mat-radio-group
            formControlName="automaticType"
            class="d-md-block mt-2"
          >
            <div
              class="d-flex my-4 mt-md-0 mb-md-4 selection-box"
              [ngClass]="
                automatePaymentForm.get('automaticType').value ===
                'debtDriven'
                  ? 'enabled-box'
                  : 'disabled-box'
              "
            >
              <div class="pr-md-2">
                <mat-radio-button value="debtDriven"></mat-radio-button>
              </div>
              <div>
                <span class="d-block box-title mb-1"
                  >Pagar al encontrar deuda</span
                >
                <span class="d-block box-subtitle">
                  Deja tu cuenta en piloto automático 🚀
                </span>
              </div>
            </div>
            <div
              class="d-flex selection-box w-100"
              [ngClass]="
                automatePaymentForm.get('automaticType').value ===
                'scheduled'
                  ? 'enabled-box'
                  : 'disabled-box'
              "
            >
              <div class="pr-md-2">
                <mat-radio-button value="scheduled"></mat-radio-button>
              </div>
              <div [ngClass]="{ 'd-flex': addingEntity && !isMobile }">
                <div class="w-100">
                  <span class="d-block box-title mb-1"
                    >Fijar fecha de pago</span
                  >
                  <span class="d-block box-subtitle" [ngStyle]="addingEntity && {'fontSize': '12px'}" >
                    Tú eliges cuándo pagamos la cuenta 🗓️
                  </span>
                </div>
                <mat-form-field
                  class="w-100"
                  [ngClass]="addingEntity ? 'mt-0' : 'mt-3'"
                  floatLabel="always"
                  appearance="outline"
                >
                  <mat-label [ngClass]="isMobile ? 'pt-5' : 'pt-4'">Día del mes</mat-label>
                  <input
                    matInput
                    id="transferLimitDate"
                    class="amountInputText"
                    formControlName="transferLimitDate"
                    name="transferLimitDate"
                    autocomplete="off"
                    placeholder="Ejemplo: 12"
                    onkeydown="onlyPositives(event)"
                    [errorStateMatcher]="matcher"
                    required
                  />
                  <mat-error
                    *ngIf="
                      automatePaymentForm.controls[
                        'transferLimitDate'
                      ].hasError('min')
                    "
                    >No puedes ingresar un día menor al
                    <strong>1</strong>.</mat-error
                  >
                  <mat-error
                    *ngIf="
                      automatePaymentForm.controls[
                        'transferLimitDate'
                      ].hasError('required')
                    "
                    >No puedes automatizar sin definir el día de
                    pago.</mat-error
                  >
                  <mat-error
                    *ngIf="
                      automatePaymentForm.controls[
                        'transferLimitDate'
                      ].hasError('max')
                    "
                    >No puedes ingresar un día mayor al
                    <strong>31</strong>.</mat-error
                  >
                  <mat-error
                    *ngIf="
                      automatePaymentForm.controls[
                        'transferLimitDate'
                      ].hasError('invalidNumber')
                    "
                    >Solo se pueden ingresar números</mat-error
                  >
                  <mat-error
                    *ngIf="
                      automatePaymentForm.controls[
                        'transferLimitDate'
                      ].hasError('invalidAutomateDay')
                    "
                    >Esta cuenta solo puede ser automatizada entre los días
                    {{ minDay }} y {{ maxDay }}</mat-error
                  >
                </mat-form-field>
              </div>
            </div>
          </mat-radio-group>
        </div>
        <div *ngIf="!showNeatAutoFeature">
          <app-text class="subtitle-semibold" color="neutral-100"
            >Fijar fecha de pago</app-text
          >
          <span class="d-block description"
            >¿Qué día del mes debemos depositar tu pago?</span
          >
          <mat-form-field
            class="w-100 mt-2"
            floatLabel="always"
            appearance="outline"
          >
            <input
              matInput
              id="transferLimitDate"
              class="amountInputText"
              formControlName="transferLimitDate"
              name="transferLimitDate"
              autocomplete="off"
              placeholder="Ejemplo: 12"
              onkeydown="onlyPositives(event)"
              [errorStateMatcher]="matcher"
              required
            />
            <mat-error
              *ngIf="
                automatePaymentForm.controls['transferLimitDate'].hasError(
                  'min'
                )
              "
              >No puedes ingresar un día menor al
              <strong>1</strong>.</mat-error
            >
            <mat-error
              *ngIf="
                automatePaymentForm.controls['transferLimitDate'].hasError(
                  'required'
                )
              "
              >No puedes automatizar sin definir el día de pago.</mat-error
            >
            <mat-error
              *ngIf="
                automatePaymentForm.controls['transferLimitDate'].hasError(
                  'max'
                )
              "
              >No puedes ingresar un día mayor al
              <strong>31</strong>.</mat-error
            >
            <mat-error
              *ngIf="
                automatePaymentForm.controls['transferLimitDate'].hasError(
                  'invalidNumber'
                )
              "
              >Solo se pueden ingresar números</mat-error
            >
            <mat-error
              *ngIf="
                automatePaymentForm.controls['transferLimitDate'].hasError(
                  'invalidAutomateDay'
                )
              "
              >Esta cuenta solo puede ser automatizada entre los días
              {{ minDay }} y {{ maxDay }}</mat-error
            >
          </mat-form-field>
        </div>
        <div
          *ngIf="
            !selectedEntity?.customData &&
            !selectedEntity?.utilityName &&
            !selectedEntity?.smbProvider
          "
          class="blue-box pt-3"
        >
          <app-text class="subtitle-semibold" color="neutral-100">Monto</app-text>
          <div class="row">
            <mat-form-field
              *ngIf="payingAccountUnit === 'CLP'"
              id="prependboxClp"
              class="col-6"
              floatLabel="always"
              appearance="outline"
            >
              <input
                matInput
                mask="separator.0"
                thousandSeparator="."
                id="amountClp"
                class="amountInputText"
                formControlName="value"
                name="value"
                onkeydown="onlyPositives(event)"
                [errorStateMatcher]="matcher"
                required
              />
              <mat-error
                *ngIf="
                  automatePaymentForm.controls['value'].hasError('required')
                "
                >El monto es <strong>requerido</strong>.</mat-error
              >
              <mat-error
                *ngIf="
                  automatePaymentForm.controls['value'].hasError('min') &&
                  !automatePaymentForm.controls['value'].hasError(
                    'required'
                  )
                "
                >El monto mínimo es
                <strong>{{ minNeatCost | clpCurrency }}</strong
                >.</mat-error
              >
              <mat-error
                *ngIf="
                  automatePaymentForm.controls['value'].hasError('max') &&
                  !customMaxAmountMessage &&
                  !automatePaymentForm.controls['value'].hasError(
                    'required'
                  )
                "
                >El monto máximo es
                <strong>{{ maxNeatCost | clpCurrency }}</strong
                >.</mat-error
              >
              <mat-error *ngIf="customMaxAmountMessage"
                >Si debes pagar sobre
                <strong>{{ maxNeatCost | clpCurrency }}</strong
                >, escríbenos al chat.</mat-error
              >
            </mat-form-field>
            <mat-form-field
              *ngIf="payingAccountUnit === 'UF'"
              id="prependboxUF"
              class="col-6"
              floatLabel="always"
              appearance="outline"
            >
              <input
                matInput
                [value]="
                  automatePaymentForm.get('valueUF').value | number: '1.0-2'
                "
                type="number"
                id="valueUF"
                class="amountInputText"
                formControlName="valueUF"
                name="valueUF"
                onkeydown="onlyPositives(event)"
                [errorStateMatcher]="matcher"
                required
              />
              <mat-error
                *ngIf="
                  automatePaymentForm.controls['valueUF'].hasError(
                    'required'
                  )
                "
                >El monto en UF es <strong>requerido</strong>.</mat-error
              >
              <mat-error
                *ngIf="
                  automatePaymentForm.controls['valueUF'].hasError('min') &&
                  !automatePaymentForm.controls['valueUF'].hasError(
                    'required'
                  )
                "
                >El monto mínimo es
                <strong>{{ minNeatCost | clpCurrency }}</strong
                >.</mat-error
              >
              <mat-error
                *ngIf="
                  automatePaymentForm.controls['valueUF'].hasError('max') &&
                  !customMaxAmountMessage &&
                  !automatePaymentForm.controls['valueUF'].hasError(
                    'required'
                  )
                "
                >El monto máximo es
                <strong>{{ maxNeatCost | clpCurrency }}</strong
                >.</mat-error
              >
              <mat-error *ngIf="customMaxAmountMessage"
                >Si debes pagar sobre
                <strong>{{ maxNeatCost | clpCurrency }}</strong
                >, escríbenos al chat.</mat-error
              >
            </mat-form-field>
            <mat-form-field
              class="col-6"
              floatLabel="always"
              appearance="outline"
            >
              <mat-select
                class="pb-05"
                id="payingAccountUnit"
                formControlName="payingAccountUnit"
                [errorStateMatcher]="matcher"
                [disabled]="
                  selectedEntity?.entityType === entityType.commonExpenses
                "
                required
              >
                <mat-option value="CLP">CLP</mat-option>
                <mat-option value="UF">UF</mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  automatePaymentForm.controls[
                    'payingAccountUnit'
                  ].hasError('required')
                "
                >La unidad de cuenta es
                <strong>requerida</strong>.</mat-error
              >
            </mat-form-field>
          </div>
        </div>
        <div
          *ngIf="
            selectedEntity?.payValidation &&
            [2, 3, 5].includes(selectedEntity?.payValidation)
          "
          class="pt-4"
        >
          <app-text class="subtitle-semibold" color="neutral-100"
            >Si tu cuenta tiene más de una deuda pendiente, ¿Qué prefieres
            pagar?</app-text
          >
          <div class="d-flex justify-content-between">
            <div
              class="mb-2 mr-3 mt-2"
              (click)="selectSencillitoPreference('total')"
              [ngClass]="
                automatePaymentForm.get('sencillitoPreference').value ===
                'total'
                  ? 'selected-chip-box'
                  : 'chip-box'
              "
            >
              <span class="flex-column-center">
                La deuda total
              </span>
            </div>
            <div
              class="mb-2 mr-3 mt-2"
              (click)="selectSencillitoPreference('first')"
              [ngClass]="
                automatePaymentForm.get('sencillitoPreference').value ===
                'first'
                  ? 'selected-chip-box'
                  : 'chip-box'
              "
            >
              <span class="flex-column-center">
                Solo la primera deuda
              </span>
            </div>
          </div>
        </div>
        <div
          class="d-flex"
          *ngIf="
            automatePaymentForm.get('sencillitoPreference').value ===
              'first' &&
            selectedEntity?.payValidation &&
            [2, 3, 5].includes(selectedEntity?.payValidation)
          "
          style="flex-direction: column;"
        >
          <div class="mb-3">
            <div class="center-box">
              <span class="warning-text"
                >⚠️ Al seleccionar esta opción puedes quedar con una deuda
                pendiente.</span
              >
            </div>
          </div>
        </div>
        <div class="d-flex pt-3 justify-content-between">
          <app-text class="subtitle-semibold" color="neutral-100"
            >Selecciona método de pago</app-text
          >
          <span
            class="description"
            id="add-payment-method-from-entity-configurator-section"
            (click)="addCreditCards()"
            *ngIf="
              (currentUser$ | async)?.paymentMethods &&
              (currentUser$ | async)?.paymentMethods?.length > 0 &&
              !isMobile
            "
            style="font-weight: 500; cursor: pointer;
color: var(--Text-Link, #3369F0);"
          >
            Agregar tarjeta
          </span>
        </div>

        <span
        class="description"
        id="add-payment-method-from-entity-configurator-section"
        (click)="addCreditCards()"
        *ngIf="
          (currentUser$ | async)?.paymentMethods &&
          (currentUser$ | async)?.paymentMethods?.length > 0 &&
          isMobile
        "
        style="font-weight: 500; cursor: pointer;
color: var(--Text-Link, #3369F0);"
      >
        Agregar tarjeta
      </span>
        <div class="mt-2 relative-overflow">
          <ng-container
            *ngIf="
              (currentUser$ | async)?.paymentMethods &&
                (currentUser$ | async).paymentMethods?.length > 0;
              else addCreditCard
            "
          >
            <mat-form-field
              floatLabel="always"
              appearance="outline"
              class="w-100"
            >
              <mat-label>Método de pago</mat-label>
              <mat-select
                id="paymentMethod"
                formControlName="paymentMethod"
                name="paymentMethod"
                [errorStateMatcher]="matcher"
                placeholder="Seleccione una tarjeta"
              >
                <!-- Trigger personalizado para mostrar la imagen y datos de la tarjeta seleccionada -->
                <mat-select-trigger>
                  <ng-container
                    *ngIf="(currentUser$ | async)?.paymentMethods"
                  >
                    <ng-container
                      *ngFor="
                        let card of (currentUser$ | async)?.paymentMethods
                      "
                    >
                      <div
                        *ngIf="
                          card.id ===
                          automatePaymentForm.get('paymentMethod').value
                        "
                        class="mt-2 d-flex align-items-center"
                      >
                        <img
                          *ngIf="formatBankName(card?.bankName)"
                          src="assets/img/payment-methods-logos-mobile/{{
                            formatBankName(card?.bankName, card.cardType)
                          }}.svg"
                          class="img-fluid img-entity mr-3"
                          style="width: 36px; max-height: 32px; border-radius: 10px;"
                          alt="{{ card.cardType }}"
                        />
                        <img
                          *ngIf="!formatBankName(card?.bankName)"
                          src="assets/payments-methods-section-icons/{{
                            card.cardType
                          }}.svg"
                          class="img-fluid img-entity mr-3"
                          style="width: 36px; height: 24px; border-radius: 10px;"
                          alt="{{ card.cardType }}"
                        />
                        <span class="mr-3 description">{{
                          card.cardType
                            ? card?.brand
                              ? card.brand.charAt(0).toUpperCase() +
                                card.brand.slice(1).toLowerCase()
                              : cardTypes[card.cardType]
                            : cardTypes[card.cardType]
                        }}</span>
                        <span
                          style="white-space: nowrap;"
                          class="ml-2 description"
                          >{{
                            formatCreditCardNumbers(card.cardNumber)
                          }}</span
                        >
                      </div>
                    </ng-container>
                  </ng-container>
                </mat-select-trigger>

                <!-- Opciones en el dropdown -->
                <mat-option
                  *ngFor="
                    let card of (currentUser$ | async)?.paymentMethods
                  "
                  [value]="card.id"
                >
                  <div
                    id="add-payment-method-modal-checkout"
                    class="d-flex align-items-center cursor-pointer"
                    [ngClass]="card ? 'py-3' : 'py-2'"
                  >
                    <div
                      [ngClass]="
                        card &&
                        card?.cardType !== 'Prepago' &&
                        card?.cardType !== 'Redcompra'
                          ? 'col-2 col-md-2 pr-0'
                          : 'col-2'
                      "
                    >
                      <img
                        *ngIf="formatBankName(card?.bankName)"
                        src="assets/img/payment-methods-logos-mobile/{{
                          formatBankName(card?.bankName, card.cardType)
                        }}.svg"
                        class="img-fluid img-entity"
                        style="width: 36px; max-height: 32px; border-radius: 10px;"
                        alt="{{ card.cardType }}"
                      />
                      <img
                        *ngIf="!formatBankName(card?.bankName)"
                        src="assets/payments-methods-section-icons/{{
                          card.cardType
                        }}.svg"
                        class="img-fluid img-entity"
                        style="width: 36px; height: 24px; border-radius: 10px;"
                        alt="{{ card.cardType }}"
                      />
                    </div>
                    <div
                      [ngClass]="
                        card &&
                        card?.cardType !== 'Prepago' &&
                        card?.cardType !== 'Redcompra'
                          ? 'col-10 col-md-10 justify-content-between'
                          : 'col-10'
                      "
                    >
                      <span class="mr-2">{{
                        card.cardType
                          ? card?.brand
                            ? card.brand.charAt(0).toUpperCase() +
                              card.brand.slice(1).toLowerCase()
                            : cardTypes[card.cardType]
                          : cardTypes[card.cardType]
                      }}</span>
                      <span style="white-space: nowrap;" class="mr-2">{{
                        formatCreditCardNumbers(card.cardNumber)
                      }}</span>
                    </div>
                  </div>
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  automatePaymentForm.controls['paymentMethod'].hasError(
                    'required'
                  )
                "
                >La tarjeta es <strong>requerida</strong>.</mat-error
              >
            </mat-form-field>
          </ng-container>
          <ng-template #addCreditCard>
            <div
              class="row p-0 p-md-3 w-100 mb-4 payment-method-box"
              (click)="addCreditCards()"
            >
              <div
                id="add-payment-method-modal-checkout"
                class="d-flex align-items-center summary-white-box cursor-pointer py-2 w-100"
              >
                <div
                  class="d-flex align-items-center card-selected col-12 py-2 justify-content-between"
                >
                  <div class="d-flex align-items-center">
                    <mat-icon class="mr-4" style="color: #3368f0;"
                      >add_card</mat-icon
                    >
                    <span>Agregar método de pago</span>
                  </div>
                  <div
                    *ngIf="
                      currentUser?.paymentMethods?.length > 0;
                      else arrows
                    "
                    class="ml-2"
                  >
                    <mat-icon>arrow_drop_down</mat-icon>
                  </div>
                  <ng-template #arrows>
                    <span
                      class="back-arrow navy-color d-flex align-items-center justify-content-end mr-2"
                    >
                      <mat-icon>keyboard_arrow_right</mat-icon>
                    </span>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div
        *ngIf="
            !selectedEntity?.customData && !selectedEntity?.utilityName
          "
          class="mt-2"
          >
          <div class="col-12 d-flex align-items-center" style="margin-bottom: 8px; gap: 8px;">
            <mat-checkbox
              class="d-flex"
              color="primary"
              formControlName="receiverEmailNotification"
              id="receiverEmailNotificationMobile"
            ></mat-checkbox
            ><app-text class="label-medium" color="neutral-100"
              >Avisar al destinatario cuando el pago esté en proceso</app-text
            >
          </div>
          <div class="pt-3">
          <app-text class="subtitle-semibold" color="neutral-100"
            >Comentario</app-text
          >
            <mat-form-field
              class="w-100 transfer-comment"
              outline="border"
              floatLabel="always"
              appearance="outline"
            >
              <textarea
                matInput
                id="lastTransferComment"
                rows="2"
                style="resize:none"
                formControlName="lastTransferComment"
                name="lastTransferComment"
                [errorStateMatcher]="matcher"
                placeholder="Neat-tip: No es necesario que menciones el mes del pago, nosotros nos encargamos de hacerlo."
              ></textarea>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="!addingEntity" class="d-block disclaimer-box px-3 py-2 mt-2">
          <app-text
            *ngIf="
              !selectedEntity?.customData && !selectedEntity?.utilityName
            "
            class="label-medium" color="neutral-80"
          >
            💡 Los pagos automáticos deben ser mensuales y siempre el mismo
            monto.
          </app-text>
          <app-text color="neutral-80"
            *ngIf="
              selectedEntity?.customData || selectedEntity?.utilityName
            "
            class="body-medium"
          >
            💡 El valor del pago automático será entregado por
            <b>{{
              selectedEntity?.customData
                ? selectedEntity?.customData?.providerName
                : selectedEntity?.utilityName
            }}</b>
          </app-text>
        </div>
        <div class="d-flex mt-4 justify-content-center flex-column">
          <button
            id="automate-payment"
            (click)="submitAutomatePayment(currentUser?.pinActive, true)"
            class="neat-btn neat-btn__primary w-100 w-md-auto"
            style="min-width: 250px;"
          >
            <span>Guardar</span>
          </button>
          <button
            *ngIf="automatePayment"
            (click)="
              askForDisableAutomaticPayment(currentUser?.pinActive, false)
            "
            class="neat-btn neat-btn__secondary w-100 mt-2 w-md-auto"
            style="background-color: white;"
          >
            Desactivar pago
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div
  *ngIf="showLoading || showInitLoading"
  class="h-100 d-flex align-items-center justify-content-center"
  style="min-height: 400px;"
>
  <div
    class="main-card align-items-center justify-content-center text-center"
    style="flex-direction: column;"
  >
    <div class="svg-container mobile-center">
      <svg
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="24"
        viewBox="0 0 10 24"
        fill="none"
      >
        <path id="svg1" opacity="0.3" d="M10 0H0V24H10V0Z" fill="#9DBEF7" />
      </svg>
      <svg
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="24"
        viewBox="0 0 10 24"
        fill="none"
      >
        <path id="svg2" opacity="0.3" d="M10 0H0V24H10V0Z" fill="#9DBEF7" />
      </svg>
      <svg
        class="mr-2"
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="24"
        viewBox="0 0 10 24"
        fill="none"
      >
        <path id="svg3" opacity="0.3" d="M10 0H0V24H10V0Z" fill="#9DBEF7" />
      </svg>
    </div>
    <span *ngIf="showLoading" class="bold-text d-block mt-3">{{
      !addingCard ? 'Programando pago...' : 'Redirigiendo ...'
    }}</span>
    <span *ngIf="showInitLoading" class="bold-text d-block mt-3">{{
      'Cargando...'
    }}</span>
  </div>
</div>
