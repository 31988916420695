import { TitleCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customTitlecase'
})
export class CustomTitleCase extends TitleCasePipe implements PipeTransform {
  transform(value: any): any {
    const regexp = /Retry/g;
    const findMatch = value && regexp?.exec(value);
    if (value === null) {
      return '';
    } else if (findMatch && findMatch?.index) {
      return value;
    } else {
      return value
        ?.toLowerCase()
        ?.split(' ')
        ?.filter(res => res !== '')
        ?.map(w => w[0]?.toUpperCase() + w?.substring(1)?.toLowerCase())
        ?.join(' ')
        ?.replace('Autotag', 'AutoTag');
    }
  }
}
