import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Inputmask from 'inputmask';
import { PromotionNames } from 'neat-lib/dist/Enums/Constants';
import { of, Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';
import {
  map,
  catchError,
  filter,
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';

import { RegisterCreditCardService } from '@services/credit-card-registration/register-credit-card.service';
import { SweetalertService } from '@services/shared/sweetalert/sweetalert.service';
import { today } from '@shared/utils';
import { PromotionCFResponse } from 'app/interfaces/promotions-cf-response.interface';
import { IPromotions } from 'app/interfaces/promotions.interface';

import { Promotion } from '../../models/promotions.model';
import { User } from '../../models/users/user.model';
import { ErrorHandlerService } from '../../services/shared/error-handler/error-handler.service';
import {
  IconErrorModals,
  PromotionEntity,
  PromotionPeriodDates
} from '../../shared/enums/enums.enum';


@Injectable({
  providedIn: 'root'
})
export class PromotionsService {
  private allPromotions: Subject<Promotion[]> = new BehaviorSubject<
    Promotion[]
  >(null);
  private promotionsAvailable: Subject<IPromotions> = new BehaviorSubject<
    IPromotions
  >(null);
  showOnlyUnique = false;
  showOnlyCmr = false;
  loadingVerify = false;
  loadingVerifySuperDigital = false;
  loadingVerifyItau = false;
  verifyCreditCardNumberMessage: string;
  verifyCreditCardNumberMessageSuperDigital: string;
  verifyCreditCardNumberMessageItau: string;
  responseCF: PromotionCFResponse;
  showLoader: boolean;
  currentUserIdToken: string;
  promotionEntity = PromotionEntity;
  validPromotionPeriod: boolean;
  validPromotionPeriodItau: boolean;
  validPromotionPeriodSuperDigital: boolean;
  masterCardPromoAvailable = true;
  referralsPromoAvailable = true;
  private errorSubscription: Subscription;
  private selectedPromotion: Subject<string> = new BehaviorSubject<string>(
    null
  );

  constructor(
    private afs: AngularFirestore,
    private errorService: ErrorHandlerService,
    private fb: FormBuilder,
    private registerCreditCard: RegisterCreditCardService,
    private swalService: SweetalertService
  ) {}

  addPromotionsAvailable(promotionsAvailable: IPromotions): void {
    this.promotionsAvailable.next(promotionsAvailable);
  }

  get getPromotionsAvailable$(): Observable<IPromotions> {
    return this.promotionsAvailable
      .asObservable()
      .pipe(filter(promotions => !!promotions));
  }

  get allPromotions$(): Observable<Promotion[]> {
    return this.allPromotions
      .asObservable()
      .pipe(filter(promotions => !!promotions));
  }

  get getSelectedPromotion$(): Observable<string> {
    return this.selectedPromotion.asObservable().pipe(filter(val => !!val));
  }

  addSelectedPromotion(data: string): void {
    this.selectedPromotion.next(data);
  }

  addAllPromotions(data: Promotion[]): void {
    this.allPromotions.next(data);
  }

  getAllPromotions$(currentUser: User): Observable<Promotion[]> {
    const promotionsCollection = this.afs.collection<Promotion>(
      'promotions',
      ref => ref.where('userId', '==', currentUser.id)
    );
    return promotionsCollection.valueChanges().pipe(
      map(promotionsArray =>
        promotionsArray.map(promotionsData =>
          new Promotion().deserialize(promotionsData)
        )
      ),
      catchError(error => {
        this.errorService.recordError(
          error,
          'promotion.service.ts',
          'promotionsDoc.valueChanges()',
          'Error al obtener promociones'
        );
        return of([]);
      })
    );
  }

  getPromotions$(
    promotionEntity: PromotionEntity | any
  ): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(promotions => promotions.entity === promotionEntity)
      )
    );
  }

  getActivePromotions$(
    promotionEntity: PromotionEntity | any
  ): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions =>
            promotions.entity === promotionEntity && promotions.active
        )
      )
    );
  }

  getPromotionsByName$(promotionName: string): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(promotions => promotions.name === promotionName)
      )
    );
  }

  getActivePromotionsByName$(promotionName: string): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions => promotions.name === promotionName && promotions.active
        )
      )
    );
  }

  getFalabellaPromotionNewUsers$(
    promotionName: string
  ): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions =>
            promotions.entity === PromotionEntity.falabella &&
            promotions.name === promotionName
        )
      )
    );
  }

  getTransbankPromotion$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions => promotions.name === 'TransbankAbril2023'
        )
      )
    );
  }

  getTransbankPromotionActive$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions =>
            promotions.name === 'TransbankAbril2023' && promotions.active
        )
      )
    );
  }

  getTransbankPromotionUsed$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions =>
            promotions.name === 'TransbankAbril2023' && !promotions.active
        )
      )
    );
  }

  getReferredPromotion$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions => promotions.name === PromotionNames.invitadoNeat2023
        )
      )
    );
  }

  getReferredPromotionActive$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions =>
            promotions.name === PromotionNames.invitadoNeat2023 &&
            promotions.active
        )
      )
    );
  }
  getReferredPromotionUsed$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions =>
            promotions.name === PromotionNames.invitadoNeat2023 &&
            !promotions.active
        )
      )
    );
  }

  getRappiPromotion$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions => promotions.name === PromotionNames.rappi
        )
      )
    );
  }

  getMastercardPromotion$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions => promotions.name === PromotionNames.mastercard2023
        )
      )
    );
  }

  getRappiPromotionActive$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions =>
            promotions.name === PromotionNames.rappi && promotions.active
        )
      )
    );
  }
  getRappiPromotionUsed$(): Observable<Promotion[]> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.filter(
          promotions =>
            promotions.name === PromotionNames.rappi && !promotions.active
        )
      )
    );
  }

  getPromotionsInfo$(promotionId: string): Observable<Promotion> {
    return this.allPromotions$.pipe(
      map(promotions =>
        promotions.find(promotions => promotions.id === promotionId)
      )
    );
  }

  buildPromotionCreditCardForm(bank: string): FormGroup {
    return this.fb.group({
      creditCardNumber: ['', [Validators.required, Validators.minLength(6)]],
      lastCreditCardNumbers: [
        '',
        [Validators.required, Validators.minLength(4)]
      ],
      fullCreditCardNumber: [
        '',
        [Validators.required, Validators.minLength(16)]
      ],
      bank: bank
    });
  }

  fullCreditCardNumberValueChanges(creditCardForm: FormGroup): Observable<any> {
    return creditCardForm.get('fullCreditCardNumber').valueChanges.pipe(
      map(ccNumber => ccNumber.replace(/ /g, '').replace(/_/g, '')),
      debounceTime(1000),
      distinctUntilChanged(),
      filter(ccNumber => ccNumber.length === 16)
    );
  }

  creditCardNumberValueChanges(creditCardForm: FormGroup): Observable<any> {
    return creditCardForm.get('creditCardNumber').valueChanges.pipe(
      map(ccNumber => ccNumber.replace(/ /g, '').replace(/_/g, '')),
      debounceTime(1000),
      distinctUntilChanged(),
      filter(ccNumber => ccNumber.length === 6)
    );
  }

  creditCardNumberValueChangesMobile(
    creditCardForm: FormGroup
  ): Observable<any> {
    return creditCardForm.get('creditCardNumber').valueChanges.pipe(
      map(ccNumber => ccNumber.replace(/ /g, '').replace(/_/g, '')),
      distinctUntilChanged(),
      filter(ccNumber => ccNumber.length === 6)
    );
  }

  lastCreditCardNumbersValueChanges(
    creditCardForm: FormGroup
  ): Observable<any> {
    return creditCardForm.get('lastCreditCardNumbers').valueChanges.pipe(
      map(ccNumber => ccNumber.replace(/ /g, '').replace(/_/g, '')),
      debounceTime(1000),
      distinctUntilChanged(),
      filter(ccNumber => ccNumber.length === 4)
    );
  }

  lastCreditCardNumbersValueChangesMobile(
    creditCardForm: FormGroup
  ): Observable<any> {
    return creditCardForm.get('lastCreditCardNumbers').valueChanges.pipe(
      map(ccNumber => ccNumber.replace(/ /g, '').replace(/_/g, '')),
      distinctUntilChanged(),
      filter(ccNumber => ccNumber.length === 4)
    );
  }

  fullCreditCardInputMask(): void {
    const creditCard = document.getElementById('fullCreditCardNumber');
    const inputMask = new Inputmask('9999 9999 9999 9999');
    inputMask.mask(creditCard);
  }

  creditCardInputMask(elementId: string): void {
    const creditCard = document.getElementById(elementId);
    const inputMask = new Inputmask('9999 99');
    inputMask.mask(creditCard);
  }

  lastCreditCardNumbersMask(elementId: string): void {
    const lastCreditCardNumbers = document.getElementById(elementId);
    const inputMask = new Inputmask('9999');
    inputMask.mask(lastCreditCardNumbers);
  }

  async creditCardRegistration(redirectSection: string) {
    this.showLoader = true;
    const res = await this.registerCreditCard.getCreditCardRegistrationToken(
      this.currentUserIdToken,
      redirectSection
    );
    if (res === false) {
      this.showLoader = false;
    }
    this.errorSubscription = this.registerCreditCard.detectErrorOnRequest$.subscribe(
      error => {
        if (error) {
          this.showLoader = false;
          this.swalService.swalError2(
            'No se ha podido agregar tu tarjeta',
            `Esto se debe a problemas con tu conexión a internet. Por favor intenta con otra red Wifi. 
          Si estás conectado al internet de tu compañía de celular, inténtalo de nuevo más tarde.`,
            IconErrorModals.sadCard,
            true,
            false
          );
          this.errorSubscription.unsubscribe();
        }
      }
    );
  }

  getPromotionValidPeriod(
    promotionEntity: string
  ): { startDate: Date; finishDate: Date } {
    let startDate: Date;
    let finishDate: Date;
    if (promotionEntity === PromotionEntity.falabella) {
      startDate =
        today >= new Date(PromotionPeriodDates.falabella_new_start)
          ? new Date(PromotionPeriodDates.falabella_new_start)
          : new Date(PromotionPeriodDates.falabella_start);
      finishDate =
        today >= new Date(PromotionPeriodDates.falabella_new_start)
          ? new Date(PromotionPeriodDates.falabella_new_finish)
          : new Date(PromotionPeriodDates.falabella_finish);
    } else if (promotionEntity === PromotionEntity.mastercard) {
      startDate = new Date(PromotionPeriodDates.mastercard_start);
      finishDate = new Date(PromotionPeriodDates.mastercard_finish);
    }
    return { startDate, finishDate };
  }

  validatePromotionDate(startDate: number, finishDate: number): boolean {
    const today: Date = new Date();
    if (
      today >= new Date(startDate * 1000) &&
      today <= new Date(finishDate * 1000)
    ) {
      return true;
    }
    return false;
  }
}
