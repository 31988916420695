import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceAll'
})
export class ReplaceAllPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      return value.replace(/-/g, '/');
    }
    return '';
  }
}
