<!-- <p>
  EmailHandlerComponent
</p> -->

<p *ngIf="!isModeResetPassword">
  {{ viewMessage }}
</p>

<section
  id="email-handler-password-reset"
  class="row animated fadeIn"
  *ngIf="isModeResetPassword"
>
  <div class="col-12 col-md-8 col-lg-6 mx-auto">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="neat-blue">Recuperar contraseña</mat-card-title>
        <!-- <mat-card-subtitle></mat-card-subtitle> -->
      </mat-card-header>
      <mat-card-content>
        <form
          id="reset-password-form"
          class="reset-password-form"
          [formGroup]="resetPasswordForm"
          (ngSubmit)="onResetPasswordSubmit()"
        >
          <mat-form-field class="w-100 mb-3">
            <mat-label> <mat-icon>email</mat-icon> Tu email </mat-label>
            <input
              matInput
              formControlName="email"
              type="email"
              name="email"
              id="reset-password-input-email"
              [errorStateMatcher]="matcher"
              [value]="resetPasswordForm.get('email').value | lowercase"
            />
            <mat-error
              *ngIf="
                resetPasswordForm.controls['email'].hasError('email') &&
                !resetPasswordForm.controls['email'].hasError('required')
              "
              >Ingresa un email valido,
              <i>ejemplo: tuemail@email.cl</i></mat-error
            >
            <mat-error
              *ngIf="resetPasswordForm.controls['email'].hasError('required')"
              >Tu email es <strong>requerido</strong></mat-error
            >
          </mat-form-field>
          <mat-form-field class="w-100 mb-3">
            <mat-label> <mat-icon>lock</mat-icon> Contraseña </mat-label>

            <input
              matInput
              [type]="hide ? 'password' : 'text'"
              id="reset-password-input-password"
              placeholder="Nueva contraseña"
              formControlName="newPassword"
              [errorStateMatcher]="matcher"
              required
            />
            <button
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
              color="primary"
            >
              <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
            <mat-error
              *ngIf="
                resetPasswordForm.controls['newPassword'].hasError('required')
              "
              >La contraseña es <strong>requerida</strong></mat-error
            >
            <mat-error
              *ngIf="
                resetPasswordForm.controls['newPassword'].hasError('minlength')
              "
              >La contraseña debe tener un minimo de
              <strong>6</strong> caracteres</mat-error
            >
            <mat-error
              *ngIf="
                resetPasswordForm.controls['newPassword'].hasError(
                  'strongPassword'
                )
              "
              >La contraseña debe tener una letra <strong>mayuscula</strong>,
              una <strong>minuscula</strong> y un
              <strong>número</strong></mat-error
            >
          </mat-form-field>
          <div class="d-flex justify-content-end">
            <button
              mat-button
              id="reset-password-submit-btn"
              type="submit"
              class="transition-ease-03"
              [class.transition-3d-hover]="!resetPasswordForm.invalid"
              [disabled]="!resetPasswordForm.valid"
            >
              Recuperar
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</section>
