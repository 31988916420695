<div class="payment-error-card py-3 p-md-4">
  <span class="payment-error-title mb-3 mt-2">
    No pudimos realizar el pago
  </span>
  <div class="mb-3">
    <div class="payment-error-message">
      <span class="payment-error-icon mr-2 mr-md-0">📲</span>
      <span class="payment-error-text">
        Normalmente, CMR envía un mensaje de WhatsApp, correo, llamada o SMS
        para autorizar la transacción. Verifícalo y reintenta el pago.
      </span>
    </div>
  </div>
  <div class="mb-3 d-flex justify-content-center">
    <div class="text-left">
      <span class="check-message">
        ¿No te llegó nada?
      </span>
    </div>
  </div>
  <div class="divider"></div>
  <div class="my-3 d-flex align-items-center">
    <span class="retry-icon mr-3">🔄</span>
    <span class="retry-message">
      Siempre puedes reintentarlo, pero evita hacerlo más de dos veces para que
      no se bloquee tu tarjeta.
    </span>
  </div>
  <div class="divider"></div>
  <div class="my-3">
    <span class="contact-bank">
      Si vuelve a fallar contacta a tu banco para entender el motivo. <br />
      Si el problema persiste, escríbenos al chat.
    </span>
  </div>
  <div class="d-flex justify-content-center">
    <button class="neat-btn neat-btn__primary" (click)="closeModal()">
      Entiendo
    </button>
  </div>
</div>
