import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, filter, take } from 'rxjs/operators';

import { CookiesService } from '@services/cookies/cookies.service';
import { RedirectService } from '@services/redirect/redirect.service';
import { DialogService } from '@services/shared/dialog/dialog.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { SoyIOService } from '@services/soy-io/soy-io.service';
import { UserService } from '@services/user/user.service';
import { AppGlobals } from '@shared/constants';
import { UserInfo } from 'app/models/users/user-info.model';

import packageJson from './../../../../package.json';

const appVersion = packageJson.version;
let neatProduct = `Platform: ${'Web'} Version: ${appVersion} Device: ${
  window.navigator.userAgent
}`;
@Injectable({
  providedIn: 'root'
})

//TODO: change service name to CreditCardService or something like this
export class RegisterCreditCardService {
  private requestError: Subject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private errorService: ErrorHandlerService,
    private redirect: RedirectService,
    private deviceService: DeviceDetectorService,
    private firePerformance: AngularFirePerformance,
    private userService: UserService,
    private soyIOService: SoyIOService,
    private dialogService: DialogService,
    private cookiesService: CookiesService
  ) {
    const isMobile = this.deviceService.isMobile();
    const isDesktop = this.deviceService.isDesktop();
    const isTablet = this.deviceService.isTablet();
    let device: string;
    if (isMobile) {
      device = 'Mobile';
    } else if (isDesktop) {
      device = 'Desktop';
    } else if (isTablet) {
      device = 'Tablet';
    }
    if (device) {
      neatProduct = `Platform: ${'Web'}-${device} Version: ${appVersion} Device: ${
        window.navigator.userAgent
      }`;
    }
  }

  get detectErrorOnRequest$(): Observable<boolean> {
    return this.requestError.asObservable().pipe(filter(val => !!val));
  }

  cardRegistrationConfirmationRequest(
    paymentMethodId: string,
    token: string,
    userIdToken: string
  ): Observable<any> {
    const jsonBody = { tokenId: userIdToken };
    const endpoint: string = this.getCardRegistrationConfirmEndpoint(
      paymentMethodId,
      token
    );
    localStorage.removeItem(`newPaymentMethod`);
    return this.http
      .put<any>(endpoint, jsonBody, {
        headers: {
          'x-neat-product': neatProduct,
          'x-neat-token': `${userIdToken}`,
          Authorization: `Bearer ${userIdToken}`
        },
        observe: 'response'
      })
      .pipe(
        catchError(this.handleError('cardRegistrationConfirmationRequest()'))
      );
  }

  getCardRegistrationConfirmEndpoint(
    paymentMethodId: string,
    token: string
  ): string {
    if (isDevMode()) {
      return AppGlobals.neatBetaAPIEndpointCreditCardConfirmation(
        paymentMethodId,
        token
      );
    } else {
      return AppGlobals.neatAPIEndpointCreditCardConfirmation(
        paymentMethodId,
        token
      );
    }
  }

  async getCreditCardRegistrationToken(
    tokenId: string,
    pathUrl: string
  ): Promise<any> {
    return new Promise(resolve => {
      this.userService.userInfo$
        .pipe(take(1))
        .subscribe(async (userInfo: UserInfo) => {
          const showPendingModal = this.cookiesService.getCookie(
            `verifyPending${userInfo?.id}`
          );
          if (
            userInfo?.fraudSuspect &&
            (!userInfo?.verificationProvider ||
              userInfo?.verificationProvider?.isStronglyVerified === false) &&
            !showPendingModal
          ) {
            this.dialogService.openSoyIoModalVerification();
            resolve(false);
            return false;
          } else if (userInfo?.fraudSuspect && userInfo?.verificationProvider) {
            this.soyIOService.showStatusModal(userInfo);
            resolve(false);
            return false;
          }
          resolve(true);
          const traceCreditCardRegistrationRequest = await this.firePerformance.trace(
            'creditCardRegistrationRequest()'
          );
          traceCreditCardRegistrationRequest.start();
          const urlResponse: string = this.getResponseUrl() + pathUrl;
          const jsonBody = { tokenId, response_url: urlResponse };
          this.creditCardRegistration(jsonBody, tokenId).subscribe({
            next: async response => {
              try {
                localStorage.setItem(`newPaymentMethod`, 'true');
                const param = { TBK_TOKEN: response.token };
                this.redirect.post(param, response.url_webpay);
              } catch (err) {
                this.errorService.recordError(
                  err,
                  'register-credit-card-service.ts',
                  'getCreditCardRegistrationToken()',
                  `Error al obtener token y/o url_webpay, la response de /transbank/paymentMethod 
                es ${JSON.stringify(response)}, jsonBody ${JSON.stringify(
  jsonBody
)} y el token Id ingresado como param es ${tokenId}`
                );
              }
            },
            error: error => {
              if (error) {
                this.requestError.next(true);
              }
              this.errorService.recordError(
                error,
                'register-credit-card-service.ts',
                'creditCardRegistration()',
                'Error al obtener token y/o url_webpay'
              );
              this.requestError.next(false);
            },
            complete: () => {
              traceCreditCardRegistrationRequest.stop();
            }
          });
        });
    });
  }

  getResponseUrl(): string {
    if (isDevMode()) {
      return AppGlobals.betaResponseUrl;
    } else {
      return AppGlobals.responseUrl;
    }
  }

  creditCardRegistration(jsonBody: any, tokenId: string): Observable<any> {
    const endpoint: string = this.getCreditCardRegistrationEndpoint();
    const httpHeader = {
      headers: new HttpHeaders({
        'x-neat-token': `${tokenId}`,
        'x-neat-product': neatProduct,
        Authorization: `Bearer ${tokenId}`
      })
    };
    return this.http.post<any>(endpoint, jsonBody, httpHeader);
  }

  creditCardDeletation(
    paymentMethodId: string,
    userIdToken: string
  ): Observable<any> {
    const httpHeaders = {
      headers: new HttpHeaders({
        'x-neat-token': `${userIdToken}`,
        'x-neat-product': neatProduct,
        Authorization: `Bearer ${userIdToken}`
      })
    };
    const endpoint: string = this.getCreditCardDeletationEndpoint(
      paymentMethodId
    );
    return this.http
      .delete<any>(endpoint, httpHeaders)
      .pipe(catchError(this.handleError('creditCardDeletation()')));
  }

  getCreditCardDeletationEndpoint(paymentMethodId: string): string {
    if (isDevMode()) {
      return AppGlobals.neatBetaAPIEndpointDeleteCreditCard(paymentMethodId);
    } else {
      return AppGlobals.neatAPIEndpointDeleteCreditCard(paymentMethodId);
    }
  }

  getCreditCardRegistrationEndpoint(): string {
    if (isDevMode()) {
      return AppGlobals.neatBetaAPIEndpointCreditCardRegistration;
    } else {
      return AppGlobals.neatAPIEndpointCreditCardRegistration;
    }
  }

  private handleError<T>(functionName: string, result?: T) {
    return (error: any): Observable<T> => {
      this.errorService.recordError(
        error,
        'register-credit-card.service.ts',
        'handleError()',
        `Error al procesar comunicación https on operation: ${functionName}`
      );
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  creditCardDeletationKushkiRequest(
    paymentMethodId: string,
    userIdToken: string,
    userId: string
  ): Observable<any> {
    const httpHeaders = {
      headers: new HttpHeaders({
        'x-neat-token': `${userIdToken}`,
        'x-neat-product': neatProduct,
        Authorization: `Bearer ${userIdToken}`,
        uid: `${userId}`
      })
    };
    const endpoint: string = AppGlobals.deleteKushkiCreditCardAPIEndpoint(
      paymentMethodId
    );
    if (isDevMode()) {
      console.info('borrando card al endpoint', endpoint);
      console.info('borrando card httpHeaders', httpHeaders);
    }
    return this.http
      .delete<any>(endpoint, httpHeaders)
      .pipe(catchError(this.handleError('creditCardDeletation()')));
  }
}
