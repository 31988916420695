import { IVerificationProvider } from 'neat-lib/dist/Interfaces/IData';

import { User } from './user.model';
import { Deserializable } from '../../interfaces/deserializable.interface';

//En algún momento comenzar a migrar al userInfo de neat-lib
export class UserInfo extends User implements Deserializable {
  public hasPayed: boolean;
  public blockedRuts: Array<string>;
  public blockedRutsAllowedList: Array<string>;
  public fraud: boolean;
  public suspectHistory: Array<SuspectHistory>;
  public deletedEmail?: string;
  public fraudSuspect: boolean;
  public teamNeat?: boolean;
  public kushkiMode?: boolean;
  public totalCMRAmount?: number;
  public fraudSuspectReasons: any;
  public verificationProvider?: IVerificationProvider;
  public manualVerification?: IManualVerification;
  public segment?: 'BestUser' | 'SuperStar' | 'WorstUser' | 'NormalUser';
}

class SuspectHistory {
  fraudType: string;
  timestamp: Date;
}

interface IManualVerification {
  verified: boolean;
  verificationDate: any | null;
  conversationLink: string | null;
}
