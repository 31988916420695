<div>
  <div>
    <span class="d-block custom-text-14">
      Existe más de una deuda asociada a tu número de cliente:
    </span>
    <span class="d-block extra-bold-neat-text">
      {{ debtSummary.clientNumber }}
    </span>
  </div>
  <div class="mt-4">
    <span class="d-block custom-text-14 mb-3">Las deudas existentes son: </span>
    <span *ngIf="oneOrMoreInOrderDebt" class="mb-3 d-block main-secundary-text"
      >*Puedes pagar la cantidad de deudas que quieras, pero siempre en
      orden.</span
    >
    <form
      *ngIf="payValidationForm && oneOrMoreInOrderDebt"
      [formGroup]="payValidationForm"
      [ngClass]="
        debtSummary.currentDebt.invoices.length > 8 ? 'custom-box' : ''
      "
    >
      <div
        *ngFor="let invoice of debtSummary.currentDebt.invoices; let i = index"
      >
        <div class="d-flex mb-2">
          <mat-checkbox
            color="primary"
            class="mr-2"
            formControlName="{{ invoice.order }}"
            id="order{{ invoice.order }}"
          ></mat-checkbox>
          <span class="d-block custom-text-14">{{
            invoice.amount | clpCurrency
          }}</span>
        </div>
      </div>
      <mat-error *ngIf="!isSelectionValid && totalAmount > 0"
        >Debes seleccionar las deudas <strong>en orden</strong>.</mat-error
      >
      <span
        *ngIf="totalAmount === 0"
        class="d-block my-3 custom-text-14 blue-pointer-text"
        (click)="selectAll()"
        >Seleccionar todas</span
      >
      <span
        *ngIf="totalAmount > 0"
        class="d-block my-3 custom-text-14 blue-pointer-text"
        (click)="removeAll()"
        >Borrar selección</span
      >
      <span *ngIf="showText" class="custom-text-12 dark-blue"
        >Se hará solo un cargo, por lo que se emitirá solo una boleta.
      </span>
    </form>
    <div *ngIf="!oneOrMoreInOrderDebt">
      <div
        *ngFor="let invoice of debtSummary.currentDebt.invoices; let i = index"
      >
        <div class="d-flex mb-2">
          <span
            class="d-flex custom-text-14"
            [ngClass]="[
              paymentTypeValidationForm.get('paymentType').value === 'total' &&
              firstAndTotalDebt
                ? 'extra-bold'
                : '',
              paymentTypeValidationForm.get('paymentType').value === 'first' &&
              firstAndTotalDebt &&
              i === 0
                ? 'extra-bold'
                : ''
            ]"
            >{{ invoice.amount | clpCurrency }}
            <span
              class="ml-2"
              *ngIf="firstDebt && i === 0 && !debtSummary.onlyRead"
              >(disponible para pagar)</span
            ></span
          >
        </div>
      </div>
    </div>
    <div
      *ngIf="paymentTypeValidationForm && firstAndTotalDebt"
      class="mt-3"
      [formGroup]="paymentTypeValidationForm"
    >
      <span class="d-flex custom-text-14"
        ><b>¿Qué monto quieres pagar?</b></span
      >
      <div class="d-flex mobile-wrap">
        <div
          class="mb-2 mr-3 mt-2"
          (click)="selectPaymentType('total')"
          [ngClass]="
            paymentTypeValidationForm.get('paymentType').value === 'total'
              ? 'selected-chip-box'
              : 'chip-box'
          "
        >
          <span class="flex-column-center">
            La deuda total <br />
            <span *ngIf="debtSummary?.currentDebt?.totalAmount"
              >({{ debtSummary?.currentDebt?.totalAmount | clpCurrency }})</span
            >
          </span>
        </div>
        <div
          class="mb-2 mr-3 mt-2"
          (click)="selectPaymentType('first')"
          [ngClass]="
            paymentTypeValidationForm.get('paymentType').value === 'first'
              ? 'selected-chip-box'
              : 'chip-box'
          "
        >
          <span class="flex-column-center">
            Solo la primera deuda <br />
            <span
              >({{
                debtSummary.currentDebt.invoices[0].amount | clpCurrency
              }})</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <div>
    <span
      *ngIf="firstDebt && !debtSummary.onlyRead"
      class="mt-3 d-block main-secundary-text"
      >*Para pagar las siguientes deudas, debes pagar la primera, y así
      sucesivamente.
    </span>
    <span *ngIf="totalDebt" class="mt-3 d-block main-secundary-text"
      >*Solo se puede pagar el monto completo de la deuda.</span
    >
    <span *ngIf="firstAndTotalDebt" class="mt-3 d-block main-secundary-text"
      >*Solo se puede pagar el total o la primera deuda.</span
    >
  </div>
  <div class="d-flex justify-content-center w-100 mt-4 mb-2">
    <button
      *ngIf="firstDebt || totalDebt"
      mat-raised-button
      class="confirm-btn"
      [ngClass]="!isSelectionValid ? 'opacity-btn' : ''"
      (click)="saveSelectedInvoices()"
      [disabled]="!isSelectionValid"
    >
      Aceptar
    </button>
    <button
      *ngIf="oneOrMoreInOrderDebt"
      mat-raised-button
      class="confirm-btn"
      [ngClass]="!isSelectionValid || totalAmount === 0 ? 'opacity-btn' : ''"
      (click)="saveSelectedInvoices()"
      [disabled]="!isSelectionValid || totalAmount === 0"
    >
      Guardar selección
      {{ totalAmount > 0 ? '(' + (totalAmount | clpCurrency) + ')' : '' }}
    </button>
    <button
      *ngIf="firstAndTotalDebt"
      mat-raised-button
      class="confirm-btn"
      [ngClass]="!isSelectionValidPaymentType ? 'opacity-btn' : ''"
      (click)="saveSelectedInvoices()"
      [disabled]="!isSelectionValidPaymentType"
    >
      Guardar selección
    </button>
  </div>
</div>
