/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirePerformance } from '@angular/fire/compat/performance';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Severity } from '@sentry/browser';
import * as Sentry from '@sentry/browser';
import { NeatMDPErrorCodes } from 'neat-lib/dist/Enums/Constants';
import { timeout } from 'rxjs/internal/operators/timeout';

import { PaymentsService } from '@services/payments/payments.service';
import { RentListService } from '@services/rent-list/rent-list.service';
import { CustomerioService } from '@services/shared/customer-io.service';
import { DatabaseService } from '@services/shared/database/database.service';
import { DialogService } from '@services/shared/dialog/dialog.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { SweetalertService } from '@services/shared/sweetalert/sweetalert.service';
import { AppGlobals } from '@shared/constants';
import {
  EntityType,
  EntityTypeLocale,
  IconErrorModals,
  PaymentProduct
} from '@shared/enums/enums.enum';
import { EntityClass } from 'app/dashboard/checkout/entity';
import { PaymentClass } from 'app/dashboard/checkout/payment';
import { IAutomaticPaymentDates } from 'app/interfaces/automatic-payment.interface';
import { IHeapPaymentInterface } from 'app/interfaces/heap-interfaces';
import {
  EntityHeader,
  IRequestPaymentData,
  TransbankPaymentData
} from 'app/interfaces/payments.interface';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';
import { PaymentRequestTransbank } from 'app/models/payment-request-dto.model';
import { User } from 'app/models/users/user.model';

export interface IRequestMultipaymentData {
  entity: Entity[];
  isMultipay?: boolean;
  paymentMethod: {
    paymentMethodId: string;
    installments: number;
    paymentOption: PaymentProduct;
  };
  tokenId: string;
  amount: number;
  commission: any;
  currentUser: User;
  defaultTransferLimitDate: string;
  card?: string;
  pin?: string;
}

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  traceSubmit: any;

  constructor(
    private db: DatabaseService,
    public router: Router,
    private fireAnalytics: AngularFireAnalytics,
    public rentListService: RentListService,
    private errorService: ErrorHandlerService,
    private paymentClass: PaymentClass,
    private entityClass: EntityClass,
    private swalService: SweetalertService,
    private paymentsService: PaymentsService,
    private firePerformance: AngularFirePerformance,
    private dialogService: DialogService,
    private customerIoService: CustomerioService
  ) {}

  async automatePayment(
    entity: Entity,
    savingEntity: Entity,
    staySection?: boolean
  ) {
    await this.db.firestoreUpdateEntity(
      this.rentListService.entityFactory.getCollectionName(entity.entityType),
      Object.assign({}, savingEntity),
      entity.id
    );
    this.automaticPaymentAnalytics(entity);
    !staySection ? this.router.navigate(['/dashboard/']) : null;
  }

  async automateBasicServiceNew(
    basicService: BasicService,
    savingEntity: BasicService,
    staySection?: boolean
  ) {
    await this.db.firestoreUpdateEntity(
      this.rentListService.basicServiceFactory.getBasicServiceCollectionName(
        basicService.entityType
      ),
      Object.assign({}, savingEntity),
      basicService.id
    );
    this.automaticBasicServiceAnalytics(basicService);
    !staySection ? this.router.navigate(['/dashboard/home/']) : null;
  }

  async automateBasicServiceNewAndDeleteField(
    basicService: BasicService,
    savingEntity: BasicService,
    staySection?: boolean
  ) {
    this.db.deleteNeatAutoFields(
      this.rentListService.basicServiceFactory.getBasicServiceCollectionName(
        basicService.entityType
      ),
      basicService.id
    );
    await this.db.firestoreUpdateEntity(
      this.rentListService.basicServiceFactory.getBasicServiceCollectionName(
        basicService.entityType
      ),
      Object.assign({}, savingEntity),
      basicService.id
    );
    this.automaticBasicServiceAnalytics(basicService);
    !staySection ? this.router.navigate(['/dashboard/home/']) : null;
  }

  async updateEntity(entity: BasicService | Entity, data: any) {
    if (entity.entityType === 'basicService') {
      await this.db.firestoreUpdateEntity(
        this.rentListService.basicServiceFactory.getBasicServiceCollectionName(
          entity.entityType
        ),
        Object.assign({}, data),
        entity.id
      );
    } else {
      await this.db.firestoreUpdateEntity(
        this.rentListService.entityFactory.getCollectionName(entity.entityType),
        Object.assign({}, data),
        entity.id
      );
    }
  }

  async updateBasicService(basicService: BasicService, data: any) {
    await this.db.firestoreUpdateEntity(
      this.rentListService.basicServiceFactory.getBasicServiceCollectionName(
        basicService.entityType
      ),
      Object.assign({}, data),
      basicService.id
    );
  }

  private automaticPaymentAnalytics(entity: Entity) {
    try {
      this.fireAnalytics.logEvent('Automatic payment confirmation', {
        entityType: entity.entityType,
        amount: entity.value,
        currency: entity.payingAccountUnit
      });
    } catch (e) {
      this.errorService.recordError(
        e,
        'checkout.service.ts',
        'this.fireAnalytics.logEvent()',
        'Error al ejecutar Analytics custom event'
      );
    }
  }

  private automaticBasicServiceAnalytics(basicService: BasicService) {
    try {
      this.fireAnalytics.logEvent('Automatic basic service confirmation', {
        entityType: basicService.entityType,
        amount: basicService.value
      });
    } catch (e) {
      this.errorService.recordError(
        e,
        'checkout.service.ts',
        'this.fireAnalytics.logEvent()',
        'Error al ejecutar Analytics custom event'
      );
    }
  }
  automaticPaymentDates(
    entityTransferLimitDate: number
  ): IAutomaticPaymentDates {
    if (entityTransferLimitDate) {
      return this.paymentClass.automaticPaymentDate(entityTransferLimitDate);
    }
  }

  getTransferLimitDate(
    entityTransferLimitDate?: number | undefined,
    selectedDay?: number | undefined
  ): number {
    if (selectedDay) {
      return selectedDay;
    } else if (entityTransferLimitDate) {
      return entityTransferLimitDate;
    } else {
      return;
    }
  }
  async updatePaymentDataOnEntity(
    entity: Entity,
    paymentForm: FormGroup,
    paymentMethodForm: FormGroup,
    automatic?: boolean,
    showError?: boolean
  ): Promise<void | boolean> {
    const savingEntity = this.entityClass.updateEntityFromCheckout(
      paymentForm,
      paymentMethodForm
    );
    try {
      await this.db.firestoreUpdateEntity(
        this.rentListService.entityFactory.getCollectionName(entity.entityType),
        Object.assign({}, savingEntity),
        entity.id
      );
    } catch (error) {
      if (showError) {
        this.swalService.swalError2(
          'Ha ocurrido un error al cargar los datos del pago',
          'Por favor inténtalo de nuevo. Si no funciona escríbenos en el chat.',
          IconErrorModals.sadCloud,
          false,
          true
        );
      }
      this.errorService.recordError(
        error,
        'checkout.service.ts',
        'this.db.firestoreUpdateEntity()',
        `Error al actualizar entidad from updateEntity(): ${JSON.stringify(
          entity
        )}y los datos de salida son:${JSON.stringify(savingEntity)}`,
        Severity.Critical
      );
      throw error;
    }
  }

  async setJSONMultipaymentData(
    paymentDataObject: IRequestMultipaymentData,
    idempotencyKey: string,
    pin?: string
  ): Promise<any> {
    paymentDataObject = Object.assign(paymentDataObject, { pin: pin });
    const multipayObject: any = paymentDataObject.entity.map(entity => {
      return {
        entityId: entity.id,
        entityType: entity.entityType
      };
    });
    const entitiesHeader: Array<EntityHeader> = multipayObject;
    const paymentRequest = this.createMultipaymentRequest(
      entitiesHeader,
      paymentDataObject
    );
    return await this.postJSONMultipaymentDataTransbank(
      paymentRequest,
      paymentDataObject,
      idempotencyKey
    );
  }

  private createMultipaymentRequest(
    entitiesHeader: Array<EntityHeader>,
    paymentDataObject: IRequestMultipaymentData
  ): PaymentRequestTransbank {
    const paymentRequest = AppGlobals.paymentRequestTransbank;
    const paymentData = this.setMultipaymentData(
      entitiesHeader,
      paymentDataObject
    );
    return Object.assign(paymentRequest, paymentData);
  }

  private setMultipaymentData(
    entitiesHeader: EntityHeader[],
    paymentDataObject: IRequestMultipaymentData
  ): TransbankPaymentData {
    return {
      entitiesHeader,
      tbkPaymentMethodId: paymentDataObject.paymentMethod.paymentMethodId,
      installments: Number(paymentDataObject.paymentMethod.installments)
    };
  }

  private async postJSONMultipaymentDataTransbank(
    paymentRequest: any,
    paymentDataObject: IRequestMultipaymentData,
    idempotencyKey: string
  ): Promise<any> {
    if (paymentRequest) {
      const transbankPayment = await this.firePerformance.trace(
        'transbankPaymentRequest from postJSONMultipaymentData()'
      );
      transbankPayment.start();
      try {
        let transbankPaymentResponse: any;
        if (!paymentDataObject.pin) {
          transbankPaymentResponse = await this.paymentsService
            .transbankPaymentRequest(
              paymentRequest,
              idempotencyKey,
              paymentDataObject.tokenId,
              true
            )
            .pipe(timeout(50000))
            .toPromise();
        } else {
          transbankPaymentResponse = await this.paymentsService
            .transbankPaymentRequestWithPin(
              paymentRequest,
              idempotencyKey,
              paymentDataObject.tokenId,
              paymentDataObject.pin,
              true
            )
            .pipe(timeout(50000))
            .toPromise();
        }
        this.transbankMultipaymentResponseHandler(transbankPaymentResponse);
        return {
          response: transbankPaymentResponse,
          entity: paymentDataObject.entity
        };
      } catch (err) {
        this.transbankMultipaymentResponseHandler(err);
        this.errorService.recordError(
          err,
          'checkout.service.ts',
          'postJSONMultipaymentDataTransbank()',
          'Error al procesar multipago con transbank',
          Sentry.Severity.Critical
        );
        return { response: err, entity: paymentDataObject.entity };
      } finally {
        transbankPayment.stop();
      }
    }
  }

  async setJSONPaymentData(
    paymentDataObject: IRequestPaymentData,
    idempotencyKey: string,
    pin?: string,
    heapData?: IHeapPaymentInterface
  ): Promise<boolean> {
    paymentDataObject = Object.assign(paymentDataObject, { pin: pin });
    const entitiesHeader: Array<EntityHeader> = [
      {
        entityId: paymentDataObject.entity.id,
        entityType: paymentDataObject.entity.entityType
      }
    ];
    const paymentRequest = this.createPaymentRequest(
      entitiesHeader,
      paymentDataObject
    );
    return await this.postJSONPaymentDataTransbank(
      paymentRequest,
      paymentDataObject,
      heapData,
      idempotencyKey
    );
  }

  private createPaymentRequest(
    entitiesHeader: Array<EntityHeader>,
    paymentDataObject: IRequestPaymentData
  ): PaymentRequestTransbank {
    const paymentRequest = AppGlobals.paymentRequestTransbank;
    const paymentData = this.setPaymentData(entitiesHeader, paymentDataObject);
    return Object.assign(paymentRequest, paymentData);
  }

  private setPaymentData(
    entitiesHeader: EntityHeader[],
    paymentDataObject: IRequestPaymentData
  ): TransbankPaymentData {
    return {
      entitiesHeader,
      tbkPaymentMethodId: paymentDataObject.paymentMethod.paymentMethodId,
      installments: Number(paymentDataObject.paymentMethod.installments)
    };
  }

  private async postJSONPaymentDataTransbank(
    paymentRequest: PaymentRequestTransbank,
    paymentDataObject: IRequestPaymentData,
    heapData: IHeapPaymentInterface,
    idempotencyKey: string
  ): Promise<any> {
    if (paymentRequest) {
      const transbankPayment = await this.firePerformance.trace(
        'transbankPaymentRequest from postJSONPaymentData()'
      );
      transbankPayment.start();
      try {
        let transbankPaymentResponse: any;
        if (!paymentDataObject.pin) {
          transbankPaymentResponse = await this.paymentsService
            .transbankPaymentRequest(
              paymentRequest,
              idempotencyKey,
              paymentDataObject.tokenId
            )
            .pipe(timeout(50000))
            .toPromise();
        } else {
          transbankPaymentResponse = await this.paymentsService
            .transbankPaymentRequestWithPin(
              paymentRequest,
              idempotencyKey,
              paymentDataObject.tokenId,
              paymentDataObject.pin
            )
            .pipe(timeout(50000))
            .toPromise();
        }
        if (!paymentDataObject?.isMultipay) {
          this.transbankPaymentResponseHandler(
            transbankPaymentResponse,
            paymentDataObject,
            heapData
          );
        }
        return {
          response: transbankPaymentResponse,
          entity: paymentDataObject.entity
        };
      } catch (err) {
        this.errorService.recordError(
          err,
          'checkout.service.ts',
          'postJSONPaymentData()',
          'Error al procesar pago con transbank',
          Sentry.Severity.Critical
        );
        if (!paymentDataObject?.isMultipay) {
          this.transbankPaymentResponseHandler(
            err,
            paymentDataObject,
            heapData
          );
        }
        return { response: err, entity: paymentDataObject.entity };
      } finally {
        transbankPayment.stop();
      }
    }
  }

  private transbankMultipaymentResponseHandler(response: any): void {
    const paymentResponseStatus = response?.status;
    if (![200, 201].includes(paymentResponseStatus)) {
      this.transbankError(response);
    }
  }

  private transbankPaymentResponseHandler(
    response: any,
    paymentDataObject: IRequestPaymentData,
    heapData: IHeapPaymentInterface
  ): void {
    const paymentResponseStatus = response?.status;
    if (paymentResponseStatus && [200, 201].includes(paymentResponseStatus)) {
      try {
        this.heapTrack(paymentDataObject);
        this.fireAnalytics.logEvent('Payment_Success');
        const heapObject = Object.assign(
          {
            Respuesta: 'Exito'
          },
          heapData
        );
        // Heap Tracks 2.0
        this.fireAnalytics.logEvent('Pagar', heapObject);
        //fbq('track', 'Purchase',
        //   {
        //     value: paymentDataObject.amount + (paymentDataObject?.commission?.commission ? paymentDataObject.commission.commission : 0),
        //     commission: paymentDataObject?.commission?.commission ? paymentDataObject.commission.commission : null,
        //     content_type: paymentDataObject.entity?.entityType,
        //     currency: paymentDataObject.entity?.payingAccountUnit
        //   }
        // );
      } catch (err) {
        this.fireAnalytics.logEvent('Payment_Failed');
        const heapObject = Object.assign(
          {
            Respuesta: 'Error',
            CódigoError: 'Neat'
          },
          heapData
        );
        // Heap Tracks 2.0
        this.fireAnalytics.logEvent('Pagar', heapObject);
        this.errorService.recordError(
          err,
          'checkout.service.ts',
          'transbankPaymentResponseHandler()',
          `fbq error ${err}`
        );
      }
      this.transbankPaymentFireAnalytics(paymentDataObject);
      paymentDataObject?.isMultipay
        ? null
        : this.goToPaymentHistory(paymentDataObject);
      this.rentListService.currentEntity = null;
      this.rentListService.selectedEntities = null;
    } else {
      this.fireAnalytics.logEvent('Payment_Failed');
      const heapObject = Object.assign(
        {
          Respuesta: 'Error',
          CódigoError:
            response.error && JSON.parse(response.error)?.errorCode
              ? JSON.parse(response.error).errorCode
              : 'Desconocido'
        },
        heapData
      );
      // Heap Tracks 2.0
      this.fireAnalytics.logEvent('Pagar', heapObject);
      this.transbankError(response, heapData);
    }
  }

  private goToPaymentHistory(paymentDataObject: IRequestPaymentData): void {
    const showMazeNPS = Math.random() <= 0.4 ? 'success' : 'ok';
    if (showMazeNPS === 'success') {
      try {
        const mazeId =
          JSON.parse(sessionStorage?.getItem('maze:widgets'))?.value[0]
            ?.mazeId[0] || null;
        this.fireAnalytics.logEvent('MazeNPS', {
          email: paymentDataObject?.currentUser?.email,
          time: new Date(
            new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
          ),
          mazeId: mazeId
        });
      } catch (err) {
        /* empty */
      }
    }
    this.router
      .navigate(['/dashboard/historial'], {
        queryParams: { status: showMazeNPS }
      })
      .then(() => {
        this.customerIoService.trackEvent('payment_complete', {
          isNeatEntity: false
        });
        if (!paymentDataObject.entity.customData) {
          this.dialogService.openPaymentSuccess({
            category:
              paymentDataObject.entity.entityType !== EntityType.others
                ? EntityTypeLocale[paymentDataObject.entity.entityType]
                : paymentDataObject.entity.category,
            transferDate: paymentDataObject.defaultTransferLimitDate,
            entity: paymentDataObject.entity
          });
        } else {
          this.dialogService.openPaymentSuccess({
            category: paymentDataObject.entity.customData.providerName,
            entity: paymentDataObject.entity
          });
        }
      });
  }

  private transbankError(
    response: any,
    heapData?: IHeapPaymentInterface
  ): void {
    try {
      this.transbankErrorHandler(response, heapData);
    } catch (e) {
      this.fireAnalytics.logEvent('Payment_Failed');
      if (heapData) {
        const heapObject = Object.assign(
          {
            Respuesta: 'Error',
            CódigoError: 'Sin respuesta'
          },
          heapData
        );
        this.fireAnalytics.logEvent('Pagar', heapObject);
      }
      this.swalService.swalError2(
        'Hubo un problema al procesar el pago',
        'Por favor reintenta, si el problema no se soluciona, escríbenos en el chat.',
        IconErrorModals.sadCard,
        false,
        true
      );
      this.errorService.recordError(
        e,
        'checkout.service.ts',
        'transbankPaymentResponseHandler()',
        `General error on else, response: ${JSON.stringify(response)}`,
        Sentry.Severity.Critical
      );
    }
  }

  private transbankPaymentFireAnalytics(
    paymentDataObject: IRequestPaymentData
  ): void {
    try {
      this.fireAnalytics.logEvent('Payment confirmation', {
        entityType: paymentDataObject?.entity?.entityType,
        amount: paymentDataObject?.amount,
        currency: 'CLP',
        commission: paymentDataObject?.commission?.commission
          ? paymentDataObject.commission.commission
          : null,
        discount: paymentDataObject?.commission?.discountApplied,
        provider: 'Transbank',
        isExpress: paymentDataObject?.entity?.isExpressPayment
      });
    } catch (e) {
      this.errorService.recordError(
        e,
        'checkout.service.ts',
        'this.fireAnalytics.logEvent()',
        'Error al ejecutar Analytics custom event'
      );
    }
  }

  private transbankErrorHandler(
    response: any,
    heapData?: IHeapPaymentInterface
  ): void {
    try {
      const errorCode =
        response.error &&
        JSON.parse(response.error)?.errorCode &&
        JSON.parse(response.error)?.errorCode;
      const getNeatCodeError = NeatMDPErrorCodes[errorCode];
      const showError = ![NeatMDPErrorCodes.NeatMaxAmount].includes(errorCode);
      if (getNeatCodeError && showError) {
        if (
          (heapData.cardBankName.toLocaleLowerCase().includes('cmr') ||
            heapData.cardBankName.toLocaleLowerCase().includes('falabella')) &&
          [NeatMDPErrorCodes.TbkTransactionProblem].includes(errorCode)
        ) {
          this.dialogService.openCmrPaymentFailedModal();
        } else {
          this.swalService.swalError2(
            'Ha ocurrido un error con tu pago',
            `
        <div class="swalCustomClass">
          <span class="mb-3">No sabemos exactamente el motivo, se puede deber a:</span> 
          <span class="mb-2">
            <li>Problemas de cupo en la tarjeta</li>
            <li>Algún bloqueo bancario</li>
            <li>Intermitencia en los sistemas del banco</li>
            <li>Bloqueo por el sistema de fraude bancario</li>
            <li>Expiración de la tarjeta</li>
          </span>
          <span>Te recomendamos intentar el pago con otra tarjeta o ponerte en contacto con el banco.</span>
        </div>`,
            IconErrorModals.sadCard,
            true,
            false,
            true
          );
        }
      } else {
        let message =
          'Por favor reintenta, si el problema no se soluciona, escríbenos en el chat.';
        if (JSON.parse(response.error)?.message && errorCode) {
          message = JSON.parse(response.error)?.message;
        }
        this.swalService.swalError2(
          'Hubo un problema con tu pago',
          message,
          IconErrorModals.sadCard,
          true,
          true
        );
        if (!getNeatCodeError) {
          this.errorService.recordError(
            new Error(`errorCode undefined`),
            'checkout.service.ts',
            'transbankPaymentResponseHandler()',
            `No errorCode exist in response: ${JSON.stringify(response)}`,
            Sentry.Severity.Critical
          );
        }
      }
    } catch (err) {
      this.errorService.recordError(
        new Error(`transbankErrorHandler`),
        'checkout.service.ts',
        'transbankErrorHandler()',
        `No errorCode exist in response: ${JSON.stringify(err)}`,
        Sentry.Severity.Critical
      );
    }
  }

  private heapTrack(paymentDataObject: IRequestPaymentData): void {
    try {
      this.fireAnalytics.logEvent('Purchase', {
        amount: paymentDataObject?.amount,
        category: paymentDataObject.entity?.entityType,
        currency: 'CLP',
        commission: paymentDataObject?.commission?.commission
          ? paymentDataObject.commission.commission
          : null,
        discount: paymentDataObject?.commission?.discountApplied,
        provider: 'Transbank',
        isExpress: paymentDataObject.entity?.isExpressPayment
      });
    } catch (e) {
      this.errorService.recordError(
        e,
        'checkout.service.ts',
        'this.fireAnalytics.logEvent()',
        'Error al ejecutar track en heap'
      );
    }
  }
}
