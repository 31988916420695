import { Injectable, isDevMode } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { SoyioTypes, SoyioWidget } from '@soyio/soyio-widget';
import { EFraudSuspectReasons } from 'neat-lib/dist/Enums/Constants';
import { Subject, BehaviorSubject, Observable } from 'rxjs';

import { CookiesService } from '@services/cookies/cookies.service';
import { DialogService } from '@services/shared/dialog/dialog.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { verificationDictionary } from '@shared/utils';
import { UserInfo } from 'app/models/users/user-info.model';
import { User } from 'app/models/users/user.model';

@Injectable({
  providedIn: 'root'
})
export class SoyIOService {
  widgetStatus: Subject<string> = new BehaviorSubject<string>('');

  constructor(
    private dialogService: DialogService,
    private errorService: ErrorHandlerService,
    private cookiesService: CookiesService,
    private fireAnalytics: AngularFireAnalytics
  ) {}

  initializeWidget(user: User) {
    try {
      const widgetConfig: SoyioTypes.AttemptConfig = {
        request: 'disclosure',
        configProps: {
          companyId: 'com_M9OVrcVKsOiSxtQdg_kZpQ',
          userReference: user.id,
          userEmail: user.email,
          templateId: 'vft_NwEO77kSy_1IXBH7KRUEMQ',
          customColor: '#3368f0'
        },
        onEvent: data => {
          data?.eventName && this.addWidgetStatus(data?.eventName);
        },
        isSandbox: isDevMode() ? true : false
      };
      new SoyioWidget(widgetConfig);
      this.fireAnalytics.logEvent(`SOYIO WIDGET OPENED`, {
        userId: user?.id,
        userEmail: user?.email
      });
    } catch (error) {
      this.errorService.recordError(
        error,
        'soy-io.service.ts',
        'this.initializeWidget()',
        'Soy Io service problem'
      );
    }
  }

  get widgetStatus$(): Observable<string> {
    return this.widgetStatus.asObservable();
  }

  addWidgetStatus(status: string): void {
    this.widgetStatus.next(status);
  }

  showResponseModal(userInfo: UserInfo, widgetStatus: string): void {
    if (
      userInfo?.verificationProvider &&
      userInfo?.verificationProvider?.isStronglyVerified &&
      ['WIDGET_CLOSED', 'DISCLOSURE_REQUEST_SUCCESSFUL'].includes(widgetStatus)
    ) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.verified
      );
    } else if (
      userInfo?.verificationProvider &&
      !userInfo?.verificationProvider?.isStronglyVerified &&
      ['WIDGET_CLOSED', 'DISCLOSURE_REQUEST_SUCCESSFUL'].includes(
        widgetStatus
      ) &&
      userInfo?.fraudSuspectReasons &&
      userInfo?.fraudSuspectReasons[userInfo?.fraudSuspectReasons?.length - 1]
        ?.reason === EFraudSuspectReasons.identityImpersonation
    ) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.notSameRut
      );
    } else if (
      userInfo?.verificationProvider &&
      !userInfo?.verificationProvider?.isStronglyVerified &&
      ['WIDGET_CLOSED', 'DISCLOSURE_REQUEST_SUCCESSFUL'].includes(
        widgetStatus
      ) &&
      userInfo?.fraudSuspectReasons &&
      ![
        EFraudSuspectReasons.birthdayToClose,
        EFraudSuspectReasons.userHaveMoreBin,
        EFraudSuspectReasons.suspectUtility
      ].includes(
        userInfo?.fraudSuspectReasons[userInfo?.fraudSuspectReasons?.length - 1]
          ?.reason
      )
    ) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.fatalError
      );
    } else if (
      userInfo?.verificationProvider &&
      !userInfo?.verificationProvider?.isStronglyVerified &&
      ['WIDGET_CLOSED', 'DISCLOSURE_REQUEST_SUCCESSFUL'].includes(
        widgetStatus
      ) &&
      userInfo?.fraudSuspectReasons &&
      [
        EFraudSuspectReasons.birthdayToClose,
        EFraudSuspectReasons.userHaveMoreBin,
        EFraudSuspectReasons.suspectUtility
      ].includes(
        userInfo?.fraudSuspectReasons[userInfo?.fraudSuspectReasons?.length - 1]
          ?.reason
      )
    ) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.temporalBlock
      );
    } else if (
      !userInfo?.verificationProvider &&
      ['WIDGET_CLOSED', 'DISCLOSURE_REQUEST_SUCCESSFUL'].includes(widgetStatus)
    ) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.notCompleted
      );
    }
  }

  showStatusModal(userInfo: UserInfo): void {
    const showPendingModal = this.cookiesService.getCookie(
      `verifyPending${userInfo?.id}`
    );
    if (showPendingModal) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.stillProcessing
      );
    } else if (
      userInfo?.verificationProvider &&
      !userInfo?.verificationProvider?.isStronglyVerified &&
      userInfo?.fraudSuspectReasons &&
      userInfo?.fraudSuspectReasons[userInfo?.fraudSuspectReasons?.length - 1]
        ?.reason === EFraudSuspectReasons.identityImpersonation
    ) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.notSameRut
      );
    } else if (
      userInfo?.verificationProvider &&
      !userInfo?.verificationProvider?.isStronglyVerified &&
      userInfo?.fraudSuspectReasons &&
      ![
        EFraudSuspectReasons.birthdayToClose,
        EFraudSuspectReasons.userHaveMoreBin,
        EFraudSuspectReasons.suspectUtility
      ].includes(
        userInfo?.fraudSuspectReasons[userInfo?.fraudSuspectReasons?.length - 1]
          ?.reason
      )
    ) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.fatalError
      );
    } else if (
      (userInfo?.verificationProvider &&
        !userInfo?.verificationProvider?.isStronglyVerified &&
        userInfo?.fraudSuspectReasons &&
        [
          EFraudSuspectReasons.birthdayToClose,
          EFraudSuspectReasons.userHaveMoreBin,
          EFraudSuspectReasons.suspectUtility
        ].includes(
          userInfo?.fraudSuspectReasons[
            userInfo?.fraudSuspectReasons?.length - 1
          ]?.reason
        )) ||
      (userInfo?.fraudSuspect && userInfo?.verificationProvider) ||
      (!userInfo?.verificationProvider?.isStronglyVerified &&
        userInfo?.fraudSuspect)
    ) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.temporalBlock
      );
    } else if (!userInfo?.verificationProvider) {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.processing
      );
    } else {
      this.dialogService.openVerificationModalStatus(
        verificationDictionary.stillProcessing
      );
    }
  }
}
