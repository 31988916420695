import { Overlay, BlockScrollStrategy } from '@angular/cdk/overlay';
import { MatTooltipDefaultOptions } from '@angular/material/tooltip';

/* Mat tooltip default options */
export const customTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 100,
  hideDelay: 0,
  touchendHideDelay: 1500
};

/* Scroll strategy for mat select */
export function scrollFactory(overlay: Overlay): () => BlockScrollStrategy {
  return () => overlay.scrollStrategies.block();
}

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_DATES_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
