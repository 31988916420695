<div class="boxes">
  <div class="header" style="background-color: white;">
    <div
      class="d-flex w-100 align-items-center p-4"
      style="justify-content: space-between;"
    >
      <span class="d-block blue-title">NeatClub - Términos y Condiciones</span>
      <span class="close-icon" style="cursor: pointer;" (click)="close()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="13"
          viewBox="0 0 12 13"
          fill="none"
        >
          <g opacity="0.5">
            <path
              d="M1 1.5L11 11.5"
              stroke="#3C4082"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M11 1.5L1 11.5"
              stroke="#3C4082"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </svg>
      </span>
    </div>
    <div
      style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
    ></div>
  </div>
  <div class="content" style="background: var(--primary-05, #F5F8FE);">
    <div class="main-section w-100 flex-column p-4">
      <div class="d-flex flex-column align-items-center w-100">
        <span class="subtitle-terms"
          >Estos Términos y Condiciones son complementarios a los Términos y
          Condiciones de uso de NEAT SpA y tiene por objetivo regular el
          Programa NEAT CLUB.</span
        >
      </div>
      <div
        class="my-4"
        style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
      ></div>
      <div>
        <span class="title-terms">
          <span class="pink-terms">PRIMERO:</span> En qué consiste el Programa
        </span>
        <span class="main-text-terms mt-3 d-block">
          NEAT SpA (“NEAT”), RUT 77.005.598-9, domiciliada en calle Prat 1101,
          ciudad de San Felipe, ha organizado un programa de beneficios
          denominado “Programa NEAT CLUB”, en adelante también el “Programa”,
          que permite a los clientes o usuarios de NEAT  acumular puntos
          denominados “NEAT POINTS”, con el objetivo de complementar nuestra
          propuesta de valor, ayudando a construir una sociedad de confianza,
          premiando a los buenos pagadores. Por ello, el Programa permite a los
          clientes o usuarios de NEAT acumular NEAT POINTS para luego poder
          canjearlos por una serie de beneficios, a partir de sus preferencias,
          intereses y gustos. Estos beneficios pueden consistir en el canje de
          descuentos en la comisión cobrada por NEAT SpA, cupones de descuentos
          que podrán ser utilizados con terceros cuya singularización será
          oportunamente informada en nuestra página web, entre otros, y que se
          desarrollará de acuerdo con las siguientes bases (“las Bases”).
        </span>
      </div>
      <div
        class="my-4"
        style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
      ></div>
      <div>
        <span class="title-terms">
          <span class="pink-terms">SEGUNDO:</span> Cómo acceder al Programa
        </span>
        <span class="main-text-terms mt-3 d-block">
          Podrán ingresar al Programa todos aquellos usuarios registrados
          previamente en nuestra página web www.neatpagos.com o en nuestra
          aplicación móvil “NEAT PAGOS” que cumplan con las siguientes
          condiciones, de manera tal de pasar a ser “Usuarios Calificables”,
          para lo cual deben formar parte de alguno de los siguientes segmentos:
        </span>
        <span class="main-text-terms mt-3 d-block">
          Segmento calificable. Haber pagado dentro de un mismo mes calendario
          lo siguiente:
        </span>
        <span class="main-text-terms mt-3 d-block">
          El usuario deberá haber pagado una cuenta de luz, agua, telefonía,
          internet, gas o autopistas.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Una cuenta diferente a las anteriores.
        </span>
        <div
          class="mt-3"
          style="border-radius: 10px;
                border: 1px solid #D6E1FC; display: flex;
                padding: 24px;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;"
        >
          <span class="subtitle-terms">
            A modo de ejemplo, el día 1 de agosto, podrías haber pagado una
            cuenta de luz más una de gasto común y estarías cumpliendo las
            condiciones.
          </span>
        </div>
        <span class="main-text-terms mt-3 d-block">
          Por motivos de seguridad, para poder inscribirte a NEAT CLUB deberás
          acreditar tu identidad cumpliendo el proceso de verificación exigido
          por NEAT, además deberás aceptar los términos y condiciones vigentes
          de NEAT CLUB.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Asimismo, el usuario solo podrá acceder a NEAT CLUB una vez que acepte
          expresamente los Términos y Condiciones, incluyendo sus
          modificaciones.
        </span>
        <span class="main-text-terms mt-3 d-block">
          NEAT podrá revisar y modificar los presentes Términos y Condiciones,
          en adelante e indistintamente “TyC”. Dichas modificaciones producirán
          efectos respecto del Usuario sólo cuando hayan sido aceptados
          expresamente por éste, previo aviso de NEAT. Si el Usuario no acepta
          las modificaciones a los presentes TyC, siempre podrá darse de baja
          del Programa. Las modificaciones a los TyC serán informadas en el
          sitio web www.neatpagos.com o la aplicación móvil “NEAT PAGOS” con al
          menos 5 días hábiles de anticipación a su entrada en vigencia, y sólo
          tendrán efectos a futuro desde su aceptación, sin que ello afecte los
          beneficios ya generados y derechos adquiridos en favor del Usuario en
          virtud de los términos y condiciones previos a su modificación. En
          caso de no aceptar los nuevos TyC, se entenderá que el Usuario se da
          de baja del Programa, no pudiendo seguir accediendo a los beneficios
          de este.
        </span>
      </div>
      <div
        class="my-4"
        style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
      ></div>
      <div>
        <span class="title-terms">
          <span class="pink-terms">TERCERO:</span> Cómo acumular NEAT POINTS
        </span>
        <span class="subtitle-terms mt-3  d-block">
          Acumulación de NEAT POINTS
        </span>
        <span class="main-text-terms mt-3 d-block">
          Una vez que accedes a NEAT CLUB, podrás empezar a acumular NEAT POINTS
          en el pago de todas tus cuentas por lo que resta del mes en curso y
          también durante todo el mes calendario siguiente.
        </span>

        <div
          class="mt-3"
          style="border-radius: 10px;
                border: 1px solid #D6E1FC; display: flex;
                padding: 24px;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;"
        >
          <span class="subtitle-terms">
            A modo de ejemplo, si ingresaste al programa el día 12 de agosto,
            podrás acumular NEAT POINTS durante lo que queda del mes de agosto y
            septiembre de ese año.
          </span>
        </div>

        <span class="main-text-terms mt-3 d-block">
          Para seguir acumulando NEAT POINTS, el mes siguiente a tu mes de
          acceso, tendrás que pagar las siguientes cuentas:
        </span>

        <span class="main-text-terms mt-3 d-block">
          Una cuenta de luz, agua, telefonía, internet, gas o autopistas.
        </span>

        <span class="main-text-terms mt-3 d-block">
          Y una cuenta diferente a las anteriores.
        </span>

        <div
          class="mt-3"
          style="border-radius: 10px;
                border: 1px solid #D6E1FC; display: flex;
                padding: 24px;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;"
        >
          <span class="subtitle-terms">
            A modo de ejemplo, si ingresaste el 12 de agosto, tendrás que
            realizar el pago de las cuentas anteriormente descritas durante el
            mes de septiembre para poder seguir acumulando beneficios durante
            octubre. En caso de no haber cumplida esa condición, acumularás NEAT
            POINTS por todos los pagos que hagas hasta el último día de
            septiembre, pero no acumularás por pagos realizados en octubre.
          </span>
        </div>

        <span class="main-text-terms mt-3 d-block">
          Un usuario NEAT acumulará 1 NEAT Point por cada $1.000 gastados en
          NEAT. Es decir, se considerará el monto total cargado en la tarjeta
          utilizada, incluyendo la comisión cobrada por el uso del servicio y
          los descuentos que pueda haber tenido el pago.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Todas las transacciones que cumplan las condiciones descritas
          anteriormente, acumularán NEAT POINTS. Los NEAT POINTS acumulables
          considerarán solo números enteros, sin decimales (considerando
          solamente los dígitos sobre los miles de pesos). Es decir, si el monto
          pagado es, por ejemplo, $123.600, los NEAT POINTS acumulables serán
          123.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Todos los pagos menores a $1.000 (incluyendo comisión) efectuados en
          NEAT, no acumularán NEAT POINTS.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Los cargos asociados a pagos automáticos mensuales acumulan NEAT
          POINTS.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Los NEAT POINTS acumulados serán abonados a la cuenta del usuario una
          vez se haya confirmado el depósito al destinatario, lo que puede
          ocurrir hasta en 7 días hábiles posteriores a la emisión del
          comprobante de transferencia por parte de NEAT. Si el usuario anuló el
          pago dentro de dicho período, o el pago realizado no fue correctamente
          transferido al destinatario, no se abonarán NEAT POINTS a la cuenta
          del usuario.
        </span>
        <span class="subtitle-terms mt-3  d-block">
          Inhabilitación y pérdida de NEAT POINTS
        </span>
        <span class="main-text-terms mt-3 d-block">
          Si eliminas tu cuenta de usuario NEAT perderás los NEAT POINTS
          acumulados y también el acceso a NEAT CLUB. Al eliminar tu cuenta no
          podremos restaurar los datos previos.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Si el titular de la cuenta o usuario no diera aviso oportuno a NEAT
          del robo de su información financiera, dispositivos digitales que
          puedan poner en riesgo el uso de su cuenta NEAT y/o NEAT POINTS, NEAT
          no será responsable del uso indebido de su cuenta y tampoco del canje
          de NEAT POINTS que se puedan haber ejecutado, sin embargo, si será
          responsable, en los casos, que de acuerdo a la ley, le corresponda
          asumir dicha responsabilidad.
        </span>
        <span class="main-text-terms mt-3 d-block">
          En caso de que NEAT tenga motivos fundados o comprobación de que has
          entregado información falsa o errónea, o de que usaste el Programa
          fraudulentamente, en forma manifiestamente contraria a estos TyC o la
          Política de Privacidad; para cometer algún ilícito; o que un tercero
          haya usado tu cuenta;  NEAT podrá dar de baja tu cuenta, eliminar tus
          NEAT POINTS, dejar sin efecto los canjes realizados, excluirte del
          Programa y hasta perseguir las responsabilidades legales que sean
          procedentes. En dichos casos, NEAT te comunicará vía correo
          electrónico las razones que justifican esas medidas, adjuntando los
          antecedentes disponibles. En todo caso, podrás presentar una
          aclaración escrita, la cual deberá contener una explicación y/o
          justificación de la conducta realizada y acompañar los antecedentes
          que estimes necesarios. La evaluación de dichas explicaciones,
          justificaciones y antecedentes será realizada por NEAT, mediante
          criterios objetivos, razonables y fundados.
        </span>
        <span class="main-text-terms mt-3 d-block">
          NEAT adoptará las medidas necesarias y prudentes para resguardar la
          seguridad de tus datos y de tu clave secreta. En caso de detectarse
          cambios en la información registrada o como medida de protección de tu
          identidad, ejecutivos de NEAT te podrán contactar a fin de corroborar
          tus datos e intentar evitar posibles fraudes.
        </span>
        <span class="main-text-terms mt-3 d-block">
          El usuario perderá los NEAT POINTS acumulados y el acceso a NEAT CLUB
          en los casos que se evidencien transacciones fraudulentas dispuestas
          en la ley N° 20.009, así como cualquier otra transacción que se
          desconozca.
        </span>
        <span class="subtitle-terms mt-3  d-block">
          Control y revisión de NEAT POINTS Acumulados
        </span>
        <span class="main-text-terms mt-3 d-block">
          Podrás revisar tu saldo de NEAT POINTS ingresando a la sección
          app.neatpagos.com/neatclub disponible en nuestra página web
          www.neatpagos.com y aplicación Móvil NEAT PAGOS.
        </span>
        <span class="main-text-terms mt-3 d-block">
          El abono de NEAT POINTS podrá ser realizado en un plazo máximo de 7
          días hábiles luego de haber realizado una compra que cuente con
          comprobante de transferencia exitosa emitido por el banco.
        </span>
        <span class="main-text-terms mt-3 d-block">
          El saldo de NEAT POINTS, en ningún caso pueden ser transferidos, son
          propiedad exclusiva del titular de la cuenta de usuario NEAT.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Los NEAT POINTS no son heredables, en caso de muerte serán anulados.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Si estimares que existe una diferencia entre los NEAT POINTS
          acumulados  y los que consideras tener acumulados, podrás requerir a
          NEAT una revisión a través del sitio del chat de servicio al cliente
          habilitado en www.neatpagos.com o en la aplicación móvil NEAT PAGOS, o
          en el correo electrónico neatclub@neatpagos.com. Solo se revisarán
          casos dónde los NEAT POINTS acumulados hayan sido entregados en un
          plazo menor o igual a 30 días desde la fecha en la que se requiere la
          revisión.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Si no quedas conforme con la revisión efectuada, deberás enviar un
          mail a neatclub@neatpagos.com con todo el detalle de las transacciones
          que deberían ser consideradas en la acumulación, para su revisión. Si
          existieren errores en la información proporcionada respecto a la
          cantidad de NEAT POINTS acumulados, estos serán corregidos en cuanto
          se detecten. Su inclusión no te autoriza a hacer uso de ellos,
          debiendo esperar que tu situación sea regularizada.
        </span>
        <span class="subtitle-terms mt-3  d-block">
          Límites de acumulación mensual
        </span>
        <span class="main-text-terms mt-3 d-block">
          El tope máximo por mes calendario de acumulación será de 10.000 NEAT
          POINTS
        </span>
      </div>
      <div
        class="my-4"
        style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
      ></div>
      <div>
        <span class="title-terms">
          <span class="pink-terms">CUARTO:</span> ¿Cómo canjear los NEAT POINTS?
        </span>
        <span class="main-text-terms mt-3 d-block">
          Tendrás derecho a efectuar canjes de distintos productos o servicios,
          o canjear otros beneficios que se establezcan, según tus NEAT POINTS
          acumulados, y conforme a lo informado en www.neatpagos.com o en otros
          medios que sean debidamente informados.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Efectuado el canje, se descontarán los NEAT POINTS respectivos de tu
          cuenta NEAT.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Para efectuar el canje de NEAT POINTS deberás ingresar a
          www.neatpagos.com o a los canales que se implementen para estos
          efectos.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Las garantías de los productos o servicios canjeados, si
          correspondiere, serán honradas por los fabricantes o por la empresa
          proveedora del producto o servicio respectivo, según corresponda, y se
          regirán por las condiciones particulares establecidas por los mismos.
          A los productos canjeados aplicarán la garantía legal y garantía
          voluntaria, según correspondieren, de acuerdo con las reglas
          generales.
        </span>
      </div>
      <div
        class="my-4"
        style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
      ></div>
      <div>
        <span class="title-terms">
          <span class="pink-terms">QUINTO:</span> Vigencia del programa y de los
          NEAT POINTS
        </span>
        <span class="main-text-terms mt-3 d-block">
          Vigencia del programa indefinida, pudiendo NEAT dar término al
          programa mediante aviso dado en la página web de NEAT con una
          anticipación no menor de 60 días a la fecha que se indique como de
          término. Expirado el programa, los NEAT POINTS ya acumulados se
          mantendrán por el tiempo que les reste de su vigencia original.
        </span>
        <span class="main-text-terms mt-3 d-block">
          Los NEAT POINTS tendrán una vigencia de 12 meses calendarios a partir
          de la fecha de entrega.
        </span>

        <div
          class="mt-3"
          style="border-radius: 10px;
                border: 1px solid #D6E1FC; display: flex;
                padding: 24px;
                justify-content: center;
                align-items: flex-start;
                gap: 16px;
                align-self: stretch;"
        >
          <span class="subtitle-terms">
            A modo de ejemplo, si tus pagos del 1 junio del año 2024 acumuló 200
            NEAT POINTS, el de 16 de junio 50 y el de 3 de julio 500, tus puntos
            vencerán de la siguiente forma, en 30 junio del 2025 vencerán 250
            NEAT POINTS, suma de los 200 y 50 acumulados en junio del 2024, y el
            31 de julio vencerán 500 NEAT POINTS.
          </span>
        </div>
      </div>
      <div
        class="my-4"
        style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
      ></div>
      <div>
        <span class="title-terms">
          <span class="pink-terms">SEXTO:</span> Tratamiento de tus datos
          personales
        </span>

        <span class="main-text-terms mt-3 d-block">
          Tus datos personales proporcionados al momento de inscribirte, así
          como los observados y obtenidos durante la vigencia de tu adhesión al
          Programa, serán tratados de acuerdo con lo establecido en este
          Reglamento y en la Política de Privacidad.
        </span>

        <span class="main-text-terms mt-3 d-block">
          El tratamiento de tus datos antes indicados, incluyendo los datos de
          contacto, de transacciones de compra, y de navegación, consulta o
          visita de sitios electrónicos de NEAT y de los Comercios con Alianza,
          resulta esencial y necesario para que, NEAT administrador del
          Programa, y los comercios adheridos, puedan cumplir sus respectivas
          obligaciones contractuales que nacen con ocasión de la participación
          en el Programa NEAT CLUB, entre ellas la acumulación, acreditación y
          canje de NEAT POINTS y la provisión y uso de los diversos beneficios
          comerciales asociados al Programa.
        </span>

        <span class="main-text-terms mt-3 d-block">
          Además, podremos realizar encuestas de satisfacción; desarrollar
          campañas de marketing y estudios de mercado propios o de terceros;
          realizar gestiones preventivas y propias de cumplimiento, incluyendo
          la comunicación de los datos a autoridades o reguladores; y remitirte
          invitaciones a eventos.
        </span>

        <span class="main-text-terms mt-3 d-block">
          En particular, para que el Programa NEAT CLUB pueda proporcionarte
          beneficios personalizados -adaptados a tus intereses, gustos, o
          experiencias de uso en sitios habilitados por NEAT y en los Comercios
          con Alianza-, consistentes en ofertas, promociones y descuentos
          preferentes o exclusivos o ajustados a tus características
          particulares, resulta necesaria la ejecución de actividades de
          perfilamiento de usuarios, a partir de tus datos personales
          recolectados.
        </span>

        <span class="main-text-terms mt-3 d-block">
          Sin perjuicio de lo anterior, y en los términos detallados en la
          Política de Privacidad, el envío de determinadas comunicaciones
          publicitarias, adicionales a las que se encuentran amparadas en el
          consentimiento que has otorgado como parte de la aceptación de este
          Reglamento, podría encontrarse fundamentada, de conformidad con la
          normativa vigente, en otras bases legales, incluyendo tu
          consentimiento o autorización particular, o bien en uno o más
          intereses legítimos o legalmente tutelados, debidamente informados,
          del administrador del Programa y y de los Comercios con Alianza al
          mismo. Dentro de estos intereses se encuentran destinados a
          proporcionar servicios innovadores y una amplia gama de opciones de
          personalización, de manera eficiente, segura y amena, considerando tus
          intereses y derechos, como los del resto de los usuarios.
        </span>

        <span class="main-text-terms mt-3 d-block">
          El tratamiento de tus datos siempre lo efectuaremos de conformidad a
          nuestra Política de Privacidad, la que te invitamos a revisar en el
          home de nuestra página web www.neatpagos.com
        </span>
      </div>
      <div
        class="my-4"
        style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
      ></div>
      <div>
        <span class="title-terms">
          <span class="pink-terms">SEPTIMO:</span> Condiciones Generales del
          Programa
        </span>

        <span class="main-text-terms mt-3 d-block">
          Solo se tratará tu información personal con una base de legalidad
          adecuada y en conformidad con la Política de Privacidad, que explica
          para qué tratamos y cómo protegemos los datos personales, y entrega
          toda la información necesaria para que conozcas y puedas ejercer tus
          derechos como titular de tus datos personales.
        </span>

        <span class="main-text-terms mt-3 d-block">
          Para cancelar la inscripción al Programa NEAT CLUB debes enviar un
          correo a la casilla neatclub@neatpagos.com y cumplir los requisitos de
          verificación de identidad dispuestos por NEAT para que podamos
          cancelar la inscripción.
        </span>

        <span class="main-text-terms mt-3 d-block">
          Las modificaciones a los TyC serán informadas en la página web
          www.neatpagos.com y a través de los demás medios que NEAT disponga,
          con al menos 5 días hábiles de anticipación a su entrada en vigencia.
        </span>

        <span class="main-text-terms mt-3 d-block">
          La participación en este Programa regulado en las Bases, implica, para
          cada Participante, el reconocimiento de su posibilidad de perder o
          ganar, y su aceptación de someterse a estas.
        </span>

        <span class="main-text-terms mt-3 d-block">
          La participación en este Programa implica la total aceptación de sus
          Bases y sus eventuales modificaciones. El incumplimiento de cualquiera
          de los términos y condiciones de estas Bases, provocará la terminación
          automática de la participación de la persona en este Programa.
        </span>

        <span class="main-text-terms mt-3 d-block">
          En consecuencia, por la sola circunstancia de participar en este
          Programa, se entenderá que el Participante conoce, comprende y acepta
          completamente las presentes Bases y todas y cada una de las normas,
          reglas, términos y condiciones relativas a su participación, sin que
          por motivo alguno le esté permitido reclamar posteriormente su
          desconocimiento.
        </span>

        <span class="main-text-terms mt-3 d-block">
          En caso que NEAT caiga en insolvencia financiera, se anularán e
          inhabilitarán todos las posibilidades de acumulación y canje de NEAT
          POINTS.
        </span>
      </div>
    </div>
  </div>
  <div class="footer" style="background-color: white;">
    <div
      style="width: 100%; height: 1px; background: #D6E1FC; margin: auto;"
    ></div>
    <div class="p-4 d-flex">
      <button
        class="neat-btn neat-btn__secondary w-100 mr-2"
        (click)="close(true)"
      >
        <span>
          No acepto
        </span>
      </button>
      <button
        class="neat-btn neat-btn__primary w-100 ml-2"
        (click)="saveTermsAnswer()"
      >
        <span>
          Acepto
        </span>
      </button>
    </div>
  </div>
</div>
