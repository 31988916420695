import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-entities-import-servipag-modal',
  templateUrl: './entities-import-servipag-modal.component.html',
  styleUrls: ['./entities-selection-onboarding.component.scss']
})
export class EntitiesImportServipagModalComponent implements OnInit, OnDestroy {
  progress: number = 0;
  private subscription: Subscription | undefined;

  constructor(
    public dialogRef: MatDialogRef<EntitiesImportServipagModalComponent>
  ) {}
  ngOnInit(): void {
    this.startProgressBar();
  }
  startProgressBar(): void {
    const totalTimeInMs = 5000;
    const intervalTimeInMs = 1000;
    const totalSteps = totalTimeInMs / intervalTimeInMs;
    const increment = 100 / totalSteps;

    this.subscription = interval(intervalTimeInMs).subscribe(() => {
      this.progress += increment;
      if (this.progress >= 100) {
        this.progress = 100;
        this.subscription?.unsubscribe();
        this.dialogRef.close();
      }
    });
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
