<app-loading-screen
  [showLoader]="showNewLoader"
  [textColor]="'rgba(4, 108, 235, 1)'"
  [loadingText]="'Validando tarjeta ...'"
></app-loading-screen>
<app-loading [showLoader]="showLoader"></app-loading>
<div data-hj-suppress id="payment-method-selection" class="boxes">
  <div
    id="payment-method-selection-header"
    class="px-4 py-3 header"
    [ngClass]="!errorMode && !successMode ? 'border-bottom' : ''"
  >
    <div
      class="d-flex align-items-center"
      [ngClass]="
        !errorMode && !successMode
          ? 'justify-content-between'
          : 'justify-content-end'
      "
    >
      <div
        *ngIf="!errorMode && !successMode"
        class="d-flex justify-content-center align-items-center"
      >
        <span class="modal-title">
          Agregar método de pago
        </span>
      </div>
      <span
        class="close-btn"
        style="z-index: 23131231232113 !important;"
        (click)="closeBtn()"
        >&times;</span
      >
    </div>
  </div>
  <div
    *ngIf="successMode"
    class="px-4 pt-4 pb-5 content d-flex align-items-center"
  >
    <div
      class="w-100 d-flex flex-column align-items-center justify-content-center"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="71"
          viewBox="0 0 72 71"
          fill="none"
        >
          <path
            d="M36 70C55.0538 70 70.5 54.5538 70.5 35.5C70.5 16.4462 55.0538 1 36 1C16.9462 1 1.5 16.4462 1.5 35.5C1.5 54.5538 16.9462 70 36 70Z"
            stroke="#5BD382"
            stroke-width="2"
          />
          <path
            d="M29.5044 43.4507L22.0414 35.9877L19.5 38.5112L29.5044 48.5156L50.9809 27.0391L48.4575 24.5156L29.5044 43.4507Z"
            fill="#5BD382"
          />
        </svg>
      </div>
      <span class="msg-title mt-2">
        Tarjeta agregada
      </span>
      <span class="msg-content mt-2">
        Ya puedes usarla para pagar tus cuentas.
      </span>
    </div>
  </div>
  <div
    *ngIf="errorMode"
    class="px-4 pt-4 pb-5 content d-flex align-items-center"
  >
    <div
      class="w-100 d-flex flex-column align-items-center justify-content-center"
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="72"
          height="71"
          viewBox="0 0 72 71"
          fill="none"
        >
          <path
            d="M36 70C55.0538 70 70.5 54.5538 70.5 35.5C70.5 16.4462 55.0538 1 36 1C16.9462 1 1.5 16.4462 1.5 35.5C1.5 54.5538 16.9462 70 36 70Z"
            stroke="#F90404"
            stroke-width="2"
          />
          <path
            d="M46.6231 27.2031L37.3262 36.5L46.6231 45.7969L44.2793 48.1406L34.9825 38.8438L25.6856 48.1406L23.3418 45.7969L32.6387 36.5L23.3418 27.2031L25.6856 24.8594L34.9825 34.1562L44.2793 24.8594L46.6231 27.2031Z"
            fill="#F90404"
          />
        </svg>
      </div>
      <span class="msg-title mt-2">
        No pudimos agregar tu tarjeta
      </span>
      <span class="msg-content mt-2">
        Inténtalo nuevamente más tarde. Si el problema persiste, escríbenos al
        chat.
      </span>
    </div>
  </div>
  <div *ngIf="!errorMode && !successMode" class="px-4 py-4 content">
    <div *ngIf="errorMsg" class="error-message mb-4">
      <span class="d-block" style="font-weight: 700 !important;"
        >No pudimos agregar tu tarjeta</span
      >
      <span>Verifica los datos de tu tarjeta y reinténtalo.</span>
    </div>
    <form [formGroup]="kushkiForm">
      <div>
        <!-- <span class="mb-2">Tarjeta de crédito recomendada: 5451951574925480</span> -->
        <mat-form-field
          class="w-100"
          outline="border"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Número de tarjeta</mat-label>
          <input
            matInput
            id="ccnum"
            formControlName="number"
            mask="0000 0000 0000 0000"
            name="number"
            [errorStateMatcher]="matcher"
            placeholder=""
            autocomplete="off"
          />
          <mat-error *ngIf="kushkiForm.controls['number'].hasError('required')"
            >El número de la tarjeta es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
        <mat-form-field
          class="w-50 pr-2"
          outline="border"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Fecha de expiración</mat-label>
          <input
            matInput
            id="expiration"
            type="text"
            formControlName="expiry_date"
            mask="M0/00"
            [leadZeroDateTime]="true"
            name="expiry_date"
            [errorStateMatcher]="matcher"
            placeholder="MM/AA"
            autocomplete="off"
          />
          <mat-error *ngIf="kushkiForm.controls['number'].hasError('required')"
            >La fecha de expiración es <strong>requerida</strong>.</mat-error
          >
        </mat-form-field>
        <mat-form-field
          class="w-50 pl-2"
          outline="border"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>CVV</mat-label>
          <input
            matInput
            id="cvc"
            mask="000"
            [type]="hide ? 'password' : 'text'"
            formControlName="cvc"
            name="cvc"
            [errorStateMatcher]="matcher"
            placeholder="***"
            required
          />
          <button
            type="button"
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
            [style.color]="!hide ? '#3369F0' : '#CCCCCC'"
          >
            <mat-icon>{{ !hide ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
          <mat-error *ngIf="kushkiForm.controls['cvc'].hasError('required')"
            >El CVV es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
        <mat-form-field
          class="w-100"
          outline="border"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Nombre del titular</mat-label>
          <input
            matInput
            id="ccname"
            formControlName="name"
            name="name"
            [errorStateMatcher]="matcher"
            placeholder=""
          />
          <mat-error *ngIf="kushkiForm.controls['name'].hasError('required')"
            >El nombre del titular es <strong>requerido</strong>.</mat-error
          >
        </mat-form-field>
      </div>
    </form>
    <div class="info-box">
      <span>
        Validaremos tu tarjeta cobrando $50, los cuales serán reversados en un
        periodo de 24 hrs.
      </span>
    </div>
    <div class="mt-3 d-flex align-items-center justify-content-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="14"
        viewBox="0 0 12 14"
        fill="none"
      >
        <path
          d="M10.0077 4.66667H9.34098V3.33333C9.34098 1.49333 7.84765 0 6.00765 0C4.16765 0 2.67432 1.49333 2.67432 3.33333V4.66667H2.00765C1.27432 4.66667 0.674316 5.26667 0.674316 6V12.6667C0.674316 13.4 1.27432 14 2.00765 14H10.0077C10.741 14 11.341 13.4 11.341 12.6667V6C11.341 5.26667 10.741 4.66667 10.0077 4.66667ZM4.00765 3.33333C4.00765 2.22667 4.90098 1.33333 6.00765 1.33333C7.11432 1.33333 8.00765 2.22667 8.00765 3.33333V4.66667H4.00765V3.33333ZM10.0077 12.6667H2.00765V6H10.0077V12.6667ZM6.00765 10.6667C6.74098 10.6667 7.34098 10.0667 7.34098 9.33333C7.34098 8.6 6.74098 8 6.00765 8C5.27432 8 4.67432 8.6 4.67432 9.33333C4.67432 10.0667 5.27432 10.6667 6.00765 10.6667Z"
          fill="#2F2F2F"
        />
      </svg>
      <span
        class="ml-2"
        style="color: #2F2F2F;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;"
      >
        Tarjeta procesada por
      </span>
      <img
        src="assets/img/providers/new-kushki-logo.svg"
        class="img-fluid img-entity ml-1"
      />
    </div>
  </div>
  <div
    id="payment-method-selection-bottom"
    class="w-100 px-4 bottom-box footer"
  >
    <form class="row align-items-center">
      <div class="col-12 my-3">
        <button
          *ngIf="!errorMode && !successMode"
          [disableRipple]="true"
          type="submit"
          class="w-100 payment-btn mb-3 mb-md-0"
          style="width: fit-content !important; margin: auto !important;"
          (click)="requestToken()"
          mat-raised-button
        >
          <span class="px-3">Agregar</span>
        </button>
        <button
          *ngIf="errorMode"
          [disableRipple]="true"
          type="submit"
          class="w-100 payment-btn mb-3 mb-md-0"
          style="width: fit-content !important; margin: auto !important;"
          (click)="resetStatus()"
          mat-raised-button
        >
          <span class="px-3">Reintentar</span>
        </button>
        <button
          *ngIf="successMode"
          [disableRipple]="true"
          type="submit"
          class="w-100 payment-btn mb-3 mb-md-0"
          style="width: fit-content !important; margin: auto !important;"
          (click)="closeBtn()"
          mat-raised-button
        >
          <span class="px-3">Ir a pagar</span>
        </button>
      </div>
    </form>
  </div>
</div>
