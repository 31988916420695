<div>
  <div class="mb-3 d-flex justify-between border-bottom">
    <span
      class="new-text-secondary font-weight-bold w-100 mb-3"
      style="font-size: 16px;"
      >Datos del destinatario copiados</span
    >
    <span (click)="closeModal(false)"
      ><mat-icon class="c-pointer" style="color: #A1A5C5">close</mat-icon></span
    >
  </div>
  <div class="d-flex flex-column" style="gap: 12px">
    <div class="border-bottom" *ngIf="userInfo.rut">
      <p class="new-text-gray mb-0" style="font-weight: 600;">RUT:</p>
      <p class="new-text-dark font-weight-bold" style="margin-bottom: 12px;">
        {{userInfo.rut}}
      </p>
    </div>
    <div class="border-bottom" *ngIf="userInfo.name">
      <p class="new-text-gray mb-0" style="font-weight: 600;">Nombre:</p>
      <p class="new-text-dark font-weight-bold" style="margin-bottom: 12px;">
        {{userInfo.name}}
      </p>
    </div>
    <div class="border-bottom" *ngIf="userInfo.bankName">
      <p class="new-text-gray mb-0" style="font-weight: 600;">Banco:</p>
      <p class="new-text-dark font-weight-bold" style="margin-bottom: 12px;">
        {{userInfo.bankName}}
      </p>
    </div>
    <div class="border-bottom" *ngIf="userInfo.bankAccountType">
      <p class="new-text-gray mb-0" style="font-weight: 600;">
        Tipo de cuenta:
      </p>
      <p class="new-text-dark font-weight-bold" style="margin-bottom: 12px;">
        {{bankAccountType[userInfo.bankAccountType]}}
      </p>
    </div>
    <div class="border-bottom" *ngIf="userInfo.bankAccountNumber">
      <p class="new-text-gray mb-0" style="font-weight: 600;">Nº de cuenta:</p>
      <p class="new-text-dark font-weight-bold" style="margin-bottom: 12px;">
        {{userInfo.bankAccountNumber}}
      </p>
    </div>
    <div class="border-bottom" *ngIf="userInfo.email">
      <p class="new-text-gray mb-0" style="font-weight: 600;">Email:</p>
      <p class="new-text-dark font-weight-bold" style="margin-bottom: 12px;">
        {{userInfo.email}}
      </p>
    </div>
  </div>
  <div class="d-flex align-items-center justify-content-between mt-3">
    <button (click)="closeModal(false)" class="neat-btn neat-btn__secondary">
      Cancelar
    </button>
    <button (click)="closeModal(true)" class="neat-btn neat-btn__primary">
      Pegar
    </button>
  </div>
</div>
