import firebase from 'firebase/compat/app';
import {
  ENeatValidProviders,
  EntityTypeCreation,
  INeatInformation,
  IPayment,
  IPaymentOption
} from 'neat-lib';

import { IPaymentHistory } from '../interfaces/payments.interface';
import {
  PaymentStatus,
  ProductNumber,
  EntityType,
  PayingAccountUnit,
  EntityTypeLocale
} from '../shared/enums/enums.enum';

export abstract class Payment implements IPayment {
  public expenseType?: string;
  public payingBillNumber?: string;
  public serviceDescription?: string;
  public companyActivity?: string;
  public receiptId?: string;
  public incompleteSchema?: boolean;
  public creationType?: EntityTypeCreation;
  public isExpressPayment?: boolean;
  public paymentOption?: IPaymentOption;
  public invoiceId?: string;
  public commissionToRetain?: number;
  public appliedCommission?: number;
  public sensitiveData?: any;
  public usedUF?: number;
  public isFromMultipay?: boolean;
  public specialDiscount?: number;
  public neatInformation?: INeatInformation;
  public neatDebtMultiEngine?: boolean;
  public neatCurrentPaymentProviderStatus?: string;
  public currentProviderDebt?: ENeatValidProviders;
  public isMultiPay?: boolean;
  public neatPointsData?: any;
  public entityType: EntityType;
  public paymentId: string;
  public providerPaymentId?: string;
  public providerRetriesCounter: number;
  public promoId: string;
  public productNumber: ProductNumber;
  public amount: number;
  public customData: any;
  public payedAmount: number;
  public entityTypeLocale?: EntityTypeLocale;
  public category?: string;
  public billNumber: number;
  public installments: number;
  public entityId: string;
  public propertyName: string;
  public kushkiSensitiveData: any;
  public currentPaymentStatus: PaymentStatus;
  public commission: number;
  public value: number;
  public originalValue?: number;
  public valueUF: number;
  public payingAccountUnit: PayingAccountUnit;
  public payer: any;
  public biller: any;
  public depositee: any;
  public voucherPath: string;
  public utilityData?: any;
  public createDate: number;
  public paymentDate: any;
  public xml2Date?: any;
  public transferDate: any;
  public transferError: string;
  public dateTransferedOutput?: string; // TODO
  public paymentProviderContext?: {
    xml1: string;
    xml2?: string;
    xml3?: string;
    xml4?: string;
    error?: any;
  };
  public paymentProviderIdentifier: string; // "servipag" u otro
  public paymentProviderPaymentId: string; // <Identificador>
  public paymentProviderParameters: {
    redirectTo: string;
  };
  public hasCMRPointsBonus?: boolean;
  public paymentStatus: IPaymentHistory;
  public paymentStatusLocale?: string;
  public saleType?: string;
  public adminEmail: string;
  public ownerEmail: string;
  public lesseeEmail: string;
  public paymentRequest: any;
  public receiverEmailNotification: boolean;
  public lastTransferComment: string;
  public payingMonth: string;

  public stringifiedPaymentDate: string;
  public stringifiedTransferDate: string;
  //Deprecated
  public rentValue?: number;

  deserialize(input: any): this {
    return Object.assign(this, input);
  }

  showPaymentStatusInfo(): string {
    switch (this.paymentStatus?.currentStatus || this.currentPaymentStatus) {
    case PaymentStatus.Created:
      return 'En progreso';
    case PaymentStatus.InProgress:
      return 'En progreso';
    case PaymentStatus.Done:
      return 'Realizado';
    case PaymentStatus.Error:
      return 'Rechazado';
    case PaymentStatus.UnexpectedError:
      return 'Rechazado';
    case PaymentStatus.Late:
      return 'Depósito con problemas';
    case PaymentStatus.Cancelled:
      return 'Anulado';
    default:
      return 'Error';
    }
  }

  showPaymentDate(): Date | string {
    if (this.paymentDate instanceof firebase.firestore.Timestamp) {
      return this.paymentDate.toDate();
    } else if (this.paymentDate instanceof Date) {
      return this.paymentDate;
    } else if (typeof this.paymentDate === 'string') {
      return this.paymentDate;
    }
  }

  showTransferDate(): Date | string {
    if (this.transferDate instanceof firebase.firestore.Timestamp) {
      return this.transferDate.toDate();
    } else if (this.transferDate instanceof Date) {
      return this.transferDate;
    } else if (typeof this.transferDate === 'string') {
      return this.transferDate;
    }
  }

  stringifyDates(): void {
    if (this.paymentDate) {
      if (this.paymentDate instanceof firebase.firestore.Timestamp) {
        this.paymentDate = this.paymentDate.toDate();
      } else if (typeof this.paymentDate === 'string') {
        this.paymentDate = new Date(this.paymentDate);
      }
      const paymentDate_day = this.paymentDate.getDate();
      const paymentDate_month = this.paymentDate.getMonth() + 1;
      const paymentDate_year = this.paymentDate.getFullYear();
      this.stringifiedPaymentDate = `${paymentDate_day}-${paymentDate_month}-${paymentDate_year}`;
    }

    if (this.transferDate) {
      if (this.transferDate instanceof firebase.firestore.Timestamp) {
        this.transferDate = this.transferDate.toDate();
      } else if (typeof this.transferDate === 'string') {
        this.transferDate = new Date(this.transferDate);
      }
      const transferDate_day = this.transferDate.getDate();
      const transferDate_month = this.transferDate.getMonth() + 1;
      const transferDate_year = this.transferDate.getFullYear();
      this.stringifiedTransferDate = `${transferDate_day}-${transferDate_month}-${transferDate_year}`;
    }
  }

  showPaymentTypeLocale(): EntityTypeLocale {
    return EntityTypeLocale[this.entityType];
  }

  fromPaymentRequest(): this {
    throw new Error('Method not implemented.');
  }
  setPaymentId(): this {
    throw new Error('Method not implemented.');
  }
  updatePayment(): this {
    throw new Error('Method not implemented.');
  }
}
