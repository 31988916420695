import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MyErrorStateMatcher } from '@shared/my-error-state-matcher/my-error-state-matcher.directive';
import { FormValidation } from 'app/landing/register/form-validation';

@Component({
  selector: 'app-reminder-modal',
  templateUrl: './reminder-modal.component.html',
  styleUrls: ['./reminder-modal.component.scss']
})
export class ReminderModalComponent {
  paymentForm: FormGroup;
  selectedDay: number;
  matcher = new MyErrorStateMatcher();
  entityType: string;
  constructor(
    public dialogRef: MatDialogRef<ReminderModalComponent>,
    private fb: FormBuilder,
    public formValidation: FormValidation,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.entityType = this.data.entityTypeLocale;
    this.paymentForm = this.fb.group({
      transferLimitDate: [
        '',
        [
          Validators.required,
          Validators.min(1),
          Validators.max(31),
          this.formValidation.onlyNumberValidator
        ]
      ]
    });
    if (this.data?.currentTransferDate) {
      this.paymentForm.patchValue(
        {
          transferLimitDate: this.data?.currentTransferDate
        },
        { emitEvent: true }
      );
    }
  }

  async updateEntityForAutomate(): Promise<void | boolean> {
    this.dialogRef.close({
      transferLimitDate: Number(this.paymentForm.get('transferLimitDate').value)
    });
  }

  closeBtn(): void {
    this.dialogRef.close();
  }
}
