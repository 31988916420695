import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

import { DatabaseService } from '@services/shared/database/database.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { SweetalertService } from '@services/shared/sweetalert/sweetalert.service';
import { UserService } from '@services/user/user.service';
import { DeleteUserReason, IconErrorModals } from '@shared/enums/enums.enum';
import { MyErrorStateMatcher } from '@shared/my-error-state-matcher/my-error-state-matcher.directive';

@Component({
  selector: 'app-delete-user-modal',
  templateUrl: './delete-user-modal.component.html',
  styleUrls: ['./delete-user-modal.component.scss']
})
export class DeleteUserModalComponent implements OnInit {
  deleteUserForm: FormGroup;
  reasons: Array<string> = Object.keys(DeleteUserReason);
  deleteUserReason = DeleteUserReason;
  matcher = new MyErrorStateMatcher();
  currentUserIdToken: string;
  showLoader: boolean;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DeleteUserModalComponent>,
    private fb: FormBuilder,
    private userService: UserService,
    private errorService: ErrorHandlerService,
    private db: DatabaseService,
    private swalService: SweetalertService
  ) {
    this.initFormGroup();
    this.detectSelectionChanges();
  }

  ngOnInit(): void {
    this.userService.idToken$.subscribe({
      next: idToken => (this.currentUserIdToken = idToken),
      error: error => {
        this.errorService.recordError(
          error,
          'delete-user-modal.component.ts',
          'this.userService.idToken$',
          'Error al ejecutar subscribe de idToken$'
        );
      }
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  initFormGroup(): void {
    this.deleteUserForm = this.fb.group({
      reason: ['', Validators.required],
      additionalComment: ['']
    });
  }

  async deleteUserAccount() {
    if (!this.deleteUserForm) {
      return false;
    }
    this.showLoader = true;
    const userRequest = { ...this.data, ...this.deleteUserForm.value };
    await this.db
      .firestoreNewDoc('surveysResponses', Object.assign({}, userRequest))
      .catch(err => {
        this.errorService.recordError(
          err,
          'delete-user-modal.ts',
          'this.db.firestoreNewDoc',
          'Error al crear doc para obtener motivos para eliminar cuenta'
        );
      });
    this.userService
      .deleteUserRequest(this.currentUserIdToken)
      .subscribe(res => {
        this.onNoClick();
        this.showLoader = false;
        if (res.status === 200) {
          this.successModal(
            'Confirmación',
            `La solicitud para eliminar tu cuenta se ha realizado exitosamente, 
        te hemos un enviado un correo de confirmación.`
          );
        } else {
          this.swalService.swalError2(
            'No se pudo eliminar tu cuenta',
            'Por favor inténtalo de nuevo más tarde. ',
            IconErrorModals.sadCloud,
            false,
            false
          );
        }
      });
  }

  detectSelectionChanges() {
    const additionalComment = this.deleteUserForm.get('additionalComment');
    this.deleteUserForm.get('reason').valueChanges.subscribe(value => {
      if (value === 'others') {
        additionalComment.setValidators(Validators.required);
        additionalComment.setErrors({ required: true });
      } else {
        additionalComment.setErrors(null);
        additionalComment.clearValidators();
        additionalComment.markAsPristine();
      }
    });
  }

  successModal(msgTitle: string, msgText: string) {
    Swal.fire({
      title: `${msgTitle}`,
      html: `${msgText}`,
      type: 'success',
      confirmButtonText: 'Aceptar',
      customClass: {
        confirmButton: 'mat-raised-button mat-button-base mat-primary'
      },
      buttonsStyling: false,
      onClose: async () => {
        await this.userService.logOut();
        window.location.reload();
      }
    });
  }
}
