import { isDevMode } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { holidays } from 'neat-lib/dist/Functions/Functions';

import { environment } from 'environments/environment';

import { BasicServiceType, EntityType, UserCategory } from './enums/enums.enum';
import {
  PaymentRequestDto,
  PaymentRequestTransbank
} from '../models/payment-request-dto.model';

/**
 * Clase con variables y métodos globales para usar a través de toda la aplicación
 */
export class AppGlobals {
  /** Función que setea los parámetros de los Template Literal
   *
   * Crea una copia del string y devuelve una función cuyos argumentos son los parámetros del Literal
   * @param strings El Template Literal
   * @param keys Array de parámetros del Template Literal
   * ```
   * $ let myString = templater`Mi amigo ${'friendName'}`;
   * $ friendObj = { friendName: 'alexis' }
   * $ console.log( myString(friendObj) );
   * $ "Mi amigo alexis"
   * ```
   */
  public static templater(strings, ...keys): any {
    return data => {
      const temp = strings.slice();
      keys.forEach((key, i) => {
        temp[i] = temp[i] + data[key];
      });
      return temp.join('');
    };
  }

  public static get sbifAPIEndpoint(): string {
    return 'https://api.sbif.cl/api-sbifv3/recursos_api/uf?apikey=a346adb52b39315bd15aee7c03fc9b705a9fcc12&formato=json';
  }
  /**
   * Servipag API ENDPOINT
   */
  public static get SPBaseURL(): string {
    return 'https://www.servipag.com/BotonPago/BotonPago/Pagar';
  }

  /**
   * BETA Endpoint
   */
  public static get neatBetaAPIEndpoint(): string {
    // mediosdepago:
    return 'https://mdp-gw-12bf6lp1.uc.gateway.dev/api/servipag/payments/rent';
    // gateway Beta:
    // return 'https://mediosdepagobeta.neat.cl/api/servipag/payments/rent';
  }

  /**
   * BETA multipay Endpoint
   */
  public static get neatBetaMultipayAPIEndpoint(): string {
    // mediosdepago:
    // return 'https://mediosdepago-dot-neatwebplatform.appspot.com/api/servipag/payments/rent';
    // gateway:
    return 'https://mdp-gw-12bf6lp1.uc.gateway.dev/api/servipag/payments/multi';
  }

  /**
   * Delete user beta Endpoint
   */
  public static get deleteUserBeta(): string {
    return 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/requestDeleteUser';
  }

  /**
   * Check Mail
   */
  public static get checkMail(): string {
    if (isDevMode()) {
      return 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/checkMail';
    } else {
      return 'https://us-central1-neatwebplatform.cloudfunctions.net/checkMail';
    }
  }

  public static get automaticRetryByUserBeta(): string {
    return 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/automaticRetryByUser';
  }

  public static get automaticRetryByUser(): string {
    return 'https://us-central1-neatwebplatform.cloudfunctions.net/automaticRetryByUser';
  }

  /**
   * Delete user Endpoint
   */
  public static get deleteUser(): string {
    return 'https://us-central1-neatwebplatform.cloudfunctions.net/requestDeleteUser';
  }

  public static get commissionRobotEndpoint(): string {
    return 'https://combot-config-gw-7l2iwbpq.uc.gateway.dev/combot/1.0.0/cb/commission/get';
  }

  public static get commissionRobotEndpointBeta(): string {
    return 'https://combot-config-gw-12bf6lp1.uc.gateway.dev/combot/1.0.0/cb/commission/get';
  }

  /**
   * Endpoint to POST Payment Request to recieve XML1
   */
  public static get neatAPIEndpoint(): string {
    // mediosdepago:
    // return 'https://mediosdepago-dot-neatwebplatform.appspot.com/api/servipag/payments/rent';
    // gateway:
    return 'https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/servipag/payments/rent';
  }

  /**
   * Endpoint multipay to POST Payment Request to recieve XML1
   */
  public static get neatMultipayAPIEndpoint(): string {
    // mediosdepago:
    // return 'https://mediosdepago-dot-neatwebplatform.appspot.com/api/servipag/payments/rent';
    // gateway:
    return 'https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/servipag/payments/multi';
  }

  public static get saveSurveyResponse(): string {
    if (isDevMode()) {
      return 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/saveSurveyResponse';
    } else {
      return 'https://us-central1-neatwebplatform.cloudfunctions.net/saveSurveyResponse';
    }
  }

  public static get getBasicServicesDebt(): string {
    if (isDevMode()) {
      return 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/getBasicServicesDebt';
    } else {
      return 'https://us-central1-neatwebplatform.cloudfunctions.net/getBasicServicesDebt';
    }
  }

  public static get getBasicServicesTAPIDebt(): string {
    if (isDevMode()) {
      return 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/neatGetBasicServicesDebts';
    } else {
      return 'https://us-central1-neatwebplatform.cloudfunctions.net/neatGetBasicServicesDebts';
    }
  }

  public static get getBasicServicesGroupedDebtsV2(): string {
    if (isDevMode()) {
      return 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/neatGetBasicServicesDebtsV2';
    } else {
      return 'https://us-central1-neatwebplatform.cloudfunctions.net/neatGetBasicServicesDebtsV2';
    }
  }

  public static get commissionRobotMultipayEndpoint(): string {
    if (isDevMode()) {
      return 'https://combot-config-gw-12bf6lp1.uc.gateway.dev/combot/1.0.0/cb/commission/multipay/get';
    } else {
      return 'https://combot-config-gw-7l2iwbpq.uc.gateway.dev/combot/1.0.0/cb/commission/multipay/get';
    }
  }

  /**
   * Payment Request Object to POST to MediosDePago and receive XML1
   */
  public static get paymentRequestServipag(): PaymentRequestDto {
    return new PaymentRequestDto('servipag');
  }

  public static get paymentRequestTransbank(): PaymentRequestTransbank {
    return new PaymentRequestTransbank('transbank');
  }

  public static get versionService(): string {
    if (environment.production) {
      return `https://us-central1-neatwebplatform.cloudfunctions.net/getVersion?product=web-app`;
    } else {
      return `https://us-central1-neatwebplatform-beta.cloudfunctions.net/getVersion?product=web-app`;
    }
  }

  public static get neatUrl(): string {
    if (!isDevMode()) {
      return `https://app.neatpagos.com/dashboard/`;
    } else {
      return `https://beta.app.neatpagos.com/dashboard/`;
    }
  }

  public static get betaResponseUrl(): string {
    // tslint:disable-next-line: max-line-length
    return `https://beta.app.neatpagos.com/dashboard/`;
  }

  public static get responseUrl(): string {
    // tslint:disable-next-line: max-line-length
    return `https://app.neatpagos.com/dashboard/`;
  }

  /**
   * Endpoint to POST Payment Request to recieve XML1
   */
  public static get paymentVoucherAPIEndpoint(): string {
    return 'https://us-central1-neatwebplatform.cloudfunctions.net/downloadVoucher';
  }

  /**
   * Endpoint to POST Payment Request to recieve XML1
   */
  public static get paymentVoucherBetaAPIEndpoint(): string {
    return 'https://us-central1-neatwebplatform-beta.cloudfunctions.net/downloadVoucher';
  }

  /**
   * Endpoint to POST Payment Request to recieve XML1
   */
  public static get transbankNeatAPIEndpoint(): string {
    if (isDevMode()) {
      return 'https://mdp-gw-12bf6lp1.uc.gateway.dev/api/transbank/payment';
    } else {
      return 'https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/transbank/payment';
    }
  }

  /**
   * Endpoint to POST Payment Request to recieve XML1
   */
  public static get multipayTransbankNeatAPIEndpoint(): string {
    if (isDevMode()) {
      return 'https://mdp-gw-12bf6lp1.uc.gateway.dev/api/transbank/multipay';
    } else {
      return 'https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/transbank/multipay';
    }
  }

  //Should be deprecated
  // New Property Invitations GET URL
  public static get NPInvitationEmailUrl(): any {
    // tslint:disable-next-line: max-line-length
    return AppGlobals.templater`https://script.google.com/macros/s/AKfycbyGeh3UZD8rLyYu9gd4NxrpK11FKGxq2fMAy2jtxdzZVH5Mybv5/exec?email=${'email'}&&name=${'nameInvited'}&&nameSender=${'nameSender'}&&propertyAddress=${'address'}`;
  }
  //Should be deprecated
  // Property Verification Notifications GET URL
  public static get VPNotificationEmailUrl(): any {
    // tslint:disable-next-line: max-line-length
    return AppGlobals.templater`https://script.google.com/macros/s/TODO/exec?email=${'email'}&&name=${'nameInvited'}&&nameSender=${'nameSender'}&&propertyAddress=${'address'}`;
  }

  /**
   *Endpoint to request the transbank token on beta
   */
  public static get neatBetaAPIEndpointCreditCardRegistration(): string {
    return 'https://mdp-gw-12bf6lp1.uc.gateway.dev/api/transbank/paymentMethod';
  }

  /**
   * Endpoint to request the transbank token
   */
  public static get neatAPIEndpointCreditCardRegistration(): string {
    return 'https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/transbank/paymentMethod';
  }

  /**
   * Endpoint to POST request the confirmation of credit card registration on beta
   */
  public static neatBetaAPIEndpointCreditCardConfirmation(
    paymentMethodId: string,
    token: string
  ): string {
    return `https://mdp-gw-12bf6lp1.uc.gateway.dev/api/transbank/paymentMethod/${paymentMethodId}/registration/${token}`;
  }

  /**
   * Endpoint to POST request the confirmation of credit card registration
   */
  public static neatAPIEndpointCreditCardConfirmation(
    paymentMethodId: string,
    token: string
  ): string {
    return `https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/transbank/paymentMethod/${paymentMethodId}/registration/${token}`;
  }

  /**
   * Endpoint to POST request the delete of a payment method on beta
   */
  public static neatBetaAPIEndpointDeleteCreditCard(
    paymentMethodId: string
  ): string {
    return `https://mdp-gw-12bf6lp1.uc.gateway.dev/api/transbank/paymentMethod/${paymentMethodId}`;
  }

  /**
   * Coldstarts endpoints
   */
  public static get coldStartPaymentEndpoint(): string {
    return 'https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/general/init';
  }

  /**
   * Coldstarts endpoints on beta
   */
  public static get betaColdStartPaymentEndpoint(): string {
    return 'https://mdp-gw-12bf6lp1.uc.gateway.dev/api/general/init';
  }

  /**
   * Endpoint to POST request the delete of a payment method on prod
   */
  public static neatAPIEndpointDeleteCreditCard(
    paymentMethodId: string
  ): string {
    return `https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/transbank/paymentMethod/${paymentMethodId}`;
  }

  /**
   * Endpoint to POST request the delete of a payment method on prod
   */
  public static neatAPIEndpointTransbankPaymentAnulation(
    paymentId: string,
    entityType: EntityType
  ): string {
    if (!isDevMode()) {
      // prod
      return `https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/transbank/payment/${paymentId}/${entityType}`;
    } else {
      // beta
      return `https://mdp-gw-12bf6lp1.uc.gateway.dev/api/transbank/payment/${paymentId}/${entityType}`;
    }
  }

  /**
   * People API Google
   */
  public static profileMeEndpoint(apiKey: string): string {
    return `https://people.googleapis.com/v1/people/me?personFields=birthdays%2CphoneNumbers&key=${apiKey}`;
  }

  public static minNeatCost(entityType?: EntityType): number {
    return this.defineMinNeatCost(entityType);
  }

  /**
   * Mínimo valor posible de entidades pagables
   */
  public static defineMinNeatCost(entityType?: EntityType): number {
    if (
      entityType &&
      [BasicServiceType.basicService].includes(entityType as any)
    ) {
      return 1;
    } else {
      return 72;
    }
  }

  public static maxNeatCost(
    entityType: EntityType,
    isVerified: boolean
  ): number {
    return this.defineMaxNeatCost(entityType, isVerified);
  }

  public static defineMaxNeatCost(
    entityType: EntityType,
    isVerified: boolean
  ): number {
    if (
      isVerified &&
      [
        EntityType.others,
        EntityType.mortgageFoot,
        EntityType.socialClub,
        EntityType.rent,
        EntityType.sportClub
      ].includes(entityType)
    ) {
      return 10000000;
    } else if ([BasicServiceType.basicService].includes(entityType as any)) {
      return 25000000;
    } else {
      return 3000000;
    }
  }

  /**
   * Convierte fecha UTC a fecha Local desde donde se esté ejecutando el navegador
   * @param date
   */
  public static convertUTCToLocalDate(date) {
    const newDate = new Date(
      date.getTime() + date.getTimezoneOffset() * 60 * 1000
    );
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();
    newDate.setHours(hours - offset);
    return newDate;
  }

  /**
   * Genera string de largo {{length}} de 62 posibles caracteres alfanuméricos
   * @param length - Largo del string
   */
  public static makeRandomCode(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  public static getValidnessOverFormControls(form: FormGroup) {
    for (const control of Object.keys(form.controls)) {
      console.log(
        `control: ${control}. value ${form.get(control).value}. valid? ${
          form.get(control).valid
        }`
      );
    }
  }

  /**
   * Array con nombres de días de la semana.
   * ```
   * $ AppGlobals.daysOfTheWeek[0]
   * $ "Lunes"
   * ```
   */
  public static get daysOfTheWeek(): string[] {
    return [
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
      'Domingo'
    ];
  }

  /**
   * Array ordenado de nombres de meses, con índice 0-11.
   * ```
   * $ AppGlobals.monthNames[3]
   * $ "Abril"
   * ```
   */
  public static get monthNames(): string[] {
    return [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre'
    ];
  }

  public static get lastDaysOfMonths(): number[] {
    const isLeapYear = new Date().getFullYear() % 4 === 0;
    const daysInMonth = [
      31,
      isLeapYear ? 29 : 28,
      31,
      30,
      31,
      30,
      31,
      31,
      30,
      31,
      30,
      31
    ];
    return daysInMonth;
  }

  /**
   * Array de años y meses e formato YYYYMM, desde 209912 (1er valor) hasta 200001 (último)
   */
  public static get yearsMonths(): string[] {
    const yearsMonths = [];
    for (let year = 2000; year < 2100; year++) {
      for (let month = 1; month <= 12; month++) {
        month < 10
          ? yearsMonths.push(`${year}0${month}`)
          : yearsMonths.push(`${year}${month}`);
      }
    }
    return yearsMonths.sort().reverse();
  }

  /**
   * Nombre completo de mes actual.
   * ```
   * $ AppGlobals.nowMonthName
   * $ 'Junio'
   * ```
   */
  public static get nowMonthName(): string {
    const tomonth = this.yyyymmdd.slice(4, -2);
    const monthNumber = Number(tomonth) - 1;
    return this.monthNames[monthNumber];
  }

  /**
   * Fecha actual en formato YYYYMMDD. Ej. AppGlobals.yyyymmdd = '20190619'
   */
  public static get yyyymmdd() {
    const x = new Date();
    const y = x.getFullYear().toString();
    let m = (x.getMonth() + 1).toString();
    let d = x.getDate().toString();
    d.length == 1 && (d = `0${d}`);
    m.length == 1 && (m = `0${m}`);
    const yyyymmdd = y + m + d;
    return yyyymmdd;
  }

  /**
   * Devuelve array de fechas entre 2 fechas
   * @param {Date} startDate - Primera fecha del array
   * @param {Date} endDate - Última fecha del array
   * ```
   * $ getDates(new Date(2013,10,22), new Date(2013,11,25));
   * $ [1970-01-01T00:00:00.001Z, ...]
   * ```
   */
  public static getDates(startDate, endDate): Array<Date> {
    const dates = [];
    let currentDate = startDate;
    const addDays = function(days) {
      const date = new Date(this.valueOf());
      date.setDate(date.getDate() + days);
      return date;
    };
    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays.call(currentDate, 1);
    }
    return dates;
  }

  /**
   * Obtiene la fecha mínima de transferencia,
   * calculada como hoy(Santiago) + 3díasHábiles
   * @TODO futuro: que dependa del PlanDay
   */
  public static transferLimitDateFromToday(): Date {
    const now = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
    );
    const actualHour: number = Number(
      String(now.getHours()) +
        String((now.getMinutes() < 10 ? '0' : '') + now.getMinutes())
    );
    let feeDays: number;
    const tempDate = new Date(now.getTime());
    const isTransferDateHoliday = this.holidaysTimes.some(
      holiday => holiday === tempDate.getTime()
    );
    if (
      actualHour < 1330 &&
      now.getDay() !== 0 &&
      now.getDay() !== 6 &&
      !isTransferDateHoliday
    ) {
      feeDays = 2;
    } else {
      feeDays = 3;
    }
    // Suma uno a los feeDays si es que es un día no laboral
    for (let i = 0; i < feeDays; i++) {
      tempDate.setDate(tempDate.getDate() + 1);
      tempDate.setHours(0, 0, 0, 0);
      const isTransferDateHoliday = this.holidaysTimes.some(
        holiday => holiday === tempDate.getTime()
      );
      if (
        tempDate.getDay() === 0 ||
        tempDate.getDay() === 6 ||
        isTransferDateHoliday
      ) {
        feeDays++;
      }
    }
    const transferLimitDate = new Date(now.getTime() + feeDays * 86400000);
    return transferLimitDate;
  }

  /**
   * Obtiene la fecha mínima de transferencia,
   * calculada como hoy(Santiago) + 3díasHábiles
   * @TODO futuro: que dependa del PlanDay
   */
  public static transferLimitDateFromLateToday(): Date {
    const now = new Date(
      new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
    );
    let feeDays: number;
    const tempDate = new Date(now.getTime());
    feeDays = 3;
    // Suma uno a los feeDays si es que es un día no laboral
    for (let i = 0; i < feeDays; i++) {
      tempDate.setDate(tempDate.getDate() + 1);
      tempDate.setHours(0, 0, 0, 0);
      const isTransferDateHoliday = this.holidaysTimes.some(
        holiday => holiday === tempDate.getTime()
      );
      if (
        tempDate.getDay() === 0 ||
        tempDate.getDay() === 6 ||
        isTransferDateHoliday
      ) {
        feeDays++;
      }
    }
    const transferLimitDate = new Date(now.getTime() + feeDays * 86400000);
    return transferLimitDate;
  }

  /**
   * Pago automático: Obtengo el día de pago que selecciona el usuario
   * y se le restan 48 horas hábiles para definir el día que se realizará
   * el cargo
   */
  public static transferLimitDateFromDate(stringDate: string): Date {
    let feeDays: number;
    feeDays = -2;
    const now = new Date(
      new Date(stringDate).toLocaleString('en-US', {
        timeZone: 'America/Santiago'
      })
    );
    const isNowHoliday = this.holidaysTimes.some(
      holiday => holiday === now.getTime()
    );
    if (now.getDay() === 6 || now.getDay() === 0 || isNowHoliday) {
      feeDays--;
    }
    const tempDate = new Date(now.getTime());
    // Resto uno a los feeDays si es que es un día no laboral
    for (let i = 0; i > feeDays; i--) {
      tempDate.setDate(tempDate.getDate() - 1);
      tempDate.setHours(0, 0, 0, 0);
      const isTransferDateHoliday = this.holidaysTimes.some(
        holiday => holiday === tempDate.getTime()
      );
      if (
        tempDate.getDay() === 0 ||
        tempDate.getDay() === 6 ||
        isTransferDateHoliday
      ) {
        feeDays--;
      }
    }
    now.setHours(now.getHours() + 8);
    const transferLimitDate = new Date(now.getTime() + feeDays * 86400000);
    return transferLimitDate;
  }

  /**
   * Pago automático: Obtengo el día de pago que selecciona el usuario
   * y se le restan 48 horas hábiles para definir el día que se realizará
   * el cargo
   */
  public static transferLimitDateFromDateCustomEntity(
    stringDate: string
  ): Date {
    const feeDays = 0;
    const now = new Date(
      new Date(stringDate).toLocaleString('en-US', {
        timeZone: 'America/Santiago'
      })
    );
    const tempDate = new Date(now.getTime());
    tempDate.setDate(tempDate.getDate());
    tempDate.setHours(0, 0, 0, 0);
    now.setHours(now.getHours() + 8);
    const transferLimitDate = new Date(now.getTime() + feeDays * 86400000);
    return transferLimitDate;
  }

  public static oneDayBeforeFromDate(transferDate: Date): Date {
    let feeDays: number;
    feeDays = -1;
    const now = transferDate;
    const tempDate = new Date(now.getTime());
    for (let i = 0; i > feeDays; i--) {
      tempDate.setDate(tempDate.getDate() - 1);
      tempDate.setHours(0, 0, 0, 0);
      const isTransferDateHoliday = this.holidaysTimes.some(
        holiday => holiday === tempDate.getTime()
      );
      if (
        tempDate.getDay() === 0 ||
        tempDate.getDay() === 6 ||
        isTransferDateHoliday
      ) {
        feeDays--;
      }
    }
    const transferLimitDate = new Date(now.getTime() + feeDays * 86400000);
    return transferLimitDate;
  }

  /**
   * La fecha en UTC
   * @returns {number} milisegundos hasta hoy en UTC
   */
  public static get now_utc(): number {
    const date = new Date();
    return Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
  }

  public static convertUFToPeso(uf: number, ufs: number): number {
    const value = Math.round(ufs * uf);
    // const value = ufs * uf;
    return value;
  }
  public static convertPesoToUF(uf: number, peso: number): number {
    // const value = Math.round(((peso / uf) * 10000) / 10000);
    const value = Number(((peso / uf) * 10000) / 10000);
    return Number(value);
  }

  /**
   * Minusculiza (?) primera letra del string pasado
   * @param {string} str1
   */
  public static uncapitalize(str1: string): string {
    return str1.charAt(0).toLowerCase() + str1.slice(1);
  }

  public static guessFirstAndLastNamesFrom(displayName: string | null): any {
    if (!displayName) {
      return { firstNames: '', lastNames: '' };
    }
    displayName = displayName.trim();
    const ary = displayName.split(' ');
    const l = ary.length;
    let firstNames: string;
    let lastNames: string | null;
    if (l == 0) {
      return { firstNames: '', lastNames: '' };
    } else if (l == 1) {
      firstNames = ary[0];
      lastNames = '';
    } else if (l == 2) {
      [firstNames, lastNames] = ary;
    } else {
      firstNames = ary.slice(0, l - 2).join(' ');
      lastNames = ary.slice(l - 2, l).join(' ');
    }
    return { firstNames, lastNames };
  }

  public static get invalidConstantRuts(): Array<string> {
    return [
      '1111111-1',
      '11111111-1',
      '2222222-2',
      '22222222-2',
      '3333333-3',
      '33333333-3',
      '4444444-4',
      '44444444-4',
      '5555555-5',
      '55555555-5',
      '6666666-6',
      '66666666-6',
      '7777777-7',
      '77777777-7',
      '8888888-8',
      '88888888-8',
      '9999999-9',
      '99999999-9'
    ];
  }

  public static get fidelityCategoriesV1(): Array<any> {
    return [
      {
        name: UserCategory.withoutCategory,
        category: 'Starter',
        monthsPaying: 0,
        totalMonthsForNextCategory: 3,
        monthsNeededForGetNextCategory: 3,
        expiration: 0,
        discount: null,
        userLevel: 1
      },
      {
        name: UserCategory.gold,
        category: 'Gold',
        monthsPaying: 3,
        totalMonthsForNextCategory: 6,
        monthsNeededForGetNextCategory: 3,
        expiration: 30,
        discount: 10,
        userLevel: 2
      },
      {
        category: 'Platinum',
        name: UserCategory.platinum,
        monthsPaying: 6,
        totalMonthsForNextCategory: 12,
        monthsNeededForGetNextCategory: 6,
        expiration: 60,
        discount: 10,
        userLevel: 3
      },
      {
        category: 'Black',
        name: UserCategory.black,
        monthsPaying: 12,
        totalMonthsForNextCategory: 18,
        monthsNeededForGetNextCategory: 6,
        expiration: 30,
        discount: 25,
        userLevel: 4
      },
      {
        category: 'Diamond',
        name: UserCategory.diamond,
        monthsPaying: 18,
        totalMonthsForNextCategory: 24,
        monthsNeededForGetNextCategory: 6,
        expiration: 30,
        discount: 40,
        userLevel: 5
      },
      {
        category: 'Infinite',
        name: UserCategory.infinite,
        monthsPaying: 24,
        totalMonthsForNextCategory: 30,
        monthsNeededForGetNextCategory: 6,
        expiration: 30,
        discount: 60,
        userLevel: 6
      },
      {
        category: 'Prime',
        name: UserCategory.prime,
        monthsPaying: 30,
        totalMonthsForNextCategory: 30,
        monthsNeededForGetNextCategory: 0,
        expiration: null,
        discount: null,
        userLevel: 7
      }
    ];
  }

  public static get fidelityCategoriesV2(): Array<any> {
    return [
      {
        name: UserCategory.withoutCategory,
        category: 'Starter',
        monthsPaying: 0,
        totalMonthsForNextCategory: 3,
        monthsNeededForGetNextCategory: 3,
        expiration: null,
        discount: null,
        textDiscount: null,
        userLevel: 1
      },
      {
        name: UserCategory.gold,
        category: 'Gold',
        monthsPaying: 3,
        totalMonthsForNextCategory: 6,
        monthsNeededForGetNextCategory: 3,
        expiration: null,
        discount: 5,
        textDiscount: '5% de descuento en comisión',
        userLevel: 2
      },
      {
        category: 'Platinum',
        name: UserCategory.platinum,
        monthsPaying: 6,
        totalMonthsForNextCategory: 12,
        monthsNeededForGetNextCategory: 6,
        expiration: null,
        discount: 7,
        textDiscount: '7% de descuento en comisión',
        userLevel: 3
      },
      {
        category: 'Black',
        name: UserCategory.black,
        monthsPaying: 12,
        totalMonthsForNextCategory: 18,
        monthsNeededForGetNextCategory: 6,
        expiration: null,
        discount: 10,
        textDiscount: '10% de descuento en comisión',
        userLevel: 4
      },
      {
        category: 'Diamond',
        name: UserCategory.diamond,
        monthsPaying: 18,
        totalMonthsForNextCategory: 24,
        monthsNeededForGetNextCategory: 6,
        expiration: null,
        discount: 13,
        textDiscount: '13% de descuento en comisión',
        userLevel: 5
      },
      {
        category: 'Infinite',
        name: UserCategory.infinite,
        monthsPaying: 24,
        totalMonthsForNextCategory: 30,
        monthsNeededForGetNextCategory: 6,
        expiration: null,
        discount: 16,
        textDiscount: '16% de descuento en comisión',
        userLevel: 6
      },
      {
        category: 'Prime',
        name: UserCategory.prime,
        monthsPaying: 30,
        totalMonthsForNextCategory: 30,
        monthsNeededForGetNextCategory: 0,
        expiration: null,
        discount: 20,
        textDiscount: '20% off permanente',
        userLevel: 7
      }
    ];
  }

  public static formatRut(rut: string): string {
    // Para que no haya error en consola
    if (!rut) {
      return '';
    }
    rut = String(rut);
    // Despejar Puntos
    let valor = rut.replace(/\./g, '');
    // Despejar Guión
    valor = valor.replace(/-/g, '');
    // Aislar Cuerpo y Dígito Verificador
    const cuerpo = valor.slice(0, -1);
    const dv = valor.slice(-1).toUpperCase();
    // Formatear RUN
    rut = `${cuerpo}-${dv}`;
    return rut;
  }

  public static get holidaysTimes(): Array<number> {
    return holidays().map(holiday => holiday.getTime());
  }

  public static get minCreditCardNumLength(): number {
    return 16;
  }

  public static get comunasDeChile(): Array<string> {
    let all = [];
    AppGlobals.RegionesYcomunas['regiones'].forEach(regYComs => {
      all = all.concat(regYComs['comunas']);
    });
    all.sort();
    return all;
  }

  public static get basicServicesList(): any {
    return {
      services: [
        {
          serviceName: 'water',
          enterprices: [
            'aguas_araucanía',
            'aguas_andinas',
            'aguas_antofagasta',
            'essbio',
            'esval',
            'smapa',
            'aguas_sepra',
            'nuevo_sur',
            'aguas_manquehue',
            'aguas_santiago_poniente',
            'essal',
            'aguas_san_pedro',
            'aguas_cordillera',
            'aguas_del_valle',
            'nueva_atacama',
            'aguas_los_guaicos',
            'aguas_del_altiplano',
            'aguas_magallanes'
          ]
        },
        {
          serviceName: 'gas',
          enterprices: [
            'gasco_en_linea',
            'abastible',
            'metrogas',
            'energas',
            'lipigas',
            'gasvalpo'
          ]
        },
        {
          serviceName: 'highway',
          enterprices: ['autopistas_unificadas_tag', 'valles_del_bio_bio_rut']
        },
        {
          serviceName: 'light',
          enterprices: [
            'enel_colina',
            'saesa',
            'frontel',
            'edelmag_en_linea',
            'luz_parral',
            'eepa',
            'luz_litoral',
            'luz_casablanca',
            'cec',
            'luz_linares',
            'edelaysen',
            'luz_osorno',
            'cge',
            'chilquinta',
            'enel',
            'codiner',
            'coopelan'
          ]
        },
        {
          serviceName: 'telecomunications',
          enterprices: [
            'claro',
            'movistar',
            'directv',
            'entel_sa',
            'entel_carrier',
            'vtr',
            'gtd',
            'mundo_pacífico',
            'telefónica_del_sur',
            'tu_ves',
            'cmet'
          ]
        },
        {
          serviceName: 'telephony',
          enterprices: [
            'wom',
            'movistar_celular_rut',
            'entel_celular',
            'claro_móvil'
          ]
        }
      ]
    };
  }

  public static get RegionesYcomunas(): any {
    return {
      regiones: [
        {
          NombreRegion: 'Región de Arica y Parinacota',
          comunas: ['Arica', 'Camarones', 'Putre', 'General Lagos']
        },
        {
          NombreRegion: 'Región de Tarapacá',
          comunas: [
            'Iquique',
            'Alto Hospicio',
            'Pozo Almonte',
            'Camiña',
            'Colchane',
            'Huara',
            'Pica'
          ]
        },
        {
          NombreRegion: 'Región de Antofagasta',
          comunas: [
            'Antofagasta',
            'Mejillones',
            'Sierra Gorda',
            'Taltal',
            'Calama',
            'Ollagüe',
            'San Pedro de Atacama',
            'Tocopilla',
            'María Elena'
          ]
        },
        {
          NombreRegion: 'Región de Atacama',
          comunas: [
            'Copiapó',
            'Caldera',
            'Tierra Amarilla',
            'Chañaral',
            'Diego de Almagro',
            'Vallenar',
            'Alto del Carmen',
            'Freirina',
            'Huasco'
          ]
        },
        {
          NombreRegion: 'Región de Coquimbo',
          comunas: [
            'La Serena',
            'Coquimbo',
            'Andacollo',
            'La Higuera',
            'Paiguano',
            'Vicuña',
            'Illapel',
            'Canela',
            'Los Vilos',
            'Salamanca',
            'Ovalle',
            'Combarbalá',
            'Monte Patria',
            'Punitaqui',
            'Río Hurtado'
          ]
        },
        {
          NombreRegion: 'Región de Valparaíso',
          comunas: [
            'Valparaíso',
            'Casablanca',
            'Concón',
            'Juan Fernández',
            'Puchuncaví',
            'Quintero',
            'Viña del Mar',
            'Isla de Pascua',
            'Los Andes',
            'Calle Larga',
            'Rinconada',
            'San Esteban',
            'La Ligua',
            'Cabildo',
            'Papudo',
            'Petorca',
            'Zapallar',
            'Quillota',
            'Calera',
            'Hijuelas',
            'La Cruz',
            'Nogales',
            'San Antonio',
            'Algarrobo',
            'Cartagena',
            'El Quisco',
            'El Tabo',
            'Santo Domingo',
            'San Felipe',
            'Catemu',
            'Llaillay',
            'Panquehue',
            'Putaendo',
            'Santa María',
            'Quilpué',
            'Limache',
            'Olmué',
            'Villa Alemana'
          ]
        },
        {
          NombreRegion: 'Región del Libertador Gral. Bernardo O’Higgins',
          comunas: [
            'Rancagua',
            'Codegua',
            'Coinco',
            'Coltauco',
            'Doñihue',
            'Graneros',
            'Las Cabras',
            'Machalí',
            'Malloa',
            'Mostazal',
            'Olivar',
            'Peumo',
            'Pichidegua',
            'Quinta de Tilcoco',
            'Rengo',
            'Requínoa',
            'San Vicente',
            'Pichilemu',
            'La Estrella',
            'Litueche',
            'Marchihue',
            'Navidad',
            'Paredones',
            'San Fernando',
            'Chépica',
            'Chimbarongo',
            'Lolol',
            'Nancagua',
            'Palmilla',
            'Peralillo',
            'Placilla',
            'Pumanque',
            'Santa Cruz'
          ]
        },
        {
          NombreRegion: 'Región del Maule',
          comunas: [
            'Talca',
            'Constitución',
            'Curepto',
            'Empedrado',
            'Maule',
            'Pelarco',
            'Pencahue',
            'Río Claro',
            'San Clemente',
            'San Rafael',
            'Cauquenes',
            'Chanco',
            'Pelluhue',
            'Curicó',
            'Hualañé',
            'Licantén',
            'Molina',
            'Rauco',
            'Romeral',
            'Sagrada Familia',
            'Teno',
            'Vichuquén',
            'Linares',
            'Colbún',
            'Longaví',
            'Parral',
            'Retiro',
            'San Javier',
            'Villa Alegre',
            'Yerbas Buenas'
          ]
        },
        {
          NombreRegion: 'Región del Biobío',
          comunas: [
            'Concepción',
            'Coronel',
            'Chiguayante',
            'Florida',
            'Hualqui',
            'Lota',
            'Penco',
            'San Pedro de la Paz',
            'Santa Juana',
            'Talcahuano',
            'Tomé',
            'Hualpén',
            'Lebu',
            'Arauco',
            'Cañete',
            'Contulmo',
            'Curanilahue',
            'Los Álamos',
            'Tirúa',
            'Los Ángeles',
            'Antuco',
            'Cabrero',
            'Laja',
            'Mulchén',
            'Nacimiento',
            'Negrete',
            'Quilaco',
            'Quilleco',
            'San Rosendo',
            'Santa Bárbara',
            'Tucapel',
            'Yumbel',
            'Alto Biobío',
            'Chillán',
            'Bulnes',
            'Cobquecura',
            'Coelemu',
            'Coihueco',
            'Chillán Viejo',
            'El Carmen',
            'Ninhue',
            'Ñiquén',
            'Pemuco',
            'Pinto',
            'Portezuelo',
            'Quillón',
            'Quirihue',
            'Ránquil',
            'San Carlos',
            'San Fabián',
            'San Ignacio',
            'San Nicolás',
            'Treguaco',
            'Yungay'
          ]
        },
        {
          NombreRegion: 'Región de la Araucanía',
          comunas: [
            'Temuco',
            'Carahue',
            'Cunco',
            'Curarrehue',
            'Freire',
            'Galvarino',
            'Gorbea',
            'Lautaro',
            'Loncoche',
            'Melipeuco',
            'Nueva Imperial',
            'Padre las Casas',
            'Perquenco',
            'Pitrufquén',
            'Pucón',
            'Saavedra',
            'Teodoro Schmidt',
            'Toltén',
            'Vilcún',
            'Villarrica',
            'Cholchol',
            'Angol',
            'Collipulli',
            'Curacautín',
            'Ercilla',
            'Lonquimay',
            'Los Sauces',
            'Lumaco',
            'Purén',
            'Renaico',
            'Traiguén',
            'Victoria'
          ]
        },
        {
          NombreRegion: 'Región de Los Ríos',
          comunas: [
            'Valdivia',
            'Corral',
            'Lanco',
            'Los Lagos',
            'Máfil',
            'Mariquina',
            'Paillaco',
            'Panguipulli',
            'La Unión',
            'Futrono',
            'Lago Ranco',
            'Río Bueno'
          ]
        },
        {
          NombreRegion: 'Región de Los Lagos',
          comunas: [
            'Puerto Montt',
            'Calbuco',
            'Cochamó',
            'Fresia',
            'Frutillar',
            'Los Muermos',
            'Llanquihue',
            'Maullín',
            'Puerto Varas',
            'Castro',
            'Ancud',
            'Chonchi',
            'Curaco de Vélez',
            'Dalcahue',
            'Puqueldón',
            'Queilén',
            'Quellón',
            'Quemchi',
            'Quinchao',
            'Osorno',
            'Puerto Octay',
            'Purranque',
            'Puyehue',
            'Río Negro',
            'San Juan de la Costa',
            'San Pablo',
            'Chaitén',
            'Futaleufú',
            'Hualaihué',
            'Palena'
          ]
        },
        {
          NombreRegion: 'Región Aisén del Gral. Carlos Ibáñez del Campo',
          comunas: [
            'Coyhaique',
            'Lago Verde',
            'Aisén',
            'Cisnes',
            'Guaitecas',
            'Cochrane',
            'O’Higgins',
            'Tortel',
            'Chile Chico',
            'Río Ibáñez'
          ]
        },
        {
          NombreRegion: 'Región de Magallanes y de la Antártica Chilena',
          comunas: [
            'Punta Arenas',
            'Laguna Blanca',
            'Río Verde',
            'San Gregorio',
            'Cabo de Hornos (Ex Navarino)',
            'Antártica',
            'Porvenir',
            'Primavera',
            'Timaukel',
            'Natales',
            'Torres del Paine'
          ]
        },
        {
          NombreRegion: 'Región Metropolitana de Santiago',
          comunas: [
            'Cerrillos',
            'Cerro Navia',
            'Conchalí',
            'El Bosque',
            'Estación Central',
            'Huechuraba',
            'Independencia',
            'La Cisterna',
            'La Florida',
            'La Granja',
            'La Pintana',
            'La Reina',
            'Las Condes',
            'Lo Barnechea',
            'Lo Espejo',
            'Lo Prado',
            'Macul',
            'Maipú',
            'Ñuñoa',
            'Pedro Aguirre Cerda',
            'Peñalolén',
            'Providencia',
            'Pudahuel',
            'Quilicura',
            'Quinta Normal',
            'Recoleta',
            'Renca',
            'Santiago',
            'San Joaquín',
            'San Miguel',
            'San Ramón',
            'Vitacura',
            'Puente Alto',
            'Pirque',
            'San José de Maipo',
            'Colina',
            'Lampa',
            'TilTil',
            'San Bernardo',
            'Buin',
            'Calera de Tango',
            'Paine',
            'Melipilla',
            'Alhué',
            'Curacaví',
            'María Pinto',
            'San Pedro',
            'Talagante',
            'El Monte',
            'Isla de Maipo',
            'Padre Hurtado',
            'Peñaflor'
          ]
        }
      ]
    };
  }

  /**
   * Create Security Pin
   */
  public static get createSecurityPinEndpoint(): string {
    if (!isDevMode()) {
      return 'https://nidentity-gw-7l2iwbpq.ue.gateway.dev/neat-identity/1.0.0/api/auth/pin';
    } else {
      return 'https://nidentity-gw-12bf6lp1.ue.gateway.dev/neat-identity/1.0.0/api/auth/pin';
    }
  }

  /**
   * Validate Security Pin
   */
  public static get validatePinEndpoint(): string {
    if (!isDevMode()) {
      return 'https://nidentity-gw-7l2iwbpq.ue.gateway.dev/neat-identity/1.0.0/api/auth/isPin';
    } else {
      return 'https://nidentity-gw-12bf6lp1.ue.gateway.dev/neat-identity/1.0.0/api/auth/isPin';
    }
  }

  public static get kushkiEnrollerAPIEndpoint(): string {
    if (isDevMode()) {
      return 'https://mdp-gw-12bf6lp1.uc.gateway.dev/api/kushki/paymentMethod';
    } else {
      return 'https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/kushki/paymentMethod';
    }
  }

  public static get kushkiPaymentAPIEndpoint(): string {
    if (isDevMode()) {
      return 'https://mdp-gw-12bf6lp1.uc.gateway.dev/api/kushki/payment';
    } else {
      return 'https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/kushki/payment';
    }
  }

  public static deleteKushkiCreditCardAPIEndpoint(
    paymentMethodId: string
  ): string {
    if (isDevMode()) {
      return `https://mdp-gw-12bf6lp1.uc.gateway.dev/api/kushki/paymentMethod/${paymentMethodId}`;
    } else {
      return `https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/kushki/paymentMethod/${paymentMethodId}`;
    }
  }

  public static anulatePaymentKushkiAPIEndpoint(
    paymentId: string,
    entityType: EntityType
  ): string {
    if (isDevMode()) {
      return `https://mdp-gw-12bf6lp1.uc.gateway.dev/api/kushki/payment/${paymentId}/${entityType}`;
    } else {
      return `https://mdp-gw-7l2iwbpq.uc.gateway.dev/api/kushki/payment/${paymentId}/${entityType}`;
    }
  }
  public static get masterCardPromoUtilityNumbers(): string[] {
    return [
      '1018',
      '1404',
      '1041',
      '1064',
      '1091',
      '1128',
      '1155',
      '1188',
      '1191',
      '1222',
      '1232',
      '1264',
      '1284',
      '1314',
      '1321',
      '1344',
      '1354',
      '1364',
      '1504',
      '1164',
      '4015',
      '4112',
      '4113',
      '4318',
      '4422',
      '4425',
      '4518',
      '2018',
      '2102',
      '2112',
      '2122',
      '2132',
      '2142',
      '2325',
      '2345',
      '2411',
      '2421',
      '2431',
      '2441',
      '2501',
      '2518',
      '2601',
      '2611',
      '2713',
      '3018',
      '3048',
      '3068',
      '3116',
      '3348',
      '3513',
      '3673',
      '3453',
      '3418',
      '3511',
      '3512',
      '3032',
      '3058',
      '3137',
      '3351',
      '3385',
      '3414',
      '3415',
      '3467',
      '3478',
      '3488',
      '3601',
      '3602',
      '3640',
      '3068',
      '3711',
      '3712',
      '3721',
      '3911',
      '3458',
      '3713',
      '3714',
      '3751',
      '3752',
      '3912',
      '6512',
      '6528',
      '6533',
      '6548',
      '6561',
      '6571',
      '6581',
      'NT-CL-G-00400'
    ];
  }
}
