import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppGuard implements CanActivate {
  constructor(private router: Router, private route: ActivatedRoute) {}
  canActivate() {
    const urlParams = new URLSearchParams(window.location.search);
    const isDeepLink = !!urlParams.get('deepLink');
    const isMobile = this.isMobileDevice();

    if (!isMobile || !isDeepLink) {
      return true;
    }

    const isStoreUrl = window.location.href.includes('get-app');
    if (isStoreUrl) {
      this.openStore();
    } else {
      const urlPath = window.location.pathname.match(/dashboard\/(.*)/);
      if (urlPath) {
        window.location.href = `neatapp://${urlPath[0]}`;
      } else {
        window.location.href = 'neatapp://';
      }
    }

    return true;
  }

  private isMobileDevice() {
    return /Mobile/.test(window.navigator.userAgent);
  }

  private openStore() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    if (/android/i.test(userAgent)) {
      window.location.href =
        'https://play.google.com/store/apps/details?id=com.neat.mobileappprod';
    } else if (
      /ipad|iphone|ipod/.test(userAgent) &&
      !Object.prototype.hasOwnProperty.call(window, 'MSStream')
    ) {
      window.location.href =
        'https://apps.apple.com/cl/app/neat-pagos/id1595103682';
    }
  }
}
