import { Component } from '@angular/core';
import { combineLatest } from 'rxjs';

import { LoaderScreenService } from '@services/loader-screen/loader-screen.service';

export enum Provider {
  NEAT = 'neat',
  GOOGLE = 'google',
  APPLE = 'apple'
}

export const cxrExternalProviderProps = [
  'blue',
  'Un momento por favor',
  'Estamos obteniendo la información de acceso',
] as const;

export const cxrInternalProviderProps = {
  type: 'blue',
  showLoaderText: 'Creando tu cuenta',
  loadingSubtitle: 'Ya estás un paso más cerca de pagar tus cuentas de manera inteligente',
  provider: Provider.NEAT
};

@Component({
  selector: 'app-loading-screen-global',
  templateUrl: './loading-screen-global.component.html',
  styleUrls: ['./loading-screen-global.component.scss']
})
export class GlobalLoadingScreenComponent {
  showLoader: boolean;
  textColor: string;
  secundaryColor: string;
  backgroundColor: string;
  loadingText: string;
  loadingSubtitle: string;
  provider: Provider;
  constructor(private loaderService: LoaderScreenService) {
    combineLatest([
      this.loaderService.getShowLoading$,
      this.loaderService.getLoadingText$,
      this.loaderService.getLoadingSubtitle$,
      this.loaderService.getTextColor$,
      this.loaderService.getSecundaryColor$,
      this.loaderService.getBackgroundColor$,
      this.loaderService.getProvider$
    ]).subscribe(
      ([
        showLoading,
        loadingText,
        loadingSubtitle,
        textColor,
        secundaryColor,
        backgroundColor,
        provider
      ]) => {
        this.showLoader = showLoading;
        this.loadingText = loadingText;
        this.loadingSubtitle = loadingSubtitle;
        this.textColor = textColor;
        this.secundaryColor = secundaryColor;
        this.backgroundColor = backgroundColor;
        this.provider = provider;
        if (showLoading) {
          const body = document.getElementsByTagName('body')[0];
          body.classList.add('cdk-global-scrollblock');
        } else {
          const body = document.getElementsByTagName('body')[0];
          body.classList.remove('cdk-global-scrollblock');
        }
      }
    );
  }
}
