<section id="promotion-modal" class="p-0 p-md-3">
  <div class="row">
    <div class="col-10">
      <div
        style="display: flex; flex-direction:column; justify-content: center; height: 100%;"
        *ngIf="selectedPromotion === 'null'"
      >
        <h4 class="body-title mb-2">Promociones</h4>
      </div>
    </div>
    <div class="col-2">
      <span class="close" (click)="onNoClick()"
        ><mat-icon class="c-pointer">close</mat-icon></span
      >
    </div>
    <div class="col-12 mb-3" *ngIf="selectedPromotion === 'null'">
      <div>
        <span class="secundary-text d-block mb-1"
          >1) Activa la promoción. (Neat Unique ya está activado)</span
        >
        <span class="secundary-text d-block mb-1"
          >2) Paga con la misma tarjeta con la que activaste el descuento.</span
        >
      </div>
    </div>
  </div>
  <app-promotion-cards-dynamic [margins]="false" [closeModal]="onNoClick.bind(this)"></app-promotion-cards-dynamic>
  <div class="row d-md-none" *ngIf="selectedPromotion === 'null'">
    <div class="col-12">
      <button
        class="prev-btn w-100 my-4"
        id="close-promotion-modal-btn"
        type="button"
        (click)="onNoClick()"
      >
        CERRAR
      </button>
    </div>
  </div>
</section>
