import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EmailHandlerService {
  actionHandlerParams: {
    mode: string;
    actionCode: string;
    continueUrl?: string;
    lang?: string | 'en';
  };
  constructor(private router: Router, private fireAuth: AngularFireAuth) {
    this.fireAuth.languageCode = new Promise(resolve => {
      resolve('es');
    });
    this.fireAuth.useDeviceLanguage();
    this.actionHandlerParams = { mode: '', actionCode: '' };
  }

  handleVerifyEmail(actionCode: string): Promise<void> {
    return this.fireAuth.applyActionCode(actionCode);
  }

  handleResetPassword(actionCode: string): Promise<string> {
    return this.fireAuth.verifyPasswordResetCode(actionCode);
  }
}
