import { Injectable } from '@angular/core';

import { User } from '../../../models/users/user.model';
import { CloudService } from '../../../services/cloud/cloud.service';

declare global {
  interface Window {
    Intercom(
      arg0: string,
      arg1?: {
        app_id?: string;
        user_id?: string;
        name?: string;
        email?: string;
        created_at?: number;
        user_type?: string;
        last_seen?: number;
        user_hash?: string;
      }
    );
  }
}
@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  constructor(private cloudFunctions: CloudService) {}
  initIntercom() {
    window.Intercom('boot', {
      app_id: 'kvqogpa7'
    });
  }

  async updateIntercomUser(
    user: Partial<User>,
    signUpDate: number,
    lastSignIn: number
  ) {
    try {
      const intercomUserHash = await this.cloudFunctions.getIntercomUserHash();
      window.Intercom('update', {
        user_id: user.id,
        name: user.displayName,
        email: user.email,
        created_at: signUpDate,
        user_type: user.userType,
        last_seen: lastSignIn,
        user_hash: intercomUserHash
      });
    } catch (error) {
      console.error('Error updating intercom user', error);
    }
  }
}
