/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import {
  CustomProvidersNames,
  PayingAccountUnit,
  SencillitoAutomaticPreferences
} from 'neat-lib/dist/Enums/Constants';
import {
  areInvoicesToBePaidValid,
  forceDebtToZero,
  isCustomProviderEnabledToBePaid
} from 'neat-lib/dist/Functions/Functions';
import {
  IBasicServiceConfiguration,
  IBasicServiceDebt,
  IFields,
  INeatCategories,
  INeatServices
} from 'neat-lib/dist/Interfaces/IData';
import {
  Observable,
  of,
  BehaviorSubject,
  Subject,
  combineLatest,
  Subscription
} from 'rxjs';
import { map, filter, catchError } from 'rxjs/operators';

import { PaymentsService } from '@services/payments/payments.service';
import { DialogService } from '@services/shared/dialog/dialog.service';
import { AppGlobals } from '@shared/constants';
import {
  MergeTypes,
  findDuplicateIds,
  isPreloadedRecentPayment,
  isRecentPayment,
  removeDuplicateEntities,
  sortByTransferLimitDate,
  sortNeatEntities,
  sortPreloadedEntities,
  utilityTypeToCategory,
  validateUpdatedDebts
} from '@shared/utils';
import { getImageUrl } from 'app/helpers/getImageUrlFromService';
import { BasicService } from 'app/models/abstract-basic-service.model';

import { Entity } from '../../models/abstract-entity.model';
import { Payment } from '../../models/abstract-payment.model';
import { BasicServiceFactory } from '../../models/basic-service-factory.model';
import { EntityFactory } from '../../models/entity-factory.model';
import { PaymentFactory } from '../../models/payment-factory.model';
import { User } from '../../models/users/user.model';
import {
  BasicServiceType,
  EntityType,
  PaymentStatus
} from '../../shared/enums/enums.enum';
import { ErrorHandlerService } from '../shared/error-handler/error-handler.service';

interface FeatureFlag {
  availability: 'none' | 'all' | 'whitelist';
  featureName: string;
  whitelistArray: string[];
}

interface IGetBasicServiceDebtRequest {
  utilityNumber?: string;
  clientNumber?: string;
  entityId?: string;
}
@Injectable({
  providedIn: 'root'
})
export class RentListService {
  private allEntities: Subject<Entity[]> = new BehaviorSubject<Entity[]>(null);
  private allBasicServices: Subject<BasicService[]> = new BehaviorSubject<
    BasicService[]
  >(null);
  private allUnifiedEntities: Subject<
    MergeTypes<BasicService, Entity>[]
  > = new BehaviorSubject<MergeTypes<BasicService, Entity>[]>(null);
  private allBasicServicesConfig: Subject<any> = new BehaviorSubject<any>(null);
  private allBasicServicesTAPI: Subject<any> = new BehaviorSubject<any>(null);
  private activeNeatServices: Subject<any> = new BehaviorSubject<any>(null);
  private neatCategoriesVisibles: Subject<
    INeatCategories[]
  > = new BehaviorSubject<INeatCategories[]>(null);
  private selectedEntity: Subject<Entity> = new BehaviorSubject<Entity>(null);
  private selectedNeatServiceCategory: Subject<string> = new BehaviorSubject<
    string
  >(null);
  private selectedUtilityNumber: Subject<string> = new BehaviorSubject<string>(
    null
  );
  public currentEntity: Entity;
  public currentEntityForConfig: MergeTypes<BasicService, Entity>;
  public selectedEntitiesForMultipay: MergeTypes<BasicService, Entity>[] = [];
  public selectedEntities: MergeTypes<BasicService, Entity>[] = [];
  public selectedPayment: Payment;
  public teamNeat = false;
  public selectedCategory: INeatCategories;
  public selectedNeatCategory: Subject<string> = new BehaviorSubject<string>(
    null
  );
  public rangeOfMonthsToLoadPayments: Subject<number> = new BehaviorSubject<
    number
  >(3);
  public monthsToLoad = {
    firstQuarter: 3,
    firstSemester: 6,
    firstYear: 12,
    allTime: 0
  };
  private allPayments: Subject<Payment[]> = new BehaviorSubject<Payment[]>(
    null
  );

  private allUnifiedPayments: Subject<Payment[]> = new BehaviorSubject<
    Payment[]
  >(null);

  private paymentChanges: Subject<number> = new BehaviorSubject<number>(null);

  private allBasicServicePayments: Subject<Payment[]> = new BehaviorSubject<
    Payment[]
  >(null);

  private customTopbarRoutName: Subject<any> = new BehaviorSubject<any>(null);

  private updatingDebts: Subject<
    IGetBasicServiceDebtRequest[]
  > = new BehaviorSubject<any[]>([null]);

  private loadingDebts: BehaviorSubject<string[]> = new BehaviorSubject<
    string[]
  >([]);

  private actionSource = new Subject<any>();
  action$ = this.actionSource.asObservable();
  public neatClubLastRoute: string;
  public neatClubCustomLastRoute: string;
  entityFactory: EntityFactory;
  basicServiceFactory: BasicServiceFactory;
  paymentFactory: PaymentFactory;
  selectedEntitySubs: Subscription;
  public availableDays: Array<any> = [];
  selectedNav: number;
  firstHomeLoad = false;
  public showNeatEntities = false;
  public showPreloadedEntities = false;
  public neatAutoAllowed = false;
  constructor(
    private afs: AngularFirestore,
    private errorService: ErrorHandlerService,
    private fireAnalytics: AngularFireAnalytics,
    private router: Router,
    private dialogService: DialogService,
    private paymentsService: PaymentsService
  ) {
    this.entityFactory = new EntityFactory();
    this.basicServiceFactory = new BasicServiceFactory();
    this.paymentFactory = new PaymentFactory();
  }

  get allEntities$(): Observable<Entity[]> {
    return this.allEntities.asObservable().pipe(filter(entities => !!entities));
  }

  get allBasicServices$(): Observable<BasicService[]> {
    return this.allBasicServices
      .asObservable()
      .pipe(filter(basicServices => !!basicServices));
  }

  get allUnifiedEntities$(): Observable<MergeTypes<BasicService, Entity>[]> {
    return this.allUnifiedEntities
      .asObservable()
      .pipe(filter(unifiedEntities => !!unifiedEntities));
  }

  get selectedNeatServiceCategory$(): Observable<string> {
    return this.selectedNeatServiceCategory
      .asObservable()
      .pipe(filter(val => !!val));
  }

  get selectedCategory$(): Observable<string> {
    return this.selectedNeatCategory.asObservable().pipe(filter(val => !!val));
  }

  get selectedUtilityNumber$(): Observable<string> {
    return this.selectedUtilityNumber.asObservable().pipe(filter(val => !!val));
  }

  get allBasicServicesConfig$(): Observable<any> {
    return this.allBasicServicesConfig
      .asObservable()
      .pipe(filter(basicServices => !!basicServices));
  }

  get allBasicServicesTAPI$(): Observable<any> {
    return this.allBasicServicesTAPI
      .asObservable()
      .pipe(filter(basicServices => !!basicServices));
  }

  get neatServicesActive$(): Observable<any> {
    return this.activeNeatServices
      .asObservable()
      .pipe(filter(basicServices => !!basicServices));
  }

  get neatCategoriesVisibles$(): Observable<INeatCategories[]> {
    return this.neatCategoriesVisibles
      .asObservable()
      .pipe(filter(basicServices => !!basicServices));
  }

  get selectedEntity$(): Observable<Entity> {
    return this.selectedEntity.asObservable().pipe(filter(entity => !!entity));
  }

  get allPayments$(): Observable<Payment[]> {
    return this.allPayments.asObservable().pipe(filter(payment => !!payment));
  }

  get allUnifiedPayments$(): Observable<Payment[]> {
    return this.allUnifiedPayments
      .asObservable()
      .pipe(filter(payment => !!payment));
  }

  get customTobparRoute$(): Observable<any> {
    return this.customTopbarRoutName.asObservable();
  }

  get allPaymentChanges$(): Observable<number> {
    return this.paymentChanges.asObservable();
  }

  // basic service mvp
  get allBasicServicePayments$(): Observable<Payment[]> {
    return this.allBasicServicePayments
      .asObservable()
      .pipe(filter(payment => !!payment));
  }

  get allBasicUpdatingDebts$(): Observable<IGetBasicServiceDebtRequest[]> {
    return this.updatingDebts.asObservable().pipe(filter(debt => !!debt));
  }

  get alloadingDebts$(): Observable<string[]> {
    return this.loadingDebts.asObservable();
  }

  addAllPaymentsChanges(data: number): void {
    this.paymentChanges.next(data);
  }

  addAllEntities(data: Entity[], neatCategories: INeatCategories[]): void {
    const entities = data.map(entity => {
      return Object.assign(entity, {
        logoUrl: getImageUrl(entity, null, neatCategories)
      });
    });
    this.allEntities.next(entities);
  }

  loadMorePayments(value: number): void {
    this.rangeOfMonthsToLoadPayments.next(value);
  }

  addSelectedNeatCategoryService(data: any): void {
    this.selectedNeatServiceCategory.next(data);
  }

  addSelectedCategory(data: string): void {
    this.selectedNeatCategory.next(data);
  }

  addSelectedUtilityNumber(data: string): void {
    this.selectedUtilityNumber.next(data);
  }

  addAllBasicServices(data: BasicService[]): void {
    this.allBasicServices.next(data);
  }

  addAllUnifiedEntities(
    data: MergeTypes<BasicService, Entity>[],
    neatServices: INeatServices[],
    neatCategories: INeatCategories[]
  ): void {
    const entities = data.map(entity => {
      return Object.assign(entity, {
        logoUrl: getImageUrl(entity, neatServices, neatCategories)
      });
    });
    this.allUnifiedEntities.next(entities);
  }

  addAllBasicServicesConfig(data: any): void {
    this.allBasicServicesConfig.next(data);
  }

  addAllBasicServicesTAPI(data: INeatServices): void {
    this.allBasicServicesTAPI.next(data);
  }

  addAllActiveNeatServices(data: INeatServices): void {
    this.activeNeatServices.next(data);
  }

  addAllNeatCategoriesServices(data: INeatCategories[]): void {
    this.neatCategoriesVisibles.next(data);
  }

  addSelectedEntity(data: Entity): void {
    this.selectedEntity.next(data);
  }
  changeSelectedEntity(entity: Entity): void {
    if (this.selectedEntitySubs) {
      this.selectedEntitySubs.unsubscribe();
    }
    const entityCollection = this.entityFactory.getCollectionName(
      entity.entityType
    );
    const selectedEntityDoc = this.afs.doc<Entity>(
      `${entityCollection}/${entity.id}`
    );
    this.selectedEntitySubs = selectedEntityDoc
      .valueChanges()
      .pipe(
        map(doc => {
          return this.entityFactory.deserializeEntity(entity.entityType, doc);
        })
      )
      .subscribe({
        next: serializedEntity => this.addSelectedEntity(serializedEntity),
        error: error => {
          this.errorService.recordError(
            error,
            'rent-list.service.ts',
            'selectedEntityDoc.valueChanges',
            'Error al ejecutar subscribe de serializedEntity'
          );
          throw error;
        }
      });
  }

  addAllPayments(data: Payment[]): void {
    this.allPayments.next(data);
  }

  addAllUnifiedPayments(data: Payment[]): void {
    this.allUnifiedPayments.next(data);
  }

  // basic service mvp
  addAllBasicServicePayments(data: Payment[]): void {
    this.allBasicServicePayments.next(data);
  }

  addAllUpdatingDebts(data: IGetBasicServiceDebtRequest[]): void {
    this.updatingDebts.next(data);
  }

  addLoadingDebt(debt: string): void {
    const currentDebts = this.loadingDebts.value;
    this.loadingDebts.next([...currentDebts, debt]);
  }

  removeLoadingDebt(debt: string): void {
    const currentDebts = this.loadingDebts.value;
    this.loadingDebts.next(currentDebts.filter(d => d !== debt));
  }

  unsubscribeElements() {
    if (this.selectedEntitySubs) {
      this.selectedEntitySubs.unsubscribe();
    }
  }

  private getEntityCollection$(
    entityType: EntityType,
    currentUser: User
  ): Observable<Entity[]> {
    const entityCollection = this.entityFactory.getCollectionName(entityType);
    const entityAFSCollection = this.afs.collection<Entity>(
      entityCollection,
      ref =>
        ref
          .where('lesseeEmail', '==', currentUser.email)
          .where('isDisable', '==', false)
    );
    return entityAFSCollection
      .valueChanges()
      .pipe(
        map(entityArray =>
          entityArray.map(entityData =>
            this.entityFactory.deserializeEntity(entityType, entityData)
          )
        )
      );
  }

  getAllEntities$(currentUser: User): Observable<Entity[]> {
    const entitiesArray: Array<Observable<Entity[]>> = [];
    for (const entityType of Object.keys(EntityType)) {
      entitiesArray.push(
        this.getEntityCollection$(EntityType[entityType], currentUser)
      );
    }
    return combineLatest(entitiesArray).pipe(
      map(entityArray => [].concat(...entityArray))
    );
  }

  private getBasicServicesCollection$(
    basicServicesType: BasicServiceType,
    currentUser: User
  ): Observable<BasicService[]> {
    const basicServiceCollection = this.basicServiceFactory.getBasicServiceCollectionName(
      basicServicesType
    );
    const basicServiceAFSCollection = this.afs.collection<BasicService>(
      basicServiceCollection,
      ref =>
        ref
          .where('lesseeEmail', '==', currentUser.email)
          .where('isDisable', '==', false)
    );
    return basicServiceAFSCollection
      .valueChanges()
      .pipe(
        map(basicServiceArray =>
          basicServiceArray.map(basicServiceData =>
            this.basicServiceFactory.deserializeBasicService(
              basicServicesType,
              basicServiceData
            )
          )
        )
      );
  }

  getAllBasicServices$(currentUser: User): Observable<BasicService[]> {
    const basicServicesArray: Array<Observable<BasicService[]>> = [];
    basicServicesArray.push(
      this.getBasicServicesCollection$(
        BasicServiceType.basicService,
        currentUser
      )
    );
    return combineLatest(basicServicesArray).pipe(
      map(basicServicesArray => [].concat(...basicServicesArray))
    );
  }

  getBasicServicesTAPICollection$(): Observable<any> {
    const basicServiceAFSCollection = this.afs.collection<any>('neatServices');
    return basicServiceAFSCollection.valueChanges();
  }

  getNeatCategoriesCollection$(): Observable<any> {
    const basicServiceAFSCollection = this.afs.collection<any>(
      'neatServiceCategories'
    );
    return basicServiceAFSCollection.valueChanges();
  }

  gethipotecariaSecurityCollection$(): Observable<any> {
    const basicServiceAFSCollection = this.afs.collection<any>(
      'securityPrincipalDebts'
    );
    return basicServiceAFSCollection.valueChanges();
  }

  async firestoreGetAvailableDays() {
    const reviewsCollection = await this.afs.firestore
      .collection('generalData')
      .doc('transferDateInfo')
      .get();
    const getData = reviewsCollection.data();
    const getAvailableDays = getData.transferAvailableDays;
    this.availableDays = getAvailableDays;
  }

  async checkWhitelistAccess(
    featureName: string,
    userRut: string | undefined
  ): Promise<boolean> {
    if (!featureName) {
      return false;
    }
    try {
      const snapshot = await this.afs
        .collection<FeatureFlag>('featureFlags', ref =>
          ref.where('featureName', '==', featureName)
        )
        .get()
        .toPromise();
      const featureFlags = snapshot.docs.map(doc => doc.data() as FeatureFlag);
      const featureFlag = featureFlags[0]; // Asumimos que solo hay un documento por featureName
      if (!featureFlag) {
        return false;
      }
      switch (featureFlag.availability) {
      case 'none':
        return false;
      case 'all':
        return true;
      case 'whitelist':
        return userRut ? featureFlag.whitelistArray.includes(userRut) : false;
      default:
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  private getPaymentCollection$(
    entityType: EntityType,
    currentUser: User,
    rangeOfMonthsToLoadPayments?: number
  ): Observable<Payment[]> {
    try {
      let maxMonth = 0;
      if (rangeOfMonthsToLoadPayments === 6) {
        maxMonth = 3;
      } else if (rangeOfMonthsToLoadPayments === 12) {
        maxMonth = 6;
      } else if (rangeOfMonthsToLoadPayments > 12) {
        maxMonth = 12;
      }
      const minDate =
        new Date(
          new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
        ).getTime() - this.monthToMillisecond(rangeOfMonthsToLoadPayments);
      const maxDate =
        new Date(
          new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
        ).getTime() - this.monthToMillisecond(maxMonth);
      const paymentCollection = this.paymentFactory.getCollectionName(
        entityType
      );
      let paymentAFSCollection = this.afs.collection<Entity>(
        paymentCollection,
        ref =>
          ref
            .where('lesseeEmail', '==', currentUser.email)
            .where('createDate', '>=', minDate)
            .where('createDate', '<', maxDate)
            .orderBy('createDate', 'desc')
      );
      if (rangeOfMonthsToLoadPayments <= 3) {
        paymentAFSCollection = this.afs.collection<Entity>(
          paymentCollection,
          ref =>
            ref
              .where('lesseeEmail', '==', currentUser.email)
              .where('createDate', '>=', minDate)
              .orderBy('createDate', 'desc')
        );
      }
      return paymentAFSCollection.valueChanges().pipe(
        map(paymentArray =>
          paymentArray.map(paymentData => {
            return this.paymentFactory.deserializePayment(
              entityType,
              paymentData
            );
          })
        ),
        catchError(error => {
          this.errorService.recordError(
            error,
            'rent-list.service.ts',
            'paymentAFSCollection.valueChanges',
            `Error al ejecutar subscribe de paymentCollection for user: ${currentUser.id}`
          );
          return of([]);
        })
      );
    } catch (error) {
      this.errorService.recordError(
        error,
        'rent-list.service.ts',
        'getPaymentCollection$()',
        `Error al ejecutar subscribe de paymentCollection for user: ${currentUser.id}`
      );
      return of([]);
    }
  }

  // basic service mvp
  private getBasicServicePaymentCollection$(
    entityType: string,
    currentUser: User,
    rangeOfMonthsToLoadPayments?: number
  ): Observable<Payment[]> {
    try {
      const paymentCollection = 'basicServicesPayments';
      let maxMonth = 0;
      if (rangeOfMonthsToLoadPayments === 6) {
        maxMonth = 3;
      } else if (rangeOfMonthsToLoadPayments === 12) {
        maxMonth = 6;
      } else if (rangeOfMonthsToLoadPayments > 12) {
        maxMonth = 12;
      }
      const minDate =
        new Date(
          new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
        ).getTime() - this.monthToMillisecond(rangeOfMonthsToLoadPayments);
      const maxDate =
        new Date(
          new Date().toLocaleString('en-US', { timeZone: 'America/Santiago' })
        ).getTime() - this.monthToMillisecond(maxMonth);
      let paymentAFSCollection = this.afs.collection<Entity>(
        paymentCollection,
        ref =>
          ref
            .where('lesseeEmail', '==', currentUser.email)
            .where('createDate', '>=', minDate)
            .where('createDate', '<', maxDate)
            .orderBy('createDate', 'desc')
      );
      if (rangeOfMonthsToLoadPayments <= 3) {
        paymentAFSCollection = this.afs.collection<Entity>(
          paymentCollection,
          ref =>
            ref
              .where('lesseeEmail', '==', currentUser.email)
              .where('createDate', '>=', minDate)
              .orderBy('createDate', 'desc')
        );
      }
      return paymentAFSCollection.valueChanges().pipe(
        map(paymentArray =>
          paymentArray.map(paymentData => {
            return this.paymentFactory.deserializePayment(
              entityType,
              paymentData
            );
          })
        ),
        catchError(error => {
          this.errorService.recordError(
            error,
            'rent-list.service.ts',
            'paymentAFSCollection.valueChanges',
            `Error al ejecutar subscribe de paymentCollection for user: ${currentUser.id}`
          );
          return of([]);
        })
      );
    } catch (error) {
      this.errorService.recordError(
        error,
        'rent-list.service.ts',
        'getPaymentCollection$()',
        `Error al ejecutar subscribe de paymentCollection for user: ${currentUser.id}`
      );
      return of([]);
    }
  }

  private monthToMillisecond(month: number) {
    return 60 * 60 * 24 * 31 * month * 1000;
  }

  // basic service mvp
  getAllPayments$(
    currentUser: User,
    rangeOfMonthsToLoadPayments?: number
  ): Observable<Payment[]> {
    const paymentsArray: Array<Observable<Payment[]>> = [];
    for (const entityType of Object.keys(EntityType)) {
      paymentsArray.push(
        this.getPaymentCollection$(
          EntityType[entityType],
          currentUser,
          rangeOfMonthsToLoadPayments
        )
      );
    }
    return combineLatest(paymentsArray).pipe(
      map(entityArray => [].concat(...entityArray))
    );
  }

  // basic service mvp
  getAllBasicServicesPayments$(
    currentUser: User,
    rangeOfMonthsToLoadPayments?: number
  ): Observable<Payment[]> {
    const paymentsArray: Array<Observable<Payment[]>> = [];
    paymentsArray.push(
      this.getBasicServicePaymentCollection$(
        'basicService',
        currentUser,
        rangeOfMonthsToLoadPayments
      )
    );

    return combineLatest(paymentsArray).pipe(
      map(entityArray => [].concat(...entityArray))
    );
  }

  async deleteEntity(entityId: string, entityType: EntityType) {
    const collection = this.afs.collection(
      this.entityFactory.getCollectionName(entityType)
    );
    await collection.doc(entityId).update({ isDisable: true });
  }

  async deleteBasicService(entityId: string, entityType: EntityType) {
    const collection = this.afs.collection(
      this.basicServiceFactory.getBasicServiceCollectionName(entityType)
    );
    await collection.doc(entityId).update({ isDisable: true });
  }

  async getDepositeeInfo(date: Date) {
    const depositeeInfoCollection = this.afs
      .collection('depositeeInfo')
      .ref.where('isPublic', '==', true)
      .where('lastUpdateDate', '>=', date)
      .where('active', '==', true)
      .where('isPrefill', '==', true)
      .get();
    return depositeeInfoCollection;
  }

  goToPay(
    entity: MergeTypes<BasicService, Entity>,
    analyticsObject: { event: string; section: string },
    navigateToCheckout: boolean,
    dialogRef?: any
  ) {
    this.currentEntity = entity;
    this.selectedEntities = [entity] as MergeTypes<BasicService, Entity>[];
    this.selectedEntitiesForMultipay = [entity] as MergeTypes<
      BasicService,
      Entity
    >[];
    this.fireAnalytics.logEvent(
      'ModalVisibilityAutomaticPayment',
      analyticsObject
    );
    navigateToCheckout &&
      this.router.navigate(['/dashboard/pagar-nuevo', entity?.id]);
    dialogRef && dialogRef.close();
  }

  goToAutomate(
    entity: MergeTypes<BasicService, Entity> | null,
    automate: boolean,
    analyticsObject: { event: string; section: string },
    dialogRef?: any,
    entities?: MergeTypes<BasicService, Entity>[]
  ): void {
    this.fireAnalytics.logEvent(
      'ModalVisibilityAutomaticPayment',
      analyticsObject
    );
    const automateMode = automate ? { queryParams: { automate: true } } : {};
    entity && (this.currentEntityForConfig = entity);
    entity &&
      this.router.navigate(
        ['/dashboard/configurar-cuenta', entity?.id],
        automateMode
      );
    entities &&
      this.dialogService.selectEntityForAutomatizate(entities as any, true);
    dialogRef && dialogRef.close();
  }

  getAvailableBasicServices(neatServices) {
    const basicServicesCompanies = neatServices.map(service =>
      Object.assign(service, {
        neatUtilityCategory: utilityTypeToCategory[service.neatCategory]
      })
    );
    const allUtilityTypes = basicServicesCompanies.map(
      (basicServicesConfig: IBasicServiceConfiguration) =>
        basicServicesConfig.neatUtilityCategory
    );
    const utilityTypes: string[] = Array.from(new Set(allUtilityTypes));

    return { utilityTypes, basicServicesCompanies };
  }

  loadNeatEntities(entities: Entity[], payments: Payment[]): Entity[] {
    const allPayments = payments
      .filter(payment =>
        [
          PaymentStatus.Done,
          PaymentStatus.InProgress,
          PaymentStatus.Late,
          PaymentStatus.Created
        ].includes(payment?.currentPaymentStatus as PaymentStatus)
      )
      .sort(
        (a, b) =>
          new Date(b?.createDate).getTime() - new Date(a?.createDate).getTime()
      );
    const filteredEntities: Entity[] = entities.filter(
      (entity: Entity) =>
        !entity?.paymentOption?.automatic &&
        !entity.incompleteSchema &&
        !entity?.customData &&
        entity?.creationType !== 'depositeeDashboard'
    );
    const neatEntities = filteredEntities.map(entity => {
      return this.entityFactory.deserializeEntity(entity.entityType, {
        ...entity,
        paymentInProgress:
          allPayments.find(
            payment =>
              payment?.entityId === entity?.id &&
              [PaymentStatus.Created, PaymentStatus.InProgress].includes(
                payment?.currentPaymentStatus as PaymentStatus
              )
          ) || null,
        paymentLate:
          allPayments.find(
            payment =>
              payment?.entityId === entity?.id &&
              payment?.currentPaymentStatus === PaymentStatus.Late
          ) || null,
        lastPayment:
          allPayments.filter(payment => payment?.entityId === entity?.id)[0] ||
          null
      });
    });
    const entitiesWithProblems = sortNeatEntities(
      neatEntities.filter(entity => entity.paymentLate)
    );
    const entitiesInProgress = sortNeatEntities(
      neatEntities.filter(entity => entity.paymentInProgress)
    );
    const entitiesWithReminders = sortNeatEntities(
      neatEntities.filter(
        entity =>
          !entity.paymentLate &&
          !entity.paymentInProgress &&
          entity?.sendReminderNotification &&
          entity.transferLimitDate
      )
    );
    const entitiesWithRecentsPayments = sortNeatEntities(
      neatEntities.filter(
        entity =>
          !entity.paymentLate &&
          !entity.paymentInProgress &&
          !(entity?.sendReminderNotification && entity?.transferLimitDate) &&
          isRecentPayment(entity)
      )
    );
    const entitiesHidden = sortNeatEntities(
      neatEntities
        .filter(
          entity =>
            !entity.paymentLate &&
            !entity.paymentInProgress &&
            !(entity?.sendReminderNotification && entity?.transferLimitDate) &&
            !isRecentPayment(entity)
        )
        .map(entity => {
          return this.entityFactory.deserializeEntity(entity.entityType, {
            ...entity,
            hidden: true
          });
        })
    );

    const entitiesSorted = [
      ...entitiesWithProblems,
      ...entitiesInProgress,
      ...entitiesWithReminders,
      ...entitiesWithRecentsPayments,
      ...entitiesHidden
    ];

    const findDuplications = findDuplicateIds(entitiesSorted);
    if (findDuplications?.count > 0) {
      const entitiesSortedCleaned = removeDuplicateEntities(
        entitiesSorted
      ) as Entity[];
      this.fireAnalytics.logEvent('Duplicated_Entity_Found', {
        ids: findDuplications?.duplicates,
        entitiesWithProblems:
          entitiesWithProblems?.map(entity => entity?.id) || null,
        entitiesInProgress:
          entitiesInProgress?.map(entity => entity?.id) || null,
        entitiesWithReminders:
          entitiesWithReminders?.map(entity => entity?.id) || null,
        entitiesWithRecentsPayments:
          entitiesWithRecentsPayments?.map(entity => entity?.id) || null,
        entitiesHidden: entitiesHidden?.map(entity => entity?.id) || null,
        entitiesSortedCleaned:
          entitiesSortedCleaned?.map(entity => entity?.id) || null,
        section: 'neatEntities'
      });
      return entitiesSortedCleaned;
    }

    return entitiesSorted;
  }

  loadPreloadedEntities(
    entities: MergeTypes<BasicService, Entity>[],
    payments: Payment[]
  ): MergeTypes<BasicService, Entity>[] {
    const allPayments = payments
      .filter(payment =>
        [
          PaymentStatus.Done,
          PaymentStatus.InProgress,
          PaymentStatus.Created
        ].includes(payment?.currentPaymentStatus as PaymentStatus)
      )
      .sort(
        (a, b) =>
          new Date(b?.createDate).getTime() - new Date(a?.createDate).getTime()
      );
    const filteredEntities: MergeTypes<
      BasicService,
      Entity
    >[] = entities.filter(
      (entities: MergeTypes<BasicService, Entity>) =>
        (entities.customData || entities.utilityName) &&
        !entities?.paymentOption?.automatic &&
        (entities?.entityType === ('basicService' as EntityType)
          ? entities.verified === true && entities.clientNumber
          : true)
    );
    const assignPaymentIntoPreloadedEntities = filteredEntities.map(entity => {
      const factoryObject =
        (entity.entityType as any) === 'basicService'
          ? this.basicServiceFactory.deserializeBasicService(
            entity.entityType,
            {
              ...entity,
              lastPayment:
                  allPayments.filter(
                    payment => payment?.entityId === entity?.id
                  )[0] || null
            }
          )
          : this.entityFactory.deserializeEntity(entity.entityType, {
            ...entity,
            lastPayment:
                allPayments.filter(
                  payment => payment?.entityId === entity?.id
                )[0] || null
          });
      return factoryObject as MergeTypes<BasicService, Entity>;
    });
    const preloadedEntities = this.orderPreloadedEntities(
      assignPaymentIntoPreloadedEntities,
      allPayments
    );
    const entitiesWithDebtAvailable = sortPreloadedEntities(
      preloadedEntities.filter(
        entity =>
          !entity?.currentDebtError &&
          entity?.value > 0 &&
          (entity?.customData?.providerName
            ? isCustomProviderEnabledToBePaid(
              entity.customData.providerName,
              entity.customData.lastUpdate,
              entity.value,
              entity.valueUF
            )
            : validateUpdatedDebts(entity))
      )
    );
    const entitiesUpdateDebtProblem = sortPreloadedEntities(
      preloadedEntities.filter(entity => entity?.currentDebtError)
    );
    const entitiesNoUpdatedDebt = sortPreloadedEntities(
      preloadedEntities.filter(
        entity =>
          !entity?.currentDebtError &&
          entity.value > 0 &&
          (entity?.customData?.providerName
            ? !isCustomProviderEnabledToBePaid(
              entity.customData.providerName,
              entity.customData.lastUpdate,
              entity.value,
              entity.valueUF
            )
            : !validateUpdatedDebts(entity))
      )
    );
    const entitiesNoDebtWithRecentsPayments = sortPreloadedEntities(
      preloadedEntities.filter(
        entity =>
          !entity?.currentDebtError &&
          entity.value === 0 &&
          !entity?.currentDebtError &&
          isPreloadedRecentPayment(entity)
      )
    );
    const entitiesNoDebtWithNoRecentsPayments = sortPreloadedEntities(
      preloadedEntities.filter(
        entity =>
          !entity?.currentDebtError &&
          entity.value === 0 &&
          !entity?.currentDebtError &&
          !isPreloadedRecentPayment(entity)
      )
    ).map(entity => {
      const factoryObject =
        (entity.entityType as any) === 'basicService'
          ? this.basicServiceFactory.deserializeBasicService(
            entity.entityType,
            {
              ...entity,
              hidden: true
            }
          )
          : this.entityFactory.deserializeEntity(entity.entityType, {
            ...entity,
            hidden: true
          });
      return factoryObject as MergeTypes<BasicService, Entity>;
    });

    const entitiesSorted = [
      ...entitiesWithDebtAvailable,
      ...entitiesUpdateDebtProblem,
      ...entitiesNoUpdatedDebt,
      ...entitiesNoDebtWithRecentsPayments,
      ...entitiesNoDebtWithNoRecentsPayments
    ];

    const findDuplications = findDuplicateIds(entitiesSorted);
    if (findDuplications?.count > 0) {
      this.fireAnalytics.logEvent('Duplicated_Entity_Found', {
        ids: findDuplications?.duplicates,
        section: 'preloadedEntities'
      });
      const entitiesSortedCleaned = removeDuplicateEntities(
        entitiesSorted
      ) as MergeTypes<BasicService, Entity>[];
      return entitiesSortedCleaned;
    }

    return entitiesSorted;
  }

  loadAutomaticEntities(
    entities: MergeTypes<BasicService, Entity>[],
    payments: Payment[]
  ): MergeTypes<BasicService, Entity>[] {
    const allPayments = payments
      .filter(payment =>
        [PaymentStatus.Late].includes(
          payment?.currentPaymentStatus as PaymentStatus
        )
      )
      .sort(
        (a, b) =>
          new Date(b?.createDate).getTime() - new Date(a?.createDate).getTime()
      );
    const filteredEntities: MergeTypes<
      BasicService,
      Entity
    >[] = entities.filter(
      (entities: MergeTypes<BasicService, Entity>) =>
        entities?.paymentOption?.automatic
    );
    const assignPaymentIntoAutomaticEntities = filteredEntities.map(entity => {
      const factoryObject =
        (entity.entityType as any) === 'basicService'
          ? this.basicServiceFactory.deserializeBasicService(
            entity.entityType,
            {
              ...entity
            }
          )
          : this.entityFactory.deserializeEntity(entity.entityType, {
            ...entity,
            paymentLate:
                allPayments?.find(
                  payment =>
                    payment?.entityId === entity?.id &&
                    payment?.currentPaymentStatus === PaymentStatus.Late
                ) || null
          });
      return factoryObject as MergeTypes<BasicService, Entity>;
    });
    const preloadedEntities = this.orderAutomaticEntities(
      assignPaymentIntoAutomaticEntities
    );
    const entitiesWithProblems = sortByTransferLimitDate(
      preloadedEntities.filter(
        entity =>
          (entity as any)?.failureAutomaticPayment ||
          entity?.currentDebtError ||
          entity.paymentLate
      )
    );
    const entitiesWithoutProblems = sortByTransferLimitDate(
      preloadedEntities.filter(
        entity =>
          !(entity as any)?.failureAutomaticPayment &&
          !entity?.currentDebtError &&
          !entity.paymentLate
      )
    );

    const entitiesSorted = [
      ...entitiesWithProblems,
      ...entitiesWithoutProblems
    ];

    const findDuplications = findDuplicateIds(entitiesSorted);
    if (findDuplications?.count > 0) {
      this.fireAnalytics.logEvent('Duplicated_Entity_Found', {
        ids: findDuplications?.duplicates,
        section: 'automaticEntities'
      });
      const entitiesSortedCleaned = removeDuplicateEntities(
        entitiesSorted
      ) as MergeTypes<BasicService, Entity>[];
      return entitiesSortedCleaned;
    }

    return entitiesSorted;
  }

  orderAutomaticEntities(
    filteredEntities: MergeTypes<BasicService, Entity>[]
  ): MergeTypes<BasicService, Entity>[] {
    return filteredEntities.map((entity: any) => {
      const factoryObject =
        (entity.entityType as any) === 'basicService'
          ? this.basicServiceFactory.deserializeBasicService(
            entity.entityType,
            {
              ...entity,
              value: this.calculateAutomaticValue(entity)
            }
          )
          : this.entityFactory.deserializeEntity(entity.entityType, {
            ...entity
          });
      return factoryObject;
    }) as MergeTypes<BasicService, Entity>[];
  }

  orderPreloadedEntities(
    filteredEntities: MergeTypes<BasicService, Entity>[],
    allPayments: Payment[]
  ): MergeTypes<BasicService, Entity>[] {
    return filteredEntities.map((entity: any) => {
      const relatedPayments = allPayments.filter(
        payment => payment?.entityId === entity?.id
      );
      const factoryObject =
        (entity.entityType as any) === 'basicService'
          ? this.basicServiceFactory.deserializeBasicService(
            entity.entityType,
            {
              ...entity,
              lastPayment: relatedPayments[0] || null,
              value: this.calculateValue(entity, relatedPayments),
              invoicesToBePayed: this.updateInvoicesToBePayed(entity)
            }
          )
          : this.entityFactory.deserializeEntity(entity.entityType, {
            ...entity,
            lastPayment: relatedPayments[0] || null,
            value: this.calculateValue(entity, relatedPayments)
          });
      return factoryObject;
    }) as MergeTypes<BasicService, Entity>[];
  }

  setBasicServiceNewValue(
    id: string,
    payValidation: number,
    currentDebt: IBasicServiceDebt
  ): number {
    if (
      this.paymentsService.invoicesToBePayed[id] &&
      this.paymentsService.invoicesToBePayed[id]?.totalAmount
    ) {
      return this.paymentsService.invoicesToBePayed[id]?.totalAmount;
    }
    if (!payValidation || [1, 6].includes(payValidation)) {
      return currentDebt.invoices[0].amount;
    } else {
      return currentDebt.totalAmount;
    }
  }

  setBasicServiceInvoicesToBePayed(
    id: string,
    payValidation: number,
    currentDebt: IBasicServiceDebt
  ): IFields[] {
    if (
      this.paymentsService.invoicesToBePayed[id] &&
      this.paymentsService.invoicesToBePayed[id]?.totalAmount
    ) {
      return this.paymentsService.invoicesToBePayed[id]?.invoices;
    }
    if (!payValidation || [1, 6].includes(payValidation)) {
      return [currentDebt.invoices[0]];
    } else {
      return currentDebt.invoices;
    }
  }

  payValidationValidator(
    payValidation: number,
    currentDebt: IBasicServiceDebt
  ): boolean {
    if (!payValidation || [1, 6].includes(payValidation)) {
      return areInvoicesToBePaidValid(
        [currentDebt.invoices[0]],
        currentDebt.invoices,
        payValidation
      );
    } else {
      return areInvoicesToBePaidValid(
        currentDebt.invoices,
        currentDebt.invoices,
        payValidation
      );
    }
  }

  calculateAutomaticValue(entity: MergeTypes<BasicService, Entity>): number {
    if (
      (entity.entityType as any) === 'basicService' &&
      entity?.paymentOption?.sencillitoPreference &&
      [2, 3, 5].includes(entity?.payValidation) &&
      entity?.currentDebt &&
      entity.currentDebt?.invoices.length > 1 &&
      this.payValidationValidator(entity.payValidation, entity?.currentDebt)
    ) {
      return this.setBasicServiceNewValueAutomatic(
        entity.currentDebt,
        entity?.paymentOption?.sencillitoPreference
      );
    } else {
      return entity.value;
    }
  }

  setBasicServiceNewValueAutomatic(
    currentDebt: IBasicServiceDebt,
    sencillitoPreference: SencillitoAutomaticPreferences
  ): number {
    if (
      sencillitoPreference &&
      sencillitoPreference === SencillitoAutomaticPreferences.total
    ) {
      return currentDebt.totalAmount;
    }
    return currentDebt.invoices[0].amount;
  }

  calculateValue(
    entity: MergeTypes<BasicService, Entity>,
    allPayments: Payment[]
  ): number {
    if (
      entity.payingAccountUnit === PayingAccountUnit.UF &&
      [
        CustomProvidersNames.hipotecariaSecurity,
        CustomProvidersNames.securityPrincipal
      ].includes(entity?.customData?.providerName) &&
      this.paymentsService?.todayUF
    ) {
      const valueClp = Math.round(
        AppGlobals.convertUFToPeso(this.paymentsService.todayUF, entity.valueUF)
      );
      return valueClp;
    }
    if (
      (entity.entityType as any) === 'basicService' &&
      entity?.currentDebt &&
      entity.currentDebt?.invoices.length > 1 &&
      this.payValidationValidator(entity.payValidation, entity?.currentDebt)
    ) {
      return this.setBasicServiceNewValue(
        entity.id,
        entity.payValidation,
        entity.currentDebt
      );
    }
    if (
      (entity.entityType as any) === 'basicService' &&
      forceDebtToZero(entity as any, allPayments)
    ) {
      return 0;
    }
    return entity.value;
  }

  updateInvoicesToBePayed(
    entity: MergeTypes<BasicService, Entity>
  ): IFields[] | IFields {
    if (
      (entity.entityType as any) === 'basicService' &&
      entity?.currentDebt &&
      entity.currentDebt?.invoices.length > 1 &&
      this.payValidationValidator(entity.payValidation, entity?.currentDebt)
    ) {
      return this.setBasicServiceInvoicesToBePayed(
        entity.id,
        entity.payValidation,
        entity.currentDebt
      );
    }
    if (
      (entity.entityType as any) === 'basicService' &&
      entity?.currentDebt &&
      entity.currentDebt?.invoices.length === 1
    ) {
      return entity.currentDebt.invoices[0];
    }
  }

  triggerAction(action: any) {
    this.actionSource.next(action);
  }

  updateCustomRouteName(route: any) {
    this.customTopbarRoutName.next(route);
  }
}
