<div *ngIf="showPreloader" class="preloader">
  <div class="loader">
    <div class="loader__figure"></div>
    <p class="loader__label">
      <img src="assets/img/newRequirements/new-neat-color-01.png" />
    </p>
    <p class="loader__label neat-text">
      Así de simple, así de <span class="font-weight-semi-bold">neat</span>
    </p>
  </div>
</div>
