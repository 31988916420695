import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-checkout-presentation',
  templateUrl: './new-checkout-presentation.component.html',
  styleUrls: ['./new-checkout-presentation.component.scss']
})
export class NewCheckoutPresentationComponent {
  constructor(
    public dialogRef: MatDialogRef<NewCheckoutPresentationComponent>
  ) {}

  closeBtn(): void {
    this.dialogRef.close();
  }
}
