<div class="text-center">
  <span class="close-icon d-flex justify-content-end" (click)="onNoClick()"
    ><mat-icon class="c-pointer">close</mat-icon></span
  >
  <div
    *ngIf="basicServicesDone.length > 0"
    class="d-flex justify-content-center align-items-center"
  >
    <img
      class="img-icon mr-3"
      src="assets/img/icons/success-icon-2.png"
      style="max-width: 100%; max-height: 100%;"
      alt="success-icon"
    />
    <span class="title d-block my-3"
      >Los siguientes servicios fueron pagados correctamente</span
    >
  </div>
  <div
    class="w-100 m-auto"
    style="max-width: 450px;"
    *ngFor="let basicService of basicServicesDone"
  >
    <div
      *ngIf="!['Tag Total', 'AutoTag'].includes(basicService?.utilityName)"
      class="w-100 d-flex align-items-center justify-content-center my-3 py-4 px-2 px-md-5 entity-row mobile-center"
    >
      <div class="ml-0 ml-md-3 text-left">
        <div *ngIf="basicService.utilityName" class="d-block">
          <span class="secundary-text"
            >{{
              basicService.alias ? basicService.alias : basicService.utilityName
            }}
          </span>
          ·
          <span class="secundary-text">{{
            basicService.value -
              (basicService?.deltaValue ? basicService?.deltaValue : 0)
              | clpCurrency
          }}</span>
        </div>
        <div *ngIf="basicService?.customData" class="d-block">
          <span class="secundary-text"
            >{{
              basicService?.customData?.providerName === 'Edipro'
                ? 'Gasto Común Edipro'
                : basicService?.customData?.providerName
            }}
          </span>
          ·
          <span class="secundary-text">{{
            ['Security Principal', 'Hipotecaria Security'].includes(
              basicService?.customData.providerName
            )
              ? basicService.valueUF + ' UF'
              : (basicService.value | clpCurrency)
          }}</span>
        </div>
        <div
          *ngIf="!basicService.utilityName && !basicService?.customData"
          class="d-block"
        >
          <span class="secundary-text"
            >{{ entityTypeLocale[basicService?.entityType] | titlecase }}
          </span>
          ·
          <span class="secundary-text">{{
            basicService?.payingAccountUnit === 'UF'
              ? basicService.valueUF + ' UF'
              : (basicService.value | clpCurrency)
          }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="['Tag Total', 'AutoTag'].includes(basicService?.utilityName)">
      <div style="border: 1px solid #B7D3F9; border-radius: 5px;">
        <div
          class="d-flex justify-content-between p-3"
          style="background-color: #F4F8FE; border-bottom: 1px solid #B7D3F9;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;"
        >
          <div>
            <span
              style="color: #212121;
                        font-family: Poppins;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.15px;"
            >
              <b>AutoTag</b> -
              {{
                basicService.currentDebt.invoices.length > 1
                  ? basicService.currentDebt.invoices.length + ' autopistas'
                  : basicService.currentDebt.invoices.length + ' autopista'
              }}
            </span>
          </div>
          <div>
            <span
              style="color: #212121;
                        font-family: Poppins;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: 0.15px;"
            >
              {{ basicService.currentDebt.totalAmount | clpCurrency }}
            </span>
          </div>
        </div>
        <div
          *ngFor="
            let invoice of basicService.currentDebt.invoices;
            let i = index
          "
          style="background-color: #FCFDFF;"
          class="d-flex align-items-center justify-content-between p-3"
        >
          <div *ngIf="invoice?.companyName" class="d-flex align-items-center">
            <div class="mr-3">
              <img
                src="assets/img/basic-services-companies-logos-2/{{
                  invoice?.companyName?.replace('/', '-')
                }}.png"
                onerror="this.onerror=null;this.remove()"
                style="max-width: 30px;"
              />
            </div>
            <div>
              <span
                class="d-block"
                style="color: #3C4082;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;"
              >
                {{ invoice.companyName }}
              </span>
            </div>
          </div>
          <div>
            <span
              class="d-block"
              style="color: #3B4086;
                        text-align: right;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 19px; /* 135.714% */
                        letter-spacing: -0.028px;"
              >{{ invoice.amount | clpCurrency }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="basicServicesDone.length > 0" class="pt-3">
    <span class="hours-text d-block"
      >En el historial de pagos podrás descargar los comprobantes que garantizan
      el pago de tu cuenta.</span
    >
    <span *ngIf="basicServicePayed" class="hours-text d-block mt-1"
      >La actualización de la deuda se puede demorar hasta 72 horas hábiles
      dependiendo de la empresa.
    </span>
  </div>
  <hr
    *ngIf="basicServicesDone.length > 0 && basicServicesIssue.length > 0"
    style="background: #E7EBEF; margin-bottom: 20px; margin-top: 20px;"
  />
  <div
    *ngIf="basicServicesIssue.length > 0"
    class="d-flex justify-content-center align-items-center"
  >
    <img
      class="img-icon mr-3"
      src="assets/img/icons/warning-icon-2.png"
      style="max-width: 100%; max-height: 100%;"
      alt="warning-icon"
    />
    <span class="title d-block my-3"
      >Los siguientes servicios no fueron pagados
    </span>
  </div>
  <span
    *ngIf="basicServicesIssue.length > 0 && errorMessage"
    class="subtitle d-block mb-3"
    >{{ errorMessage }}</span
  >
  <div
    class="w-100 m-auto"
    style="max-width: 450px;"
    *ngFor="let basicService of basicServicesIssue"
  >
    <div
      *ngIf="!['Tag Total', 'AutoTag'].includes(basicService?.utilityName)"
      class="w-100 d-flex align-items-center justify-content-center my-3 py-4 px-2 px-md-5 entity-row mobile-center warning-box"
    >
      <div class="ml-0 ml-md-3 text-left">
        <div *ngIf="basicService.utilityName" class="d-block">
          <span class="secundary-text"
            >{{
              basicService.alias ? basicService.alias : basicService.utilityName
            }}
          </span>
          ·
          <span class="secundary-text">{{
            basicService.value | clpCurrency
          }}</span>
        </div>
        <div *ngIf="basicService?.customData" class="d-block">
          <span class="secundary-text"
            >{{
              basicService?.customData?.providerName === 'Edipro'
                ? 'Gasto Común Edipro'
                : basicService?.customData?.providerName
            }}
          </span>
          ·
          <span class="secundary-text">{{
            ['Security Principal', 'Hipotecaria Security'].includes(
              basicService?.customData.providerName
            )
              ? basicService.valueUF + ' UF'
              : (basicService.value | clpCurrency)
          }}</span>
        </div>
        <div
          *ngIf="!basicService.utilityName && !basicService?.customData"
          class="d-block"
        >
          <span class="secundary-text"
            >{{ entityTypeLocale[basicService?.entityType] | titlecase }}
          </span>
          ·
          <span class="secundary-text">{{
            basicService?.payingAccountUnit === 'UF'
              ? basicService.valueUF + ' UF'
              : (basicService.value | clpCurrency)
          }}</span>
        </div>
        <div *ngIf="basicService?.message && !errorMessage" class="d-block">
          <span class="primary-text">ERROR</span>:
          <span class="subtitle">{{ basicService?.message }}</span>
        </div>
      </div>
    </div>
    <div *ngIf="['Tag Total', 'AutoTag'].includes(basicService?.utilityName)">
      <div class="mb-3" style="border: 1px solid #F4CF5D; border-radius: 5px;">
        <div
          class="d-flex justify-content-between p-3"
          style="background-color: #FFF7E3; border-bottom: 1px solid #F4CF5D;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;"
        >
          <div>
            <span
              style="color: #212121;
                        font-family: Poppins;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.15px;"
            >
              <b>AutoTag</b> -
              {{
                basicService.currentDebt.invoices.length > 1
                  ? basicService.currentDebt.invoices.length + ' autopistas'
                  : basicService.currentDebt.invoices.length + ' autopista'
              }}
            </span>
          </div>
          <div>
            <span
              style="color: #212121;
                        font-family: Poppins;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        letter-spacing: 0.15px;"
            >
              {{ basicService.currentDebt.totalAmount | clpCurrency }}
            </span>
          </div>
        </div>
        <div
          *ngFor="
            let invoice of basicService.currentDebt.invoices;
            let i = index
          "
          style="background-color: #FFF7E3;"
          class="d-flex align-items-center justify-content-between p-3"
        >
          <div *ngIf="invoice?.companyName" class="d-flex align-items-center">
            <div class="mr-3">
              <img
                src="assets/img/basic-services-companies-logos-2/{{
                  invoice?.companyName?.replace('/', '-')
                }}.png"
                onerror="this.onerror=null;this.remove()"
                style="max-width: 30px;"
              />
            </div>
            <div>
              <span
                class="d-block"
                style="color: #212121;
                            font-family: Poppins;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;"
              >
                {{ invoice.companyName }}
              </span>
            </div>
          </div>
          <div>
            <span
              class="d-block"
              style="color: #212121;
                        text-align: right;
                        font-family: Poppins;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 19px; /* 135.714% */
                        letter-spacing: -0.028px;"
              >{{ invoice.amount | clpCurrency }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="row mt-2 justify-content-center"
    style="flex-wrap: wrap;"
    *ngIf="basicServicesIssue.length === 0"
  >
    <div class="py-3">
      <button
        mat-button
        class="primary-btn ml-3"
        id="continue-btn-basic-service-payment-modal"
        type="button"
        (click)="onNoClick()"
      >
        CONTINUAR
      </button>
    </div>
    <hr style="opacity: 0.3;background: #B7D3F9;width: 100%;" />
    <div class="d-flex justify-content-center">
      <span class="my-2 text-center automate-text" (click)="goToAutomate()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="17"
          viewBox="0 0 16 17"
          fill="none"
        >
          <mask
            id="mask0_60_61"
            style="mask-type:alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="16"
            height="17"
          >
            <rect y="0.5" width="16" height="16" fill="#D9D9D9" />
          </mask>
          <g mask="url(#mask0_60_61)">
            <path
              d="M3.33333 5.83301H12.6667V4.49967H3.33333V5.83301ZM3.33333 15.1663C2.96667 15.1663 2.65278 15.0358 2.39167 14.7747C2.13056 14.5136 2 14.1997 2 13.833V4.49967C2 4.13301 2.13056 3.81912 2.39167 3.55801C2.65278 3.2969 2.96667 3.16634 3.33333 3.16634H4V1.83301H5.33333V3.16634H10.6667V1.83301H12V3.16634H12.6667C13.0333 3.16634 13.3472 3.2969 13.6083 3.55801C13.8694 3.81912 14 4.13301 14 4.49967V8.28301C13.7889 8.18301 13.5722 8.09967 13.35 8.03301C13.1278 7.96634 12.9 7.91634 12.6667 7.88301V7.16634H3.33333V13.833H7.53333C7.61111 14.0775 7.70278 14.3108 7.80833 14.533C7.91389 14.7552 8.03889 14.9663 8.18333 15.1663H3.33333ZM12 15.833C11.0778 15.833 10.2917 15.508 9.64167 14.858C8.99167 14.208 8.66667 13.4219 8.66667 12.4997C8.66667 11.5775 8.99167 10.7913 9.64167 10.1413C10.2917 9.49134 11.0778 9.16634 12 9.16634C12.9222 9.16634 13.7083 9.49134 14.3583 10.1413C15.0083 10.7913 15.3333 11.5775 15.3333 12.4997C15.3333 13.4219 15.0083 14.208 14.3583 14.858C13.7083 15.508 12.9222 15.833 12 15.833ZM13.1167 14.083L13.5833 13.6163L12.3333 12.3663V10.4997H11.6667V12.633L13.1167 14.083Z"
              fill="#3369F0"
            />
          </g>
        </svg>
        Ahorra tiempo programando tu próximo pago
      </span>
    </div>
  </div>
  <div class="mt-3" *ngIf="basicServicesIssue.length > 0">
    <div>
      <div class="pt-3">
        <button
          style="max-width: 210px;"
          mat-button
          class="primary-btn w-100 w-md-50"
          id="go-to-make-payment-after-entity-create-button"
          type="button"
          (click)="openChat()"
        >
          IR AL CHAT
        </button>
      </div>
    </div>
    <div>
      <div class="py-3">
        <button
          mat-button
          style="max-width: 210px;"
          class="prev-btn mb-3 w-100 w-md-50"
          id="retry-payment-btn-basic-service"
          type="button"
          (click)="onNoClick()"
        >
          CERRAR
        </button>
      </div>
    </div>
  </div>
</div>
