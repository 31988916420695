import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-screen',
  templateUrl: './loading-screen.component.html',
  styleUrls: ['./loading-screen.component.scss']
})
export class LoadingScreenComponent {
  @Input() showLoader: boolean;
  @Input() textColor: string;
  @Input() backgroundColor: string;
  @Input() loadingText: string;

  constructor() {}
}
