import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cmr-payment-failed',
  templateUrl: './cmr-payment-failed.component.html',
  styleUrls: ['./cmr-payment-failed.component.scss']
})
export class CmrPaymentFailedComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CmrPaymentFailedComponent>,
    private fireAnalytics: AngularFireAnalytics
  ) {}

  ngOnInit(): void {
    this.fireAnalytics.logEvent('cmr_payment_failed_modal_opened');
  }

  closeModal(): void {
    this.fireAnalytics.logEvent('cmr_payment_failed_modal_closed');
    this.dialogRef.close();
  }
}
