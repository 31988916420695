import { Component, Input } from '@angular/core';

/**
desktop text-sizes: (
  h1: "4xl": (font-size: 40px, line-height: 48px),
  h2: "3xl": (font-size: 36px, line-height: 44px),
  h3: "2xl": (font-size: 24px, line-height: 32px),
  h4: "xl": (font-size: 20px, line-height: 28px),
  subtitle: "lg": (font-size: 18px, line-height: 22px),
  body: "md": (font-size: 16px, line-height: 20px),
  label: "sm": (font-size: 14px, line-height: 18px),
  small label: "xs": (font-size: 13px, line-height: 17px),
  tiny label: "2xs": (font-size: 12px, line-height: 16px),
  tiny label: "3xs": (font-size: 10px, line-height: 14px),
);
$text-weights: (
    "bold": 700,
    "semibold": 600,
    "medium": 500,
    "regular": 400,
);
 */

export type TextType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'subtitle-bold'
  | 'subtitle-semibold'
  | 'subtitle-medium'
  | 'body-bold'
  | 'body-semibold'
  | 'body-medium'
  | 'body-regular'
  | 'label-bold'
  | 'label-semibold'
  | 'label-medium'
  | 'small-label-semibold'
  | 'small-label-medium'
  | 'tiny-label-semibold'
  | 'tiny-label-medium'
  | 'tiny-label-regular';

export enum TextDecoration {
  UNDERLINE = 'underline',
  STRIKETHROUGH = 'line-through'
}

export interface TextProps {
  color?: string;
  decoration?: TextDecoration;
  type: TextType;
  text: string;
}

@Component({
  selector: 'app-text',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss']
})
export class TextComponent {
  @Input() color?: string = 'primary-100';
  @Input() decoration?: TextDecoration;

  get colorValue(): string {
    if (!this.color) {return '';}
    return `var(--${this.color})`;
  }

  get textDecorationStyle(): string {
    return this.decoration;
  }
}
