import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CapitalizePipe } from './capitalize.pipe';
import { ClpCurrencyPipe } from './clp-currency/clp-currency.pipe';
import { CustomTitleCase } from './custom-title-case/custom-title-case.pipe';
import { FormatRutPipe } from './format-rut/format-rut.pipe';
import { ImagePipe } from './image/image.pipe';
import { ReplacePipe } from './replace/replace.pipe';
import { ReplaceAllPipe } from './replace-all/replace-all.pipe';
import { StylesPipe } from './styles.pipe';
import { ThousandsSeparatorPipe } from './thousand-separator/thousand-separator.pipe';
import { ToFixedPipe } from './to-fixed/to-fixed.pipe';

@NgModule({
  declarations: [
    ImagePipe,
    ClpCurrencyPipe,
    ReplacePipe,
    ToFixedPipe,
    FormatRutPipe,
    ReplaceAllPipe,
    StylesPipe,
    CustomTitleCase,
    ThousandsSeparatorPipe,
    CapitalizePipe
  ],
  imports: [CommonModule],
  exports: [
    ImagePipe,
    ClpCurrencyPipe,
    ReplacePipe,
    ToFixedPipe,
    FormatRutPipe,
    ReplaceAllPipe,
    StylesPipe,
    CustomTitleCase,
    ThousandsSeparatorPipe,
    CapitalizePipe
  ]
})
export class PipesModule {}
