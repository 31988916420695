import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription, delay, take } from 'rxjs';

import { CookiesService } from '@services/cookies/cookies.service';
import { LoaderScreenService } from '@services/loader-screen/loader-screen.service';
import { SoyIOService } from '@services/soy-io/soy-io.service';
import { UserService } from '@services/user/user.service';
import { User } from 'app/models/users/user.model';

declare let Intercom: any;

@Component({
  selector: 'app-verify-identity-modal',
  templateUrl: './verify-identity-modal.component.html',
  styleUrls: ['./verify-identity-modal.component.scss']
})
export class VerifyIdentityModalComponent implements OnDestroy, OnInit {
  currentUser: User | any;
  focusState = false;
  showDisclaimer = false;
  isMobile: boolean;
  emailPass: boolean;
  provider: any;
  soyIoEnabled = true;
  private allSubscriptions: Subscription = new Subscription();
  constructor(
    private loaderService: LoaderScreenService,
    private userService: UserService,
    private deviceService: DeviceDetectorService,
    private fireAnalytics: AngularFireAnalytics,
    private soyIOService: SoyIOService,
    public dialogRef: MatDialogRef<VerifyIdentityModalComponent>,
    private cookiesService: CookiesService,
    private router: Router
  ) {
    this.soyIoEnabled = this.userService.soyio;
    this.isMobile = this.deviceService.isMobile();
    const body = document.getElementsByTagName('body')[0];
    body.classList.add('cdk-global-scrollblock');
  }

  ngOnInit(): void {
    this.soyIOService.addWidgetStatus(null);
    this.allSubscriptions = this.soyIOService.widgetStatus$
      .pipe(delay(13000))
      .subscribe(widgetStatus => {
        this.userService.userInfo$.pipe(take(1)).subscribe(userInfo => {
          this.soyIOService.showResponseModal(userInfo, widgetStatus);
          if (
            (userInfo?.verificationProvider &&
              userInfo?.verificationProvider.verificationStatus &&
              ['WIDGET_CLOSED', 'DISCLOSURE_REQUEST_SUCCESSFUL'].includes(
                widgetStatus
              )) ||
            (!userInfo?.verificationProvider &&
              ['WIDGET_CLOSED', 'DISCLOSURE_REQUEST_SUCCESSFUL'].includes(
                widgetStatus
              ))
          ) {
            this.close();
            this.loaderService.hideLoader();
          }
          if (['DISCLOSURE_REQUEST_SUCCESSFUL'].includes(widgetStatus)) {
            this.cookiesService.setCookie(
              `verifyPending${userInfo?.id}`,
              'true',
              '.neatpagos.com',
              1
            );
            this.fireAnalytics.logEvent('SOYIO_COMPLETED');
            this.router.url.includes('neatclub') &&
              this.fireAnalytics.logEvent(`nc_soyio_success`, {
                newUser: false
              });
          }
          if (['WIDGET_CLOSED'].includes(widgetStatus)) {
            this.fireAnalytics.logEvent('SOYIO_CLOSED');
          }
        });
      });
  }

  ngOnDestroy(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.remove('cdk-global-scrollblock');
    this.allSubscriptions.unsubscribe();
  }

  showOrHideDisclaimer() {
    this.showDisclaimer = !this.showDisclaimer;
    if (this.showDisclaimer) {
      this.fireAnalytics.logEvent('show_disclaimer_onboarding_screen');
    }
  }

  openSoyIO(): void {
    this.loaderService.showLoader(
      'white',
      'Finalizando verificación',
      'Estamos ajustando los últimos detalles'
    );
    this.userService.currentUserData$
      .pipe(take(1))
      .subscribe(user => this.soyIOService.initializeWidget(user));
    this.fireAnalytics.logEvent(`SOYIO SCREEN OPENED`, { newUser: false });
  }

  openIntercom(): void {
    Intercom('showNewMessage', '');
  }

  close(): void {
    this.dialogRef.close();
  }
}
