import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IVerification } from '@shared/utils';

declare let Intercom: any;

@Component({
  selector: 'app-verification-status-modal',
  templateUrl: './verification-status-modal.component.html',
  styleUrls: ['./verification-status-modal.component.scss']
})
export class VerificationStatusModalComponent {
  modalData: IVerification;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IVerification,
    public dialogRef: MatDialogRef<VerificationStatusModalComponent>
  ) {
    this.modalData = this.data;
  }

  closeBtn(): void {
    this.dialogRef.close();
  }

  openIntercom(): void {
    Intercom('showNewMessage', '');
  }
}
