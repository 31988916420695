import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireRemoteConfig } from '@angular/fire/compat/remote-config';
import { Title, Meta, MetaDefinition } from '@angular/platform-browser';
import { ActivationEnd, Router, ActivatedRoute } from '@angular/router';
import { Observable, Observer, fromEvent, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { CloudService } from '@services/cloud/cloud.service';
import { CookiesService } from '@services/cookies/cookies.service';
import { IntercomService } from '@services/shared/intercom/intercom.service';
import { UserService } from '@services/user/user.service';

import { ErrorHandlerService } from './services/shared/error-handler/error-handler.service';
import { SweetalertService } from './services/shared/sweetalert/sweetalert.service';


declare let heap: any;
export interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  titulo = 'neat-app';
  wasOffline = false;
  activeLang: string;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private swalService: SweetalertService,
    private fireRemoteConfig: AngularFireRemoteConfig,
    private errorService: ErrorHandlerService,
    private clouService: CloudService,
    private firestoreService: AngularFirestore,
    private fireAnalytics: AngularFireAnalytics,
    private userService: UserService,
    private intercomService: IntercomService,
    private cookiesService: CookiesService
  ) {
    this.showSecurityBannerOnce();
    this.fireRemoteConfig.fetchAndActivate().catch(err => {
      this.errorService.recordError(
        err,
        'app.component.ts',
        'this.fireRemoteConfig.fetchAndActivate()',
        'Error al conectarse con Remote Config'
      );
      // console.error('Error al conectarse con Remote Config', err);
    });
    this.firestoreService.firestore.enablePersistence().catch(err => {
      this.fireAnalytics.logEvent(
        `firestore.enablePersistence() error: ${err.code}`
      );
      //err.code = failed-precondition:
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
      //err.code = unimplemented:
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    });
    this.route.queryParams.subscribe(params => {
      if (params['template'] && params['action'] && params['email']) {
        this.clouService.engagementBigqueryMetrics(Object.assign({}, params));
      }
      if (params['from']) {
        try {
          heap.addUserProperties({
            comesFrom: params['from']
          });
        } catch (e) {
          this.errorService.recordError(
            e,
            'app.component.ts',
            `heap.addUserProperties()', 'Error al asignar heap property`
          );
        }
      }
    });
    this.getDataRoute().subscribe(data => {
      this.titulo = data.titulo;
      this.title.setTitle(this.titulo);

      const metaTag: MetaDefinition = {
        name: 'description',
        content: this.titulo
      };
      this.meta.updateTag(metaTag);
    });
    this.createOnline$().subscribe(isOnline => {
      if (!isOnline) {
        this.swalService.swalToastGeneral(
          'Uups, parece que no tienes conexión a internet, algunas funcionalidades pueden dejar de funcionar',
          'warning'
        );
        this.wasOffline = true;
      } else if (isOnline && this.wasOffline) {
        this.swalService.swalToastGeneral(
          'Genial, volviste a tener conexión',
          'success'
        );
        this.wasOffline = false;
      }
    });
    window.addEventListener('appinstalled', () => {
      this.fireAnalytics.logEvent(`App installed`);
    });
    this.intercomService.initIntercom();
  }

  createOnline$() {
    return merge<readonly unknown[]>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      })
    );
  }

  getDataRoute() {
    return this.router.events.pipe(
      filter(evento => evento instanceof ActivationEnd),
      filter((evento: ActivationEnd) => evento.snapshot.firstChild === null),
      map((evento: ActivationEnd) => evento.snapshot.data)
    );
  }

  loadLanguaje() {
    if (localStorage.getItem('languaje')) {
      this.activeLang = JSON.parse(localStorage.getItem('languaje'));
    }
  }

  showSecurityBannerOnce() {
    const showBanner = this.cookiesService.getCookie('showBanner');
    const securityBannerDate = this.cookiesService.getCookie(
      'securityBannerDate'
    );
    if (!showBanner) {
      this.cookiesService.setCookie(
        'showBanner',
        'true',
        '.neatpagos.com',
        360
      );
    }
    if (securityBannerDate) {
      const lastDate = new Date(securityBannerDate);
      const today = new Date();
      const dayDiff = this.dateDiffInDays(lastDate, today);
      if (dayDiff > 30) {
        this.cookiesService.setCookie(
          'showBanner',
          'true',
          '.neatpagos.com',
          360
        );
        this.cookiesService.setCookie(
          'securityBannerDate',
          String(new Date()),
          '.neatpagos.com',
          360
        );
      }
    }
  }

  dateDiffInDays(firstDate: Date, currentDate: Date) {
    const _MS_PER_DAY = 1000 * 60 * 60 * 24;
    const utc1 = Date.UTC(
      firstDate.getFullYear(),
      firstDate.getMonth(),
      firstDate.getDate()
    );
    const utc2 = Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }
}
