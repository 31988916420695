import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs/internal/Subscription';
import { Md5 } from 'ts-md5';

import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { UserService } from '@services/user/user.service';
import { AppGlobals } from '@shared/constants';


@Component({
  selector: 'app-pin-modal',
  templateUrl: './pin-modal.component.html',
  styleUrls: ['./pin-modal.component.scss']
})
export class PinModalComponent implements OnInit, OnDestroy {
  showLoader: boolean;
  currentUserIdToken: string;
  validatePin: FormGroup;
  loadingVerifyPin: boolean = false;
  validPin: boolean;
  errorMessage: string;
  traceSubmit: any;
  servipagPayURL: string = AppGlobals.SPBaseURL;
  rentListSubscription: Subscription;

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private http: HttpClient,
    private errorService: ErrorHandlerService,
    public dialogRef: MatDialogRef<PinModalComponent>
  ) {
    this.validatePin = this.fb.group({
      pin: [
        '',
        [Validators.required, Validators.maxLength(4), Validators.minLength(4)]
      ]
    });
  }

  ngOnInit(): void {
    this.userService.idToken$.subscribe({
      next: idToken => (this.currentUserIdToken = idToken),
      error: error => {
        this.errorService.recordError(
          error,
          'payment-summary-modal.component.ts',
          'this.userService.idToken$',
          'Error al ejecutar subscribe de idToken$'
        );
      }
    });
  }

  ngOnDestroy() {
    this.rentListSubscription?.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  pinValidationForAutomatizate() {
    this.loadingVerifyPin = true;
    this.validatePinRequest(
      Md5.hashStr(this.validatePin.get('pin').value)
    ).subscribe({
      next: res => {
        if (res.data.status === 200) {
          this.loadingVerifyPin = false;
          this.validPin = true;
          this.dialogRef.close({
            pinValid: true,
            md5Pin: Md5.hashStr(this.validatePin.get('pin').value)
          });
        } else {
          this.loadingVerifyPin = false;
          this.errorMessage = res.error.error.message;
          this.validatePin.controls['pin'].setErrors({ incorrect: true });
        }
      },
      error: error => {
        if (error?.status === 0) {
          this.retryPinValidation();
        } else {
          if (
            error?.error &&
            error.error.error?.code === -1003 &&
            error.error.error?.message
          ) {
            this.errorMessage = `Pin incorrecto, te quedan ${error.error.error.message} intentos`;
            this.validatePin.controls['pin'].setErrors({ incorrect: true });
            this.loadingVerifyPin = false;
          } else if (
            error.error.error?.code === -1002 &&
            error.error.error?.status === 'FORBIDDEN'
          ) {
            this.errorMessage = 'Tu pin ha sido bloqueado';
            this.validatePin.controls['pin'].setErrors({ incorrect: true });
            this.loadingVerifyPin = false;
          } else {
            this.errorMessage = 'Error al validar pin, intenta nuevamente';
            this.validatePin.controls['pin'].setErrors({ incorrect: true });
            this.loadingVerifyPin = false;
          }
          this.errorService.recordError(
            error,
            'profile.component.ts',
            'validatePin',
            'Error al validar pin'
          );
        }
      }
    });
  }

  retryPinValidation() {
    this.validatePinRequest(
      Md5.hashStr(this.validatePin.get('pin').value)
    ).subscribe({
      next: res => {
        if (res.data.status === 200) {
          this.loadingVerifyPin = false;
          this.validPin = true;
          this.dialogRef.close({
            pinValid: true,
            md5Pin: Md5.hashStr(this.validatePin.get('pin').value)
          });
        } else {
          this.loadingVerifyPin = false;
          this.errorMessage = res.error.error.message;
          this.validatePin.controls['pin'].setErrors({ incorrect: true });
        }
      },
      error: error => {
        if (
          error?.error &&
          error.error.error?.code === -1003 &&
          error.error.error?.message
        ) {
          this.errorMessage = `Pin incorrecto, te quedan ${error.error.error.message} intentos`;
        } else {
          this.errorMessage = 'Error al validar pin, intenta nuevamente';
        }
        this.errorService.recordError(
          error,
          'profile.component.ts',
          'validatePin',
          'Error al validar pin, segundo intento'
        );
        this.validatePin.controls['pin'].setErrors({ incorrect: true });
        this.loadingVerifyPin = false;
      }
    });
  }

  validatePinRequest(pin: string): Observable<any> {
    const endpoint: string = AppGlobals.validatePinEndpoint;
    const httpHeader = {
      headers: new HttpHeaders({
        'x-pin': `${pin}`,
        Authorization: `Bearer ${this.currentUserIdToken}`
      })
    };
    return this.http.get<any>(endpoint, httpHeader);
  }
}
