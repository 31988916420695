import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AppGlobals } from '@shared/constants';
import { Payment } from 'app/models/abstract-payment.model';
import { User } from 'app/models/users/user.model';

@Component({
  selector: 'app-retry-transfer',
  templateUrl: './retry-transfer.component.html',
  styleUrls: ['./retry-transfer.component.scss']
})
export class RetryTransferModalComponent implements OnInit {
  currentUser: User;
  currentPayment: Payment;
  successModal: boolean;
  errorModal: { error: boolean; errorMessage: string };
  paymentDate: string;
  paymentError: boolean;
  allPayments: string[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<RetryTransferModalComponent>,
    private fireAnalytics: AngularFireAnalytics
  ) {}

  ngOnInit(): void {
    this.currentPayment = this.data.payment;
    if (!this.currentPayment || !this.currentPayment.transferError) {
      this.errorModal = { error: true, errorMessage: null };
      this.paymentError = true;
    } else {
      this.paymentError = false;
      if (this.currentPayment.currentPaymentStatus === 'Done') {
        this.successModal = true;
      }
      const strinDateArray = this.data.payment.stringifiedTransferDate.split(
        '-'
      );
      this.paymentDate = `${strinDateArray[0]} ${AppGlobals.monthNames[
        strinDateArray[1] - 1
      ].toLowerCase()} ${strinDateArray[2]}`;
    }
    this.fireAnalytics.logEvent('Show Retry Modal', {
      payment: this.currentPayment,
      paymentId: this.currentPayment?.paymentId,
      errorModal: this.errorModal
    });
    localStorage.removeItem('retryTransfer');
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  showSuccessModal(value: boolean) {
    this.successModal = value;
  }
  showErrorModal(value: { error: boolean; errorMessage: string }) {
    this.errorModal = value;
  }
}
