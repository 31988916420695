import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { RentListService } from '@services/rent-list/rent-list.service';
import { ErrorHandlerService } from '@services/shared/error-handler/error-handler.service';
import { SweetalertService } from '@services/shared/sweetalert/sweetalert.service';
import { EntityType, PaymentProduct } from '@shared/enums/enums.enum';

@Injectable({
  providedIn: 'root'
})
export class EntityClass {
  constructor(
    private swalService: SweetalertService,
    private rentListService: RentListService,
    private errorService: ErrorHandlerService
  ) {}

  public updateEntityFromCheckout(
    paymentForm: FormGroup,
    paymentMethodForm: FormGroup
  ): any {
    const entityData = {
      payingMonth: `${paymentForm.get('paymentMonth').value} ${
        paymentForm.get('paymentYear').value
      }`,
      payingAccountUnit: paymentForm.get('payingAccountUnit').value,
      value: paymentForm.get('amount').value,
      valueUF: paymentForm.get('amountUF').value,
      lastTransferComment: paymentForm.get('lastTransferComment').value
        ? paymentForm.get('lastTransferComment').value
        : '',
      receiverEmailNotification: paymentForm.get('receiverEmailNotification')
        .value,
      fixedValue: paymentForm.get('fixedValue').value,
      paymentOption: {
        automatic: false,
        paymentMethodId: paymentMethodForm.get('paymentMethod').value,
        product: PaymentProduct[paymentMethodForm.get('paymentOption').value]
      },
      ...(paymentForm.get('sendReminderNotification').value && {
        sendReminderNotification: paymentForm.get('sendReminderNotification')
          .value
      }),
      ...(paymentForm.get('transferLimitDate').value && {
        transferLimitDate: Number(paymentForm.get('transferLimitDate').value)
      })
    };
    return entityData;
  }

  public updateEntityAutomatePayment(
    automateForm: FormGroup,
    automatic: boolean
  ): any {
    let entityData = {
      value: Number(automateForm.get('value').value),
      valueUF: Number(automateForm.get('valueUF').value),
      payingAccountUnit: automateForm.get('payingAccountUnit').value,
      lastTransferComment: automateForm.get('lastTransferComment').value,
      monthlyPayment: automateForm.get('monthlyPayment')?.value || true,
      fixedValue: automateForm.get('fixedValue')?.value || true,
      paymentOption: {
        automatic: automatic,
        paymentMethodId: automateForm.get('paymentMethod').value,
        product: PaymentProduct.oneclick
      },
      receiverEmailNotification: !!automateForm.get('receiverEmailNotification')?.value
    };
    const variableFields = [
      'transferLimitDate',
      'payingAccountUnit',
      'value',
      'valueUF'
    ];
    variableFields.forEach(fieldValue => {
      if (automateForm.get(fieldValue).value) {
        entityData = Object.assign(entityData, {
          [fieldValue]: automateForm.get(fieldValue).value
            ? this.setTypeValue(automateForm.get(fieldValue).value, fieldValue)
            : ''
        });
      }
    });
    return entityData;
  }
  public updateEntityAutomateBasicService(
    automateForm: FormGroup,
    automatic: boolean
  ): any {
    const entityData: any = {
      paymentOption: this.createPaymentOption(automateForm, automatic)
    };
    if (automateForm.get('automaticType')?.value === 'debtDriven') {
      entityData.automaticPaymentConfiguration = this.createAutomaticPaymentConfiguration();
    }
    this.assignVariableFields(entityData, automateForm, [
      'transferLimitDate',
      'alias'
    ]);
    return entityData;
  }

  private createPaymentOption(
    automateForm: FormGroup,
    automatic: boolean
  ): any {
    const paymentOption = {
      automatic: automatic,
      paymentMethodId: automateForm.get('paymentMethod').value,
      product: PaymentProduct.oneclick
    };
    if (automateForm.get('sencillitoPreference')?.value) {
      paymentOption['sencillitoPreference'] = automateForm.get(
        'sencillitoPreference'
      ).value;
    }
    if (automateForm.get('automaticType')?.value === 'debtDriven') {
      paymentOption['automaticType'] = automateForm.get('automaticType').value;
    }
    if (automateForm.get('automaticType')?.value === 'scheduled') {
      paymentOption['automaticType'] = automateForm.get('automaticType').value;
    }
    return paymentOption;
  }

  private createAutomaticPaymentConfiguration() {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    return {
      debtRequestDay: tomorrow.getDate(),
      requestTryCounter: 0
    };
  }

  private assignVariableFields(
    entityData: any,
    automateForm: FormGroup,
    fields: string[]
  ): void {
    fields.forEach(fieldValue => {
      if (automateForm.get(fieldValue).value !== undefined) {
        entityData[fieldValue] = automateForm.get(fieldValue).value
          ? this.setTypeValue(automateForm.get(fieldValue).value, fieldValue)
          : null;
      }
    });
  }

  setTypeValue(value: string, field: string) {
    if (field !== 'payingAccountUnit' && field !== 'alias') {
      return Number(value);
    }
    return value;
  }

  async deleteEntity(entityId: string, entityType: EntityType) {
    try {
      await this.rentListService.deleteEntity(entityId, entityType);
    } catch (error) {
      this.errorService.recordError(
        error,
        'entities-list.component.ts',
        'deleteEntity()',
        'Error al eliminar entidad'
      );
    }
  }
}
