<div style="position: relative;">
  <span
    style="position: absolute; right: 0px;"
    id="neat-prime-modal-closed"
    (click)="onNoClick()"
    ><mat-icon class="c-pointer">close</mat-icon></span
  >
  <div class="px-0 px-md-4">
    <div class="m-auto">
      <h2 class="title m-auto pt-2">Bienvenido a</h2>
      <img
        class="img-fluid text-center ml-auto mr-auto d-block my-3"
        width="100%"
        style="max-width: 250px;"
        src="assets/img/newRequirements/neat-prime-logo.svg"
        alt="neat-prime-img"
      />
      <p class="text m-auto" style="max-width: 450px;">
        Por ser un usuario constante de Neat abriste un nuevo mundo de
        beneficios exclusivos
      </p>
      <p class="text m-auto pt-3" style="max-width: 450px;">
        Ganaras durante <b>1 año, el 15% de todas las comisiones</b> de los
        pagos que realicen tus amigos
      </p>
      <p class="secundary-text m-auto pt-2 pt-md-4" style="max-width: 450px;">
        a diferencia de las categorías anteriores, no perderás la categoría en
        caso de no hacer pagos en Neat
      </p>
    </div>
    <div
      class="d-flex mt-4"
      style="justify-content: center; flex-wrap: wrap-reverse;"
    >
      <button
        mat-raised-button
        [disableRipple]="false"
        class="prev-btn mr-1 mr-md-3 mb-2"
        style="text-transform: uppercase;"
        id="modal-neat-prime-return"
        type="button"
        (click)="onNoClick()"
      >
        INVITAR EN OTRO MOMENTO
      </button>
      <button
        mat-raised-button
        [disableRipple]="false"
        class="next-btn ml-1 ml-md-3 mb-2"
        style="color: white; text-transform: uppercase;"
        id="neat-prime-modal-refer-friend"
        type="button"
        [routerLink]="['/dashboard/referidos']"
        (click)="onNoClick()"
      >
        REFERIR AMIGOS
      </button>
    </div>
  </div>
</div>
