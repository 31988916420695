import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { PromotionsService } from '@services/promotions/promotions.service';

@Component({
  selector: 'app-promotion-modal',
  templateUrl: './promotion-modal.component.html',
  styleUrls: ['./promotion-modal.component.scss']
})
export class PromotionModalComponent implements OnDestroy {
  selectedPromotion: string = 'null';
  private allSubscriptions: Subscription = new Subscription();
  constructor(
    public promotion: PromotionsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PromotionModalComponent>
  ) {
    this.promotion.getSelectedPromotion$.subscribe(selectedPromotions => {
      this.selectedPromotion = selectedPromotions;
    });
  }

  ngOnDestroy(): void {
    this.promotion.addSelectedPromotion('null');
    this.allSubscriptions?.unsubscribe();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
