import { Component, Inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { take } from 'rxjs/internal/operators/take';

import { RentListService } from '@services/rent-list/rent-list.service';
import { UserService } from '@services/user/user.service';
import { MergeTypes } from '@shared/utils';
import { BasicService } from 'app/models/abstract-basic-service.model';
import { Entity } from 'app/models/abstract-entity.model';

@Component({
  selector: 'app-neat-entity-payment-success-modal',
  templateUrl: './neat-entity-payment-success-modal.component.html',
  styleUrls: ['./neat-entity-payment-success-modal.component.scss']
})
export class NeatEntityPaymentSuccessModalComponent {
  category: string;
  transferDate: Date;
  entity: Entity;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: { category: string; transferDate?: string; entity: Entity },
    public dialogRef: MatDialogRef<NeatEntityPaymentSuccessModalComponent>,
    public router: Router,
    private fireAnalytics: AngularFireAnalytics,
    private rentListService: RentListService,
    private userService: UserService
  ) {
    this.category = this.data.category;
    this.userService.transferDates$.pipe(take(1)).subscribe(transferDates => {
      this.transferDate = new Date(
        new Date(
          transferDates.transferDate.seconds * 1000
        ).toLocaleString('en-US', { timeZone: 'America/Santiago' })
      );
    });
    this.entity = this.data.entity;
  }

  closeBtn(): void {
    this.dialogRef.close();
  }

  goToAutomate(entity: Entity): void {
    const analyticsParams = {
      event: 'goToAutomate',
      section: 'neatEntityPayedModal'
    };
    this.rentListService.goToAutomate(
      entity as MergeTypes<BasicService, Entity>,
      true,
      analyticsParams,
      this.dialogRef
    );
  }
}
