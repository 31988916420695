import { Component, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-retry-transfer-success',
  templateUrl: './retry-transfer-success.component.html',
  styleUrls: ['../retry-transfer.component.scss']
})
export class RetryTransferSuccessComponent {
  @Input() entityType: string;
  @Input() currentPaymentStatus: string;
  @Output() setCloseModal = new EventEmitter();
  constructor() {}

  closeModal(): void {
    this.setCloseModal.emit();
  }
}
