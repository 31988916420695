import firebase from 'firebase/compat';
import { PromotionTypes } from 'neat-lib/dist/Enums/Constants';
import {
  IAdditionalInfo,
  IPromoHistoryMoney,
  IPromoHistoryTotalValue,
  IPromotion
} from 'neat-lib/dist/Interfaces/IData';

import { Deserializable } from '../interfaces/deserializable.interface';
import { EntityType } from '../shared/enums/enums.enum';


export class Promotion implements IPromotion, Deserializable {
  public duration: number | Date | firebase.firestore.Timestamp;
  public type: PromotionTypes;
  public discount: number;
  public contador: number;
  public category: string;
  public cumulative: boolean;
  public active: boolean;
  public product: 'M1P' | EntityType;
  public entity: string;
  public name: string;
  public message: string;
  public expirationDate: { seconds: number; nanoseconds: number };
  public id: string;
  public creationDate: firebase.firestore.Timestamp;
  public userId: string;
  public activationCode?: string;
  public isValid?: boolean;
  public discountHistory?: Array<IPromoHistoryTotalValue | IPromoHistoryMoney>;
  public balanceHistory?: Array<any>;
  public additionalInfo?: IAdditionalInfo;
  public spentAmount?: number;
  public totalCashback?: number;

  constructor() {}

  deserialize(input: any): this {
    return Object.assign(this, input);
  }
}
