import { Payment } from './abstract-payment.model';
import { AlimonyPayment } from './payments/alimony-payment.model';
import { BasicServicesPayment } from './payments/basic-services-payment.model';
import { CommonExpensesPayment } from './payments/common-expenses-payment.model';
import { DomesticWorkerPayment } from './payments/domestic-worker-payment.model';
import { KindergartenPayment } from './payments/kindergarten-payment.model';
import { MortgageFootPayment } from './payments/mortgage-foot-payment.model';
import { RentPayment } from './payments/rent-payment.model';
import { Deserializable } from '../interfaces/deserializable.interface';
import { IPaymentFactory } from '../interfaces/payments.interface';
import { GymPayment } from './payments/gym-payment.model';
import { NursingHomePayment } from './payments/nursing-home-payment.model';
import { OthersPayments } from './payments/others-payment.model';
import { ParkingPayment } from './payments/parking-payment.model';
import { ProfessionalPayment } from './payments/professional-payment.model';
import { SchoolPayment } from './payments/school-payment.model';
import { SocialClubPayment } from './payments/social-club-payment.model';
import { SportClubPayment } from './payments/sport-club-payment.model';
import { WarehousePayment } from './payments/warehouse-payment.model';
// import { UniversityPayment } from './payments/university-payment.model';
import { EntityType, PaymentCollectionName } from '../shared/enums/enums.enum';

class PaymentFactory implements IPaymentFactory {
  createInstance<T extends Deserializable>(
    PaymentClass: new () => T,
    data: any
  ): T {
    return new PaymentClass().deserialize(data);
  }

  deserializePayment(entityType: EntityType | string, data: any): Payment {
    switch (entityType) {
    case EntityType.rent:
    case 'Rent':
      return this.createInstance(RentPayment, data);
    case EntityType.commonExpenses:
    case 'CommonExpenses':
      return this.createInstance(CommonExpensesPayment, data);
    case EntityType.warehouse:
    case 'Warehouse':
      return this.createInstance(WarehousePayment, data);
    case EntityType.parking:
    case 'Parking':
      return this.createInstance(ParkingPayment, data);
    case EntityType.mortgageFoot:
    case 'MortgageFoot':
      return this.createInstance(MortgageFootPayment, data);
    case EntityType.kindergarten:
    case 'Kindergarten':
      return this.createInstance(KindergartenPayment, data);
    case EntityType.school:
    case 'School':
      return this.createInstance(SchoolPayment, data);
      // case EntityType.university:
      // case 'University':
      //   return this.createInstance(UniversityPayment, data);
    case EntityType.domesticWorker:
    case 'DomesticWorker':
      return this.createInstance(DomesticWorkerPayment, data);
    case EntityType.nursingHome:
    case 'NursingHome':
      return this.createInstance(NursingHomePayment, data);
    case EntityType.sportClub:
    case 'SportClub':
      return this.createInstance(SportClubPayment, data);
    case EntityType.socialClub:
    case 'SocialClub':
      return this.createInstance(SocialClubPayment, data);
    case EntityType.gym:
    case 'Gym':
      return this.createInstance(GymPayment, data);
    case EntityType.alimony:
    case 'Alimony':
      return this.createInstance(AlimonyPayment, data);
    case EntityType.professionals:
    case 'Professional':
      return this.createInstance(ProfessionalPayment, data);
    case EntityType.others:
    case 'Others':
      return this.createInstance(OthersPayments, data);
      // basic service mvp
      // case EntityType.basicServices:
    case 'basicService':
      return this.createInstance(BasicServicesPayment, data);
    default:
      break;
    }
  }

  getCollectionName(
    entityType: EntityType | 'mapper'
  ): PaymentCollectionName | string {
    switch (entityType) {
    case EntityType.rent:
      return PaymentCollectionName.rent;
    case EntityType.commonExpenses:
      return PaymentCollectionName.commonExpenses;
    case EntityType.warehouse:
      return PaymentCollectionName.warehouse;
    case EntityType.parking:
      return PaymentCollectionName.parking;
    case EntityType.mortgageFoot:
      return PaymentCollectionName.mortgageFoot;
    case EntityType.kindergarten:
      return PaymentCollectionName.kindergarten;
    case EntityType.school:
      return PaymentCollectionName.school;
      // case EntityType.university: return PaymentCollectionName.university;
    case EntityType.domesticWorker:
      return PaymentCollectionName.domesticWorker;
    case EntityType.nursingHome:
      return PaymentCollectionName.nursingHome;
    case EntityType.sportClub:
      return PaymentCollectionName.sportClub;
    case EntityType.socialClub:
      return PaymentCollectionName.socialClub;
    case EntityType.gym:
      return PaymentCollectionName.gym;
    case EntityType.alimony:
      return PaymentCollectionName.alimony;
    case EntityType.professionals:
      return PaymentCollectionName.professionals;
    case EntityType.others:
      return PaymentCollectionName.others;
      // case EntityType.basicServices: return PaymentCollectionName.basicServices;
    case 'mapper':
      return 'paymentsMapper';
    default:
      throw new Error('Unrecognized entity type');
    }
  }
}

export { PaymentFactory };
