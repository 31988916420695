import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { Provider } from '../../shared/loading-screen-global/loading-screen.component';

interface LoaderScreenSetup {
  [key: string]: {
    textColor: string;
    secundaryColor: string;
    backgroundColor: string;
  };
}

export const LoaderScreenSetup: LoaderScreenSetup = {
  blue: {
    textColor: 'white',
    secundaryColor: '#ADC3F9',
    backgroundColor: '#3369F0'
  },
  white: {
    textColor: '#3369F0',
    secundaryColor: '#979CC2',
    backgroundColor: '#F4F8FE'
  }
};

@Injectable({
  providedIn: 'root'
})
export class LoaderScreenService {
  private showLoading: Subject<boolean> = new BehaviorSubject<boolean>(false);
  private loadingText: Subject<string> = new BehaviorSubject<string>(
    'Cargando ...'
  );
  private loadingSubtitle: Subject<string> = new BehaviorSubject<string>('');
  private textColor: Subject<string> = new BehaviorSubject<string>('#3368f0');
  private secundaryColor: Subject<string> = new BehaviorSubject<string>(
    '#ADC3F9'
  );
  private backgroundColor: Subject<string> = new BehaviorSubject<string>(
    'white'
  );
  private provider: Subject<Provider> = new BehaviorSubject<Provider>(
    Provider.NEAT
  );
  constructor() {}

  get getShowLoading$(): Observable<boolean> {
    return this.showLoading.asObservable();
  }

  get getLoadingText$(): Observable<string> {
    return this.loadingText.asObservable();
  }

  get getLoadingSubtitle$(): Observable<string> {
    return this.loadingSubtitle.asObservable();
  }

  get getTextColor$(): Observable<string> {
    return this.textColor.asObservable();
  }

  get getSecundaryColor$(): Observable<string> {
    return this.secundaryColor.asObservable();
  }

  get getBackgroundColor$(): Observable<string> {
    return this.backgroundColor.asObservable();
  }

  get getProvider$(): Observable<Provider> {
    return this.provider.asObservable();
  }

  showLoader(
    type: 'blue' | 'white',
    showLoaderText?: string,
    loadingSubtitle?: string,
    provider?: Provider
  ): void {
    this.showLoading.next(true);
    this.loadingText.next(showLoaderText);
    loadingSubtitle && this.loadingSubtitle.next(loadingSubtitle);
    if (LoaderScreenSetup[type]) {
      this.textColor.next(LoaderScreenSetup[type].textColor);
      this.backgroundColor.next(LoaderScreenSetup[type].backgroundColor);
      this.secundaryColor.next(LoaderScreenSetup[type].secundaryColor);
      provider && this.provider.next(provider);
    }
  }

  hideLoader(): void {
    this.showLoading.next(false);
    this.loadingText.next('Cargando ...');
    this.loadingSubtitle.next('');
    this.textColor.next('#3368f0');
    this.secundaryColor.next('#ADC3F9');
    this.backgroundColor.next('white');
  }
}
